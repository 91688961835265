import React, { useEffect, useState } from "react";
import { Modal, Grid, Header } from "semantic-ui-react";

const EditPhoto = ({ open, onClose, image, updatePhoto }) => {
  const [title, setTitle] = useState(image.title);
  useEffect(() => {
    setTitle(image.title);
  }, [image]);

  const handleTitle = (event) => {
    setTitle(event.target.value);
  };

  return (
    <Modal size="small" open={open} onClose={onClose}>
      <Modal.Header>Edit photo</Modal.Header>
      <Grid>
        <Grid.Row />
        <Grid.Column width={8}>
          <Header color="teal" sub content="Photo preview" />
          <img
            style={{ width: "200px", hight: "200px", margin: "20px" }}
            src={image.url}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Header color="teal" sub content="Photo title" />
          <input
            placeholder="Photo title"
            onChange={(e) => handleTitle(e)}
            type="text"
            value={title}
            style={{
              width: "200px",
              height: "30px",
              marginBottom: "8px",
            }}
          />
          <button
            className="saveButton"
            onClick={() => updatePhoto(image, title)}
          >
            Save
          </button>
        </Grid.Column>
      </Grid>
    </Modal>
  );
};

export default EditPhoto;
