/*global google*/
import React, { useState, useEffect } from "react";
import { firestoreConnect, withFirestore } from "react-redux-firebase";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { createTour, updateTour } from "../tourAction";
import TourMainNav from "./TourMainNav";
import CreateRoute from "./CreateRoute";
import { toastr } from "react-redux-toastr";
import TourDetails from "./TourDetails";
import cuid from "cuid";
import TourPreview from "./TourPreview";
import RoutePreview from "./RoutePreview";
import "../../../style/tourControl.css";
import InstractionController from "../../instractions/InstractionController";

/* const query = (props) => {
  let tourId;
  if (props.tourId) {
    tourId = props.tourId;
  } else if (props.initialValues && props.initialValues.tourId) {
    tourId = props.initialValues.tourId;
  }
  if (tourId) {
    return [
      {
        collection: "tours",
        doc: tourId,
      },
    ];
  } else {
    return [{ collection: "tours" }];
  }
};
 */
const actions = {
  createTour,
  updateTour,
};

const mapState = (state, ownProps) => {
  let tourId;
  let stops;

  if (ownProps.match.params.tourId) {
    tourId = ownProps.match.params.tourId;
  } else if (state.form.tourForm && state.form.tourForm.values.id) {
    tourId = state.form.tourForm.values.id;
  }

  let tour = {};

  if (
    ownProps.match.params.tourId &&
    state.firestore.ordered.tours &&
    state.firestore.ordered.tours.length > 0
  ) {
    tour =
      state.firestore.ordered.tours.filter((tour) => tour.id === tourId)[0] ||
      {};
    stops = tour.stops;
    if (stops) {
      stops.sort((a, b) => a.order > b.order);
    }
  }
  /*   let user = {};
  if (state.firestore.ordered.user) {
    user =
      state.firestore.ordered.users.filter(
        (user) => user.email === state.firebase.auth.email
      )[0] || {};
  } */

  return {
    stops: stops,
    initialValues: tour,
    tourId: tourId,
    tabStatus: state.async.tab_name,
  };
};

const TourControl = (props) => {
  const { initialValues, tourId } = props;
  const [mainNavActive, setMainNav] = useState("Tour Preview");

  const [markerList, setMarkerList] = useState([]);
  const [clickMarker, setClickMarker] = useState(null);
  /*  const [mapCenter, setCenter] = useState({ lat: 15.0, lng: 15.0 }); */
  /* const [mapZoom, setZoom] = useState(0); */
  const [Distance, setDistance] = useState(0);
  const [Duration, setDuration] = useState(0);
  const [workingStop, setworkingStop] = useState(
    initialValues.stops ? initialValues.stops[0] : null
  );
  const [clickLocation, setClickLocation] = useState(null);
  const [ActiveDetailsTab, setActiveDetailsTab] = useState("First details");

  const [businessMarker, setbusinessMarker] = useState([]);
  const [SelectedBusiness, setSelectedBusiness] = useState();

  //props.addTourToUser(tourId);

  /* useEffect functions 
  async function setListener(firestore) {
    await firestore.setListener({
      collection: "tours",
      doc: tourId,
    });
  }
  async function unSetListener(firestore) {
    await firestore.unsetListener({
      collection: "tours",
      doc: tourId,
    });
  }

  useEffect(() => {
    const { firestore } = props;
    if (tourId) {
      setListener(firestore);
    }

    return () => {
      unSetListener(firestore);
    };
  });
*/

  useEffect(() => {
    if (props.stops && props.stops[0] && initialValues.stops[0]) {
      checkStartingPoint(initialValues.stops[0].stop_location);
      renderRoute("USE EFFECT");
    }
  }, [props, initialValues, initialValues.stops]);

  /* useEffect end*

  /*block user from continue without create tour object first */
  const handleMainNavClick = (value) => {
    if (tourId) {
      setMainNav(value);
    } else {
      toastr.error("Enter tour name", "Please enter your tour name");
    }
  };

  const updateStop = async (update_stop) => {
    let wanted_stop = await initialValues.stops.filter(
      (stop) => stop.id === update_stop.id
    )[0];
    if (wanted_stop) {
      initialValues.stops = await initialValues.stops.map((stop) =>
        stop.id === update_stop.id ? update_stop : stop
      );
      await props.updateTour(initialValues);
    } else {
      update_stop = {
        ...update_stop,
        order: initialValues.stops.length,
        id: cuid(),
        all_media: update_stop.all_media ? [...update_stop.all_media] : [],
        created_date: new Date(),
        loc_pics: update_stop.loc_pics ? [...update_stop.loc_pics] : [],
      };
      let updated_stops = [...initialValues.stops, update_stop];
      let updated_tour = {
        ...initialValues,
        stops: [...updated_stops],
      };
      initialValues.stops = updated_stops;
      props.change("all_stops", updated_stops);
      await props.updateTour(updated_tour);
      setworkingStop(update_stop);
    }
    //renderRoute("UPDATE STOP");
    return update_stop.id;
  };

  const deleteStop = async (wanted_stop) => {
    //NEED TO delete all media
    initialValues.stops = initialValues.stops.filter(
      (stop) => stop.id !== wanted_stop.id
    );
    if (initialValues.stops[0]) {
      checkStartingPoint(initialValues.stops[0].stop_location);
    } else {
      setMarkerList([]);
    }

    await props.updateTour(initialValues);
    setworkingStop(null);
    // renderRoute("DELETE");
  };
  /* 
  var bar = new Promise((resolve, reject) => {
    foo.forEach((value, index, array) => {
        console.log(value);
        if (index === array.length -1) resolve();
    });
}); */
  const renderRoute = async (caller = "default") => {
    // console.log("THE CALLER", caller);
    let markerStops = [];
    //console.log("RENDERROUTE1", caller);
    await props.stops.forEach((stop) => {
      if (stop.stop_location) {
        markerStops.push({ location: stop.stop_location, order: stop.order });
      }
    });
    /* console.log(
      "RENDERROUTE props.stops markerStops",
      props.stops,
      markerStops,
      markerStops
    ); */
    setMarkerList(markerStops);
    //console.log("RENDERROUTE3", markerList);
    return;
  };

  const updateAllStops = async (all_stops) => {
    initialValues.stops = all_stops;
    await props.updateTour(initialValues);
    props.change("stops", all_stops);
    renderRoute("UPDATE ALL STOPS");
  };

  /* Rendering the main area of the cteation of the tour */
  const renderMainNav = () => {
    switch (mainNavActive) {
      /* case "Main Location":
        return (
          <PeakMainLocation
            saveChanges={onPeakAddSubmit}
            setZoom={setZoom}
            setCenter={setCenter}
            setSelectMarker={setClickMarker}
          />
        ); */
      case "Tour Preview":
        return (
          <TourDetails
            onFormSubmit={onFormSubmit}
            ActiveTab={ActiveDetailsTab}
            setActiveTab={setActiveDetailsTab}
          />
        );
      /*  case "Tour profile picture":
        return (
          <PeakProfilePic
            all_media={initialValues.all_media}
            tourID={initialValues.id}
            updateTour={props.updateTour}
            tour={initialValues}
          />
        ); */
      case "Create Route":
        if (tourId) {
          return (
            <CreateRoute
              setMarker={setClickMarker}
              markerList={markerList}
              setMarkerList={setMarkerList}
              tourId={tourId}
              checkStartingPoint={checkStartingPoint}
              setbusinessMarker={setbusinessMarker}
              updateStop={updateStop}
              updateAllStops={updateAllStops}
              selectedBusiness={SelectedBusiness}
              deleteStop={deleteStop}
              setCurrentStop={setworkingStop}
              clickLocation={clickLocation}
              setClickLocation={setClickLocation}
            />
          );
        } else {
          return <h1>Please create main location please</h1>;
        }
      default:
        break;
    }
  };

  const addStopToTour = (all_stops, newStop) => {
    const updated_stops = [...all_stops, newStop];
    props.change("all_stops", updated_stops);
  };

  const onFormSubmit = async (values) => {
    try {
      if (props.initialValues.id) {
        props.updateTour(values);
      } else {
        let createdTour = await props.createTour(values);
        props.change("id", createdTour.id);
        //props.updateTour(values);
        //console.log("updateTour", );
        props.history.push(`/tourControl/${createdTour.id}`);
        console.log("initial after push", props.initialValues);
      }

      switch (ActiveDetailsTab) {
        /*  case "First details":
          setActiveDetailsTab("General details");
          break; */
        case "General details":
          setActiveDetailsTab("General content");
          break;
        case "General content":
          setActiveDetailsTab("First details");
          break;
        case "Tour profile picture":
          setActiveDetailsTab("Pictures from the tour");
          break;
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  };

  /* const handleClickMap = (e) => {
    if (mainNavActive === "Main Location") {
      let location = { lat: e.lat, lng: e.lng, color: "red" };
      /* setClickMarker(location); *
      setCenter(location);
      try {
        props.change("main_location", location);
      } catch (error) {
        console.log(error);
      }
    }
  }; */

  const checkStartingPoint = async (starting_point) => {
    let doUpdate = false;
    if (
      initialValues.starting_point.latitude !== starting_point.latitude ||
      initialValues.starting_point.longitude !== starting_point.longitude
    ) {
      initialValues.starting_point = starting_point;
      doUpdate = true;
    }

    if (initialValues.distance !== Distance && Distance !== 0) {
      initialValues.distance = Distance;
      doUpdate = true;
    }
    if (initialValues.durationCalc !== Duration && Duration !== 0) {
      initialValues.durationCalc = Duration;
      doUpdate = true;
    }
    if (doUpdate === true) {
      // await props.updateTour(initialValues);
    }
  };

  const renderMainWorkZone = () => {
    switch (mainNavActive) {
      /* case "Tour Medi1":
        return <div>Media</div>;
      case "Main Location1":
        return (
          <MapComponent
            travelMode={google.maps.TravelMode.WALKING}
            selectedMarker={clickMarker}
            markerList={markerList}
            defaultZoom={mapZoom}
            bounds={bounds}
            defaultCenter={mapCenter}
            setCenter={setCenter}
            handleClickMap={handleClickMap}
          />
        ); */
      case "Tour Summary":
        return <TourPreview /*tour={initialValues}*/ />;
      case "Create Route":
        return (
          <RoutePreview
            tour={initialValues}
            places={markerList}
            businessPlaces={businessMarker}
            setSelectedBusiness={setSelectedBusiness}
            setDistance={setDistance}
            setDuration={setDuration}
            currentStop={workingStop}
            clickLocation={clickLocation}
            setClickLocation={setClickLocation}
            tabStatus={props.tabStatus}
          />
        );
      default:
        return <TourPreview tour={initialValues} />;
    }
  };

  /*Start Instraction modal */
  const [showInstraction, setShowInstraction] = useState(false);

  const renderInstraction = () => {
    console.log("RENDER INSTRACTION");
    switch (mainNavActive) {
      case "Tour Preview":
        switch (ActiveDetailsTab) {
          case "First details":
            return setShowInstraction("firstForm");
          case "General details":
            return setShowInstraction("tourInstraction");
          case "General content":
            return setShowInstraction("tourMediaInstraction");
          default:
            return;
        }
      default:
        return setShowInstraction("firstForm");
    }
  };
  useEffect(() => {
    if (showInstraction !== false) {
      renderInstraction();
    }
  }, [mainNavActive, ActiveDetailsTab]);
  /*End Instraction modal */

  return (
    <div className="controlArea">
      <div className="contextArea">
        <div className="mainTourHeader">Tour Details</div>
        <div className="subTourHeader">Here you start to build your tour</div>
        <div className="getInstraction" onClick={() => renderInstraction()}>
          Get some help
        </div>
        <TourMainNav
          activeTab={mainNavActive}
          handleTabChange={handleMainNavClick}
        />
        <InstractionController
          showInstraction={showInstraction}
          setShowInstraction={setShowInstraction}
        />
        <div>{renderMainNav()}</div>
      </div>
      <div className="mapArea">{renderMainWorkZone()}</div>
    </div>
  );
};

export default withFirestore(
  connect(
    mapState,
    actions
  )(
    reduxForm({
      form: "tourForm",
      enableReinitialize: true,
      destroyOnUnmount: false,
      forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    })(firestoreConnect([{ collection: "tours" }])(TourControl))
  )
);
