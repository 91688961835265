import React from "react";
import Fade from "react-reveal/Fade";
import plusImg from "../../images/plus.svg";
import minusImg from "../../images/minus.svg";
import "./cartStyle.css";

const CartList = ({ cartItems, removeFromCart, calcPrice, formatCurrency }) => {
  return (
    <div>
      {cartItems.length > 0 && (
        <div className="cart">
          <Fade left cascade>
            <ul className="cart-items">
              {cartItems.map((item) => (
                <li key={item.id} className="cart-item">
                  <div className="cartTop">
                    <img
                      className="cartImg"
                      src={item.tour_image}
                      alt={item.title}
                    ></img>
                    <div className="contentCartArea">
                      <div className="cartTitle">{item.title}</div>
                      <div className="right">
                        <div className="priceText">
                          {formatCurrency(calcPrice(item))} x {item.count}{" "}
                        </div>
                        {/*  <button onClick={() => removeFromCart(item)}>
                          <img src={minusImg} alt="add to cart" />
                        </button>
                        <button onClick={() => removeFromCart(item)}>
                          <img src={plusImg} alt="remove from cart" />
                        </button> */}
                        <button
                          className="button"
                          onClick={() => removeFromCart(item)}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </Fade>
        </div>
      )}
    </div>
  );
};

export default CartList;
