import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button, Icon } from "semantic-ui-react";
import "../../style/media.css";

const MediaList = ({
  all_media,
  setMediaList,
  deleteFuncSwitch,
  setText,
  setTextModal,
  setQuestionModal,
  setPhotoModal,
  setSelectedPhoto,
  setVideoModal,
  setSelectedVideo,
  setAudioModal,
  setSelectedAudio,
}) => {
  const [listItems, setListItems] = useState(all_media ? all_media : []);
  useEffect(() => {
    console.log("WE HAVE NEW ALL MEDIA", all_media);
    setListItems(all_media);
    listItems.sort((a, b) => (a.order > b.order ? 1 : -1));
    console.log("AFTER SORT MEDIA", listItems);
  }, [all_media]);
  const grid = 8;
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 0.5,
    margin: `0 0 ${grid}px 0`,
    width: "100%",
    height: "120px",
    borderRadius: "5px",
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.15)",
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "#f8f8f5",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  // a little function to help us with reordering the result
  /* const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }; */
  function promiseReorder(list, startIndex, endIndex) {
    var promise = new Promise(function (resolve, reject) {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      resolve(result);

      //reject(("It is a failure."));
    });
    return promise;
  }

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#eef5b3" : "#f8f8f5",
    padding: grid,
    width: "33vw",
  });

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    /* const items = reorde(
      listItems,
      result.source.index,
      result.destination.index
    );
    setMediaList(items);
    setListItems(items); */
    promiseReorder(
      listItems,
      result.source.index,
      result.destination.index
    ).then((result) => {
      setMediaList(result);
      setListItems(result);
    });
  };

  const tagByType = (item) => {
    if (!item.type) {
      return;
    }
    if (item.type.includes("image")) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <button
            className="saveButton"
            style={{ width: "90px", marginLeft: "15px", marginRight: "15px" }}
            onClick={() => {
              setSelectedPhoto(item);
              setPhotoModal(true);
            }}
          >
            Edit
          </button>
          <img className="photoItem" src={item.url} />
        </div>
      );
    } else if (item.type && item.type.includes("audio")) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <button
            className="saveButton"
            style={{ width: "90px", marginLeft: "15px", marginRight: "15px" }}
            onClick={() => {
              setSelectedAudio(item);
              setAudioModal(true);
            }}
          >
            Edit
          </button>
          <audio
            preload="auto"
            id="id12"
            controls="controls"
            src={item.url}
          ></audio>
        </div>
      );
    } else if (item.type.includes("video")) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <button
            className="saveButton"
            style={{ width: "90px", marginLeft: "15px", marginRight: "15px" }}
            onClick={() => {
              setSelectedVideo(item);
              setVideoModal(true);
            }}
          >
            Edit
          </button>

          {/* <img className="photoItem" src={item.url} /> */}
          {
            <video poster={item.poster_url} width="180" hight="120" controls>
              <source src={item.url} type={item.type} />
            </video>
          }
        </div>
      );
    } else if (item.type.includes("text")) {
      return (
        <button
          className="saveButton"
          onClick={() => {
            setText({
              title: item.title ? item.title : "",
              content: item.content ? item.content : "",
              order: item.order,
            });

            setTextModal(true);
          }}
        >
          Read & Edit
        </button>
      );
    } else if (item.type.includes("question")) {
      return (
        <button
          className="saveButton"
          onClick={() => {
            setText(item);
            setQuestionModal(true);
          }}
        >
          Read & Edit
        </button>
      );
    }
  };
  console.log("listItems", listItems);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable12">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {listItems &&
              listItems.map((item, index) => (
                <Draggable
                  key={item.name}
                  draggableId={item.name}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <Button
                        icon
                        size="mini"
                        style={{
                          backgroundColor: "lightgray",
                          padding: "0",
                          float: "right",
                          marginLeft: "15px",
                        }}
                        onClick={() =>
                          window.confirm("Do you want to remove that media?")
                            ? deleteFuncSwitch(item)
                            : null
                        }
                      >
                        <Icon name="close" />
                      </Button>
                      <div className="contentArea">
                        <div className="leftCard">
                          <div>{index + 1}</div>
                          <div className="cardTitle">
                            {" "}
                            {item.title ? item.title : item.type}
                          </div>
                        </div>
                        <div className="mediaArea">{tagByType(item)}</div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default MediaList;
