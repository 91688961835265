import React, { useEffect, useState } from "react";
import { Table, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import "./salesPage.css";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import DatePicker from "react-datepicker";

/* const getFullPurchase = (tour, all_purchase) => {
  let all_sales = [];
  console.log("ALL PURCHASE", all_purchase);
  all_purchase.map((purchase) => {
    console.log("CHECL APP SALE", purchase.id);
    if (purchase.tourID === tour.id) {
      all_sales.push({
        ...purchase,
        name: tour.name,
        price: tour.price,
      });
    }
  });

  return all_sales;
}; */

const mapState = (state, ownProps) => {
  let web_sale = [];
  let app_sale = [];
  let app_sale_comb = [];
  let web_sale_comb = [];

  let guide_id = null;

  if (!ownProps.match) {
    guide_id = ownProps.guideId;
  } else if (ownProps.match.params.guideId) {
    guide_id = ownProps.match.params.guideId;
  } else {
    guide_id = state.firebase.auth.uid;
  }

  if (guide_id && state.firestore.ordered.webPurchase) {
    state.firestore.ordered.webPurchase.map((purchase) => {
      if (purchase.guideID && purchase.guideID.includes(guide_id)) {
        web_sale.push(purchase);
      }
    });
  }

  if (guide_id && state.firestore.ordered.purchase) {
    state.firestore.ordered.purchase.map((purchase) => {
      if (purchase.guideID && purchase.guideID.includes(guide_id)) {
        app_sale.push(purchase);
      }
    });
  }

  if (guide_id && state.firestore.ordered.tour_in_general) {
    /* let temp_arr = []; */

    state.firestore.ordered.tour_in_general.map((tour) => {
      /*  temp_arr = [];
      temp_arr = getFullPurchase(tour, app_sale);
      if (temp_arr.length > 0) {
        app_sale_comb.push(...temp_arr);
      }
      temp_arr = [];
      temp_arr = getFullPurchase(tour, web_sale);
      if (temp_arr.length > 0) {
        web_sale_comb.push(...temp_arr);
      } */

      app_sale.map((purchase) => {
        if (purchase.tourID === tour.id) {
          state.firestore.ordered.appUsers.map((user) => {
            if (user.id === purchase.userID) {
              app_sale_comb.push({
                ...purchase,
                name: tour.name,
                price: tour.price,
                user: user,
              });
            }
          });
        }
      });
      web_sale.map((purchase) => {
        if (tour.id === purchase.tourID) {
          state.firestore.ordered.appUsers.map((user) => {
            if (user.id === purchase.userID) {
              web_sale_comb.push({
                ...purchase,
                name: tour.name,
                price: tour.price,
                user: user,
              });
            }
          });
        }
      });
    });
  }
  /*   console.log("COMB-SALE", web_sale_comb, app_sale_comb); */
  return {
    auth: state.firebase.auth,
    sales: [],
    app_sale: app_sale_comb,
    web_sale: web_sale_comb,
  };
};

const SalesPage = (props) => {
  const [appArray, setAppArray] = useState([]);
  const [webArray, setWebArray] = useState([]);
  const [webSalesCount, setWebSalesCount] = useState(0);
  const [appSalesCount, setAppSalesCount] = useState(0);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  useEffect(() => {
    //if(props.app_sale.length)
    setAppArray(props.app_sale);
    setWebArray(props.web_sale);
    setWebSalesCount(props.web_sale.length);
    setAppSalesCount(props.app_sale.length);
    sortByDay(props.app_sale);
    sortByDay(props.web_sale);
  }, [props]);

  const sortByDay = (array) => {
    if (array[0] && array[0].coupon) {
      /* console.log("SORT ARRAY APP", array); */
      let new_arr = array.sort((a, b) => a.time < b.time);
      /* console.log("AFTER SORT APP", new_arr); */
      setAppArray(new_arr);
    } else {
      /* console.log("SORT ARRAY WEB", array); */
      let new_arr = array.sort((a, b) => a.time < b.time);
      /* console.log("AFTER SORT WEB", new_arr); */
      setWebArray(new_arr);
    }
  };
  const fillterByDay = (array, from, to) => {
    if (array[0] && array[0].time) {
      let new_arr = array.filter(
        (purchase) =>
          purchase.time.toDate() < to && purchase.time.toDate() > from
      );
      setAppArray(new_arr);
    } else {
      let new_arr = array.sort((a, b) => a.date < b.date);
      setWebArray(new_arr);
    }
  };

  /*  console.log("webSalesCount", webSalesCount, "appSalesCount", appSalesCount); */

  return (
    <div className="salesTable">
      <h1>App sales</h1>
      <div className="dataFilter">
        <Header dividing size="small" content="From" />
        <DatePicker
          selected={fromDate}
          onChange={(date) => setFromDate(date)}
        />
        <Header dividing size="small" content="To:" />
        <DatePicker
          wrapperClassName="datePicker"
          selected={toDate}
          onChange={(date) => setToDate(date)}
        />
        <button
          style={{ margin: "15px" }}
          onClick={() => fillterByDay(props.app_sale, fromDate, toDate)}
        >
          {" "}
          filter
        </button>
      </div>

      <Table celled compact definition>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>Tour name</Table.HeaderCell>
            <Table.HeaderCell>Purchase Date</Table.HeaderCell>
            <Table.HeaderCell>Price</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Coupon</Table.HeaderCell>
            <Table.HeaderCell>User email</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {appArray.map((sale) => (
            <Table.Row id={sale.id}>
              <Table.Cell>{sale.name}</Table.Cell>
              <Table.Cell>
                {
                  sale.time.toDate().toDateString()
                  //? sale.time.toDate().toDateString()
                  //sale.time
                }
              </Table.Cell>
              <Table.Cell>{sale.price}</Table.Cell>
              <Table.Cell>{sale.amount}</Table.Cell>
              <Table.Cell>{sale.coupon}</Table.Cell>
              <Table.Cell>
                {sale.user ? sale.user.email : sale.userID}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <h1>Web sales</h1>
      <Table celled compact definition>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>Tour name</Table.HeaderCell>
            <Table.HeaderCell>Purchase Date</Table.HeaderCell>
            <Table.HeaderCell>Price</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Coupon</Table.HeaderCell>
            <Table.HeaderCell>User email</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {webArray.map((sale) => (
            <Table.Row id={sale.id}>
              <Table.Cell>{sale.name}</Table.Cell>
              <Table.Cell>{sale.time}</Table.Cell>
              <Table.Cell>{sale.price}</Table.Cell>
              <Table.Cell>{sale.amount}</Table.Cell>
              <Table.Cell>{sale.source_id}</Table.Cell>
              <Table.Cell>
                {sale.user ? sale.user.email : sale.userID}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default compose(
  connect(mapState),
  firestoreConnect(() => [
    { collection: "webPurchase" },
    { collection: "purchase" },
    { collection: "tour_in_general" },
  ])
)(SalesPage);
