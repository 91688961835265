import React, { useEffect, useState } from "react";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import { Table, Header } from "semantic-ui-react";
import { addCoupon, deleteCoupon, disableCoupon } from "../cart/cartActions";

const actions = { addCoupon, deleteCoupon, disableCoupon };

const mapState = (state) => {
  return {
    coupons: state.firestore.ordered.coupons,
  };
};

const CouponComponent = ({
  coupons,
  addCoupon,
  deleteCoupon,
  disableCoupon,
}) => {
  const [couponVal, setCouponVal] = useState("");
  const [couponNum, setCouponNum] = useState(1);
  const [couponDiscount, setCouponDiscount] = useState(1);
  const [couponOwner, setCouponOwner] = useState("Dguide");
  const [couponTour, setCouponTour] = useState("");
  const [allCoupons, setAllCoupons] = useState(undefined);

  useEffect(() => {
    if (coupons) {
      coupons.sort((a, b) => {
        if (a.value.toLowerCase() > b.value.toLowerCase()) {
          return 1;
        } else {
          return -1;
        }
      });
      setAllCoupons(coupons);
    }
  }, [coupons]);

  const createCoupons = () => {
    for (var i = 1; i <= couponNum; i++) {
      var new_coupon = {
        value: couponVal + i,
        owner: couponOwner,
        tours: [couponTour],
        usage: [],
        discount: Number(couponDiscount),
      };

      addCoupon(new_coupon);
    }
  };

  return (
    <div>
      <div>
        <div>
          <h4>name</h4>
          <input
            onChange={(e) => setCouponVal(e.target.value)}
            className="contactForm"
            name="coupon_value"
            type="text"
            placeholder="Coupon Value"
            value={couponVal}
          />
        </div>
        <div>
          <h4>Amount of coupons</h4>
          <input
            onChange={(e) => setCouponNum(e.target.value)}
            className="contactForm"
            name="coupon_num"
            type="number"
            placeholder="Coupon Amount"
            value={couponNum}
          />
        </div>
        <div>
          <h4>Discount</h4>
          <input
            onChange={(e) => setCouponDiscount(e.target.value)}
            className="contactForm"
            name="coupon_discount"
            type="number"
            placeholder="Coupon Discount"
            value={couponDiscount}
          />
        </div>
        <div>
          <h4>coupons owner</h4>
          <input
            onChange={(e) => setCouponOwner(e.target.value)}
            className="contactForm"
            name="coupon_owner"
            type="text"
            placeholder="Coupon Owner"
            value={couponOwner}
          />
        </div>
        <div>
          <h4>Whitch tour?</h4>
          <input
            onChange={(e) => setCouponTour(e.target.value)}
            className="contactForm"
            name="coupon_tour"
            type="text"
            placeholder="Coupon Tour"
            value={couponTour}
          />
        </div>
        <button style={{ margin: "15px" }} onClick={() => createCoupons()}>
          {" "}
          create
        </button>
      </div>
      <Table celled compact definition>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell>Coupon name</Table.HeaderCell>
            <Table.HeaderCell>discount</Table.HeaderCell>
            <Table.HeaderCell>Usage</Table.HeaderCell>
            <Table.HeaderCell>tours</Table.HeaderCell>
            <Table.HeaderCell>Delete</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {allCoupons &&
            allCoupons.map((sale) => (
              <Table.Row id={sale.id}>
                <Table.Cell>{sale.value && sale.value}</Table.Cell>
                <Table.Cell>{sale.discount}</Table.Cell>
                <Table.Cell>
                  <b>{sale.usage && sale.usage.length}</b> <br />
                  {sale.usage &&
                    sale.usage.map((usage) => usage + "  | " + <br />)}
                </Table.Cell>
                <Table.Cell>
                  {sale.tours && sale.tours.map((tour) => tour)}
                </Table.Cell>
                <Table.Cell>
                  <button onClick={() => deleteCoupon(sale)}>DELETE</button>
                  <button onClick={() => disableCoupon(sale)}>DISABLE</button>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default compose(
  connect(mapState, actions),
  firestoreConnect(() => [{ collection: "coupons" }])
)(CouponComponent);
