import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Checkbox, Header, Table } from "semantic-ui-react";
import { updateHomePage, toursWithoutStops } from "../tour/tourAction";

const query = (props) => {
  return [
    { collection: "approval_tours" },
    { collection: "users" },
    { collection: "homepage" },
  ];
};

const actions = {
  updateHomePage,
  toursWithoutStops,
};

const mapState = (state) => {
  return {
    aprv_tours: state.firestore.ordered.approval_tours,
    app_users: state.firestore.ordered.appUsers,
    homePage: state.firestore.ordered.homepage,
  };
};
const HomePageAllocation = ({
  aprv_tours,
  app_users,
  homePage,
  updateHomePage,
  toursWithoutStops,
}) => {
  const [tours, setTours] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedTour, setSelectedTour] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [withBusiness, setWithBusiness] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let theTours = [];
    if (aprv_tours) {
      aprv_tours.map((tour) => {
        theTours.push({ key: tour.id, text: tour.title, value: tour });
      });
      setTours(theTours);
    }
  }, [aprv_tours]);

  useEffect(() => {
    let theUsers = [];
    if (app_users) {
      app_users.map((user) => {
        if (user.email) {
          theUsers.push({ key: user.id, text: user.email, value: user });
        }
      });

      theUsers.sort(function (a, b) {
        if (a.text > b.text) {
          return 1;
        } else {
          return -1;
        }
      });

      setUsers(theUsers);
    }
  }, [app_users]);

  useEffect(() => {
    let theCategories = [];
    console.log("HOME PAGE", homePage);
    if (homePage) {
      homePage[0].tours.map((cat) => theCategories.push(cat));
      setCategories(theCategories);
    }
  }, [homePage]);

  const [newCategoryEn, setNewCategoryEn] = useState("");
  const onEnglishCat = (option) => {
    setNewCategoryEn(option.target.value);
  };
  const [newCategoryHe, setNewCategoryHe] = useState("");
  const onHebrewCat = (option) => {
    setNewCategoryHe(option.target.value);
  };

  const addNewCategory = () => {
    setCategories([
      ...categories,
      { title: { he: newCategoryHe, en: newCategoryEn }, tours: [] },
    ]);
  };
  const removeCategory = (catIndex) => {
    const tmpCat = categories;
    tmpCat.splice(catIndex, 1);
    setCategories([...tmpCat]);
  };
  const getTourName = (tourID) => {
    let wantedTour = tours.filter((tour) => {
      return tour.key === tourID;
    });

    return wantedTour[0].text;
  };

  const addTourToCategory = () => {
    let index = categories.findIndex(
      (cat) => cat.title["en"] === selectedCategory
    );

    let tempCat = categories;

    tempCat[index] = {
      title: categories[index].title,
      tours: [...categories[index].tours, selectedTour],
    };

    setCategories([...tempCat]);
  };

  const changeTourLocation = (catIdx, tourIdx, dir) => {
    let tmpCat = categories;

    var element = tmpCat[catIdx].tours[tourIdx];
    tmpCat[catIdx].tours.splice(tourIdx, 1);
    if (dir !== 0) tmpCat[catIdx].tours.splice(tourIdx + dir, 0, element);
    setCategories([...tmpCat]);
  };
  const calcKey = (tours) => {
    const key = [];
    tours.map((id) => key.push(id[0]));
    return key.join("");
  };
  const saveCategories = () => {
    homePage[0].tours = categories;

    updateHomePage(homePage);
  };
  const renderTours = (tours, catIndex) => {
    return (
      <div>
        {tours.map((tour, tourIndex) => {
          return (
            <div key={tour} id={tour}>
              {`${getTourName(tour)}`}

              <button
                onClick={() => changeTourLocation(catIndex, tourIndex, 0)}
              >
                Remove tour
              </button>
              <button
                onClick={() => changeTourLocation(catIndex, tourIndex, -1)}
              >
                up tour
              </button>
              <button
                onClick={() => changeTourLocation(catIndex, tourIndex, 1)}
              >
                down tour
              </button>
            </div>
          );
        })}
      </div>
    );
  };

  const convertTours = () => {
    toursWithoutStops(aprv_tours);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "inline-block",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div style={{ margin: "15px" }}>
        <button onClick={convertTours}>Convert Tours</button>
      </div>
      <Header size="small" content="Peak Tour" />
      <select
        multiple
        onChange={(e) => {
          console.log("e", e.target.value);
          setSelectedTour(e.target.value);
        }}
        value={tours.text}
        style={{ margin: "20px", width: "30%", height: "150px" }}
      >
        {tours.map((tour) => (
          <option key={tour.key} value={tour.key}>
            {tour.text}
          </option>
        ))}
      </select>
      <Header size="small" content="Peak Category" />
      <select
        multiple
        onChange={(e) => {
          console.log("e", e.target.value);
          setSelectedCategory(e.target.value);
        }}
        value={tours.text}
        style={{ margin: "20px", width: "30%", height: "150px" }}
      >
        {categories.map((cat) => (
          <option key={cat.title["en"]} value={cat.title["en"]}>
            {cat.title["en"]}
          </option>
        ))}
      </select>
      <div style={{ margin: "15px" }}>
        <button onClick={addTourToCategory}>Add tour to category</button>
      </div>
      {/* <select
        options={options.filter(
          (option) => option.value !== this.state.value1.value
        )}
      /> 
      <Checkbox slider onClick={() => setWithBusiness(!withBusiness)} />*/}

      <input
        name="enCat"
        placeholder="English category"
        onChange={(e) => onEnglishCat(e)}
      />
      <input
        name="heCat"
        placeholder="Hebrew category"
        onChange={(e) => onHebrewCat(e)}
      />
      <div style={{ margin: "15px" }}>
        <button onClick={addNewCategory}>Add New Category</button>
      </div>
      <div>
        <Table celled compact definition>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>Catagory name</Table.HeaderCell>
              <Table.HeaderCell>Tours inside</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {categories &&
              categories.map((cat, catIndex) => (
                <Table.Row id={calcKey(cat.tours)} key={calcKey(cat.tours)}>
                  <Table.Cell>{`English :${cat.title["en"]} |---| Hebrew :${cat.title["he"]}`}</Table.Cell>
                  <Table.Cell>
                    {cat.tours && renderTours(cat.tours, catIndex)}
                  </Table.Cell>
                  <Table.Cell>
                    <button onClick={() => removeCategory(catIndex)}>
                      DELETE
                    </button>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
        <div style={{ margin: "15px" }}>
          <button onClick={saveCategories}>SAVE category</button>
        </div>
      </div>
    </div>
  );
};

export default compose(
  connect(mapState, actions),
  firestoreConnect((props) => query(props))
)(HomePageAllocation);
