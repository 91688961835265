import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { initialize, reduxForm } from "redux-form";
import VideoComponent from "../media/VideoComponent";
import PhotoComponent from "../media/PhotoComponent";
import AudioComponent from "../media/AudioComponent";
import { setMainPhoto } from "../../tour/tourAction";
import { toastr } from "react-redux-toastr";
import MediaList from "../../media/MediaList";
import TextComponent from "../media/TextComponent";
import QuestionComponent from "../media/QuestionComponent";
import {
  deleteStopFile,
  deleteStopVideo,
  deleteStopText,
  removeQuestion,
  generalUploadFile,
  generalDeleteFile,
} from "../../media/mediaActions";
import EditPhoto from "../media/EditPhoto";
import EditVideo from "../media/EditVideo";
import EditAudio from "../media/EditAudio";

const actions = {
  deleteStopFile,
  deleteStopVideo,
  setMainPhoto,
  deleteStopText,
  removeQuestion,
  generalUploadFile,
  generalDeleteFile,
};

const mapState = (state, props) => {
  let all_media = [];

  if (props.stop.all_media) {
    all_media = props.stop.all_media;
  }

  return {
    all_media: all_media,
    firestore: state.firestore,
    initialValues: state.form.stopForm.values,
    loading: state.async.loading,
  };
};

const StopMedia = (props) => {
  const {
    initialValues,
    saveChanges,
    loading,
    setMainPhoto,
    tourId,
    all_media,
    generalUploadFile,
    generalDeleteFile,
    stop,
  } = props;

  const [photoOpen, setPhotoModal] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState();
  const [editPhotoModal, setEditdPhotoModal] = useState(false);
  const [editPhoto, setEditdPhoto] = useState(false);
  const [videoOpen, setVideoModal] = useState(false);
  const [editVideoModal, setEditVideoModal] = useState(false);
  const [editVideo, setEditVideo] = useState(false);
  const [audioOpen, setAudioModal] = useState(false);
  const [editAudioModal, setEditAudioModal] = useState(false);
  const [editAudio, setEditAudio] = useState(false);
  const [textOpen, setTextModal] = useState(false);
  const [textContent, setTextContent] = useState();
  const [questionOpen, setQuestionModal] = useState(false);

  const handleSetMainFile = async (photo, stop) => {
    try {
      await setMainPhoto(photo, stop);
    } catch (error) {
      toastr.error("Oops", error.message);
    }
  };

  const setMediaList = (updatedList) => {
    updatedList.map((item, index) => (item.order = index));
    initialize.all_media = [...updatedList];
  };

  const updatePoster = async (new_poster, new_title) => {
    let doUpdate = false;
    let update_stop = {};
    let new_media = {};
    let new_video = { ...editVideo };
    if (!editVideo.poster_url) {
      new_media = await generalUploadFile(new_poster[0], stop.id, "stop");

      new_video = {
        ...editVideo,
        poster_url: new_media.url,
        poster_name: new_media.name,
      };
      doUpdate = true;
    }
    if (new_title !== editVideo.title) {
      new_video = {
        ...new_video,
        title: new_title,
      };
      doUpdate = true;
    }
    if (doUpdate) {
      all_media[editVideo.order] = new_video;
      update_stop = {
        ...stop,
        all_media: [...all_media],
      };

      await saveChanges(update_stop);
      setEditVideo(false);
      setEditVideoModal(false);
    }
  };

  const deletPoster = async (obj) => {
    await generalDeleteFile(
      { name: obj.poster_name, type: "image" },
      stop.id,
      "stopMedia"
    );
    let new_media = { ...obj, poster_url: null, poster_name: null };
    all_media[obj.order] = new_media;

    let update_stop = {
      ...stop,
      all_media: [...all_media],
    };
    await saveChanges(update_stop);
  };

  const deleteFile = async (file) => {
    if (!file.type) {
      return;
    }
    if (file.type.includes("video")) {
      await generalDeleteFile(file, stop.id, "stopMedia");
    } else if (
      file.type.includes("image") ||
      file.type.includes("audio") ||
      file.type.includes("video")
    ) {
      await generalDeleteFile(file, stop.id, "stopMedia");
    }

    let new_all_media = all_media.filter((media) => media.name !== file.name);
    new_all_media.map((media) => {
      if (media.order > file.order) media.order = media.order - 1;
    });
    let update_stop = {
      ...stop,
      all_media: new_all_media,
    };

    await saveChanges(update_stop);
  };

  const updateTitle = async (obj, new_title) => {
    all_media[obj.order].title = new_title;
    let update_stop = {
      ...stop,
      all_media: [...all_media],
    };
    await saveChanges(update_stop);
  };

  const uploadFile = async (file, fileTitle = "file", poster = {}) => {
    let new_media = await generalUploadFile(
      file,
      stop.id,
      "stop",
      fileTitle,
      poster
    );
    new_media = {
      ...new_media,
      order: all_media ? all_media.length : 0,
    };
    let new_all_media = [...all_media, new_media];
    let update_stop = {
      ...stop,
      all_media: new_all_media,
    };

    await saveChanges(update_stop);
  };

  const uploadWithoutFile = (question) => {
    let media = {};
    let new_all_media = [];
    if (question.order) {
      media = {
        ...question,
        order: question.order,
      };
      new_all_media = [...all_media];
      new_all_media[question.order] = media;
    } else {
      media = {
        ...question,
        order: all_media ? all_media.length : 0,
      };
      new_all_media = [...all_media, media];
    }

    let update_stop = {
      ...stop,
      all_media: new_all_media,
    };
    saveChanges(update_stop);
  };
  const orderMedia = () => {
    return all_media.sort((a, b) => (a.order > b.order ? 1 : -1));
  };
  return (
    <Fragment>
      <div style={{ float: "right" }}>
        <button
          className="saveButton"
          onClick={() => saveChanges(initialValues)}
        >
          Save changes
        </button>
      </div>
      <button className="addButton" onClick={() => setPhotoModal(true)}>
        + Photo
      </button>
      <button className="addButton" onClick={() => setVideoModal(true)}>
        + Video
      </button>
      <button className="addButton" onClick={() => setAudioModal(true)}>
        + Audio
      </button>
      <button
        className="addButton"
        onClick={() => {
          setTextContent({});
          setTextModal(true);
        }}
      >
        + Text
      </button>
      <button
        className="addButton"
        onClick={() => {
          setTextContent({});
          setQuestionModal(true);
        }}
      >
        + Question
      </button>

      <PhotoComponent
        loading={loading}
        objectId={initialValues.id}
        tourId={tourId}
        all_media={all_media}
        handleSetMainFile={handleSetMainFile}
        handleDeletePhoto={deleteFile}
        open={photoOpen}
        onClose={() => setPhotoModal(false)}
        generalUploadFile={uploadFile}
        selectedPhoto={selectedPhoto}
      />
      <VideoComponent
        open={videoOpen}
        onClose={() => setVideoModal(false)}
        objectId={initialValues.id}
        all_media={all_media}
        collectionName={"stops"}
        tourId={initialValues.tour_owner}
        handleDeleteFile={deleteFile}
        generalUploadFile={uploadFile}
      />

      <AudioComponent
        open={audioOpen}
        onClose={() => setAudioModal(false)}
        objectId={initialValues.id}
        tourId={initialValues.tour_owner}
        all_media={all_media}
        collectionName={"stops"}
        handleDeleteFile={deleteFile}
        generalUploadFile={uploadFile}
      />

      <TextComponent
        open={textOpen}
        onClose={() => setTextModal(false)}
        objectId={initialValues.id}
        collectionName={"stops"}
        handleDeleteFile={deleteFile}
        uploadText={uploadWithoutFile}
        context={textContent}
      />

      <QuestionComponent
        open={questionOpen}
        onClose={() => setQuestionModal(false)}
        /*objectId={initialValues.id}
        tourId={initialValues.tour_owner}
        all_media={all_media} 
        collectionName={"stops"}*/
        uploadQuestion={uploadWithoutFile}
        content={textContent}
      />
      <EditPhoto
        open={editPhotoModal}
        onClose={() => setEditdPhotoModal(false)}
        image={editPhoto}
        updatePhoto={updateTitle}
      />
      <EditVideo
        open={editVideoModal}
        onClose={() => setEditVideoModal(false)}
        video={editVideo}
        deletPoster={deletPoster}
        updatePoster={updatePoster}
      />
      <EditAudio
        open={editAudioModal}
        onClose={() => setEditAudioModal(false)}
        audio={editAudio}
        updateAudio={updateTitle}
      />
      {
        /* initialValues. */ all_media && (
          <MediaList
            all_media={/* initialValues.all_media  all_media */ orderMedia()}
            setMediaList={setMediaList}
            deleteFuncSwitch={deleteFile}
            setText={setTextContent}
            setTextModal={setTextModal}
            setQuestionModal={setQuestionModal}
            selectedPhoto={selectedPhoto}
            setPhotoModal={setEditdPhotoModal}
            setSelectedPhoto={setEditdPhoto}
            setVideoModal={setEditVideoModal}
            setSelectedVideo={setEditVideo}
            setAudioModal={setEditAudioModal}
            setSelectedAudio={setEditAudio}
          />
        )
      }
    </Fragment>
  );
};

export default connect(
  mapState,
  actions
)(
  reduxForm({
    form: "stopForm",
    enableReinitialize: true,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })(StopMedia)
);
