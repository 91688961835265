import React, { useState } from "react";
import { withFirestore } from "react-redux-firebase";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Form, Header } from "semantic-ui-react";
import TextInput from "../../../app/common/form/textInput";
import textAreaInput from "../../../app/common/form/textAreaInput";
import PhotoComponent from "../../stop/media/PhotoComponent";
import { generalUploadFile, generalDeleteFile } from "../../media/mediaActions";
import defaultPic from "../../../images/default-profile.png";

const actions = { generalUploadFile, generalDeleteFile };
const mapState = (state) => {
  let formValues = {};

  if (state.form.businessForm) {
    formValues = state.form.businessForm.values;
  }
  return {
    initialValues: formValues,
    loading: state.async.loading,
  };
};
const OfferForm = (props) => {
  const {
    invalid,
    submitting,
    initialValues,
    loading,
    generalUploadFile,
    generalDeleteFile,
    onFormSubmit,
  } = props;
  const [photoOpen, setPhotoModal] = useState(false);

  const uploadFile = async (file, fileTitle = "file") => {
    let new_media = await generalUploadFile(
      file,
      initialValues.id,
      "business",
      "Coupon pic"
    );
    let update_business = {
      ...initialValues,
      product_pic: new_media,
    };
    await onFormSubmit(update_business);
    props.change("product_pic", new_media);
    setPhotoModal(false);
  };

  const deleteFile = async (file) => {
    await generalDeleteFile(file, initialValues.id, "businessMedia");
    let update_business = {
      ...initialValues,
      product_pic: null,
    };
    await onFormSubmit(update_business);
    props.change("product_pic", {});
  };
  console.log("initialValues", initialValues);
  return (
    <div>
      <Form onSubmit={props.handleSubmit(props.onFormSubmit)}>
        <div className="businessForm">
          <div className="formOne">
            <Header size="small" content="Product name" />
            <Field
              name="product_name"
              component={TextInput}
              placeholder="product name"
              type="text"
            />
          </div>
          <div className="formOne">
            <Header size="small" content="Offer price" />
            <Field
              name="offer_price"
              component={TextInput}
              placeholder="Offer price"
              type="number"
            />
          </div>
          <div className="formOne">
            <Header size="small" content="Product barcode" />
            <Field
              name="product_barcode"
              component={TextInput}
              placeholder="product barcode"
              type="text"
            />
          </div>
          <div className="profilePicture">
            <div className="profileHeader">Offer picture</div>
            {initialValues.product_pic && initialValues.product_pic.url ? (
              <div className="profileImgObj">
                <img
                  className="profileImg"
                  src={initialValues.product_pic.url}
                  alt="profile image1"
                  //style={{ width: "150px", height: "150px" }}
                />
                <button
                  className="profileSave"
                  onClick={() =>
                    window.confirm("Do you want to change the picture?")
                      ? deleteFile(initialValues.product_pic)
                      : null
                  }
                >
                  Change picture
                </button>
              </div>
            ) : (
              <div className="profileImgObj">
                <img
                  className="profileImg"
                  src={defaultPic}
                  alt="profile image2"
                  //style={{ width: "150px", height: "150px" }}
                />
                <button
                  className="addButton"
                  onClick={() => setPhotoModal(true)}
                >
                  + Photo
                </button>
              </div>
            )}
          </div>

          <div className="formOne">
            <Header size="small" content="Offer in details" />
            <Field
              name="offer_in_details"
              type="textarea"
              component={textAreaInput}
              placeholder="What the is your offer includs? Min of 25 words"
              rows={3}
            />
          </div>
          {/* <div className="formOne">
            <Header size="small" content="More details" />
            <Field
              name="more_details"
              type="textarea"
              component={textAreaInput}
              placeholder="Tell us more, what to expect? Min of 25 words"
              rows={3}
            />
          </div> */}
          <div>
            {/* <Header
              size="small"
              content="Coupon picture - this is what the customers will display to you to get their value "
            />

            <button
              className="addButton"
              style={{ width: "200px" }}
              onClick={() => setPhotoModal(true)}
            >
              Mangage Coupons
            </button>
             {initialValues.coupon_pic && initialValues.coupon_pic.url && (
              <Image
                src={initialValues.coupon_pic.url}
                style={{ height: "200px", width: "200px" }}
              />
            )}
            {
              <PhotoComponent
                loading={loading}
                objectId={initialValues.id}
                tourId={initialValues.id}
                
                handleSetMainFile={() => console.log("main")}
                handleDeletePhoto={deleteFile}
                open={photoOpen}
                onClose={() => setPhotoModal(false)}
                generalUploadFile={uploadFile}
              />
            } */}
          </div>
        </div>
        <button
          className="saveBusinessButton"
          disabled={invalid || submitting}
          positive
          type="submit"
          onClick={() => props.onFormSubmit(initialValues)}
        >
          save & continue
        </button>
      </Form>
      <PhotoComponent
        loading={loading}
        handleDeletePhoto={deleteFile}
        generalUploadFile={uploadFile}
        open={photoOpen}
        onClose={() => setPhotoModal(false)}
        constAspectRation="square"
      />
    </div>
  );
};

export default withFirestore(
  connect(
    mapState,
    actions
  )(
    reduxForm({
      form: "businessForm",
      //validate,
      enableReinitialize: true,
      destroyOnUnmount: false,
      forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    })(OfferForm)
  )
);
