import { toastr } from "react-redux-toastr";

export const conferenceRegister = (data) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const registerData = {
      ...data,
      time: new Date(),
    };
    if (data.conference_name) {
      try {
        await firestore.add("conferenceRegister", registerData);
        toastr.success(
          "Welcome to D-guide!",
          "Your request has been seccessfully received."
        );
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await firestore.add("guideRegister", registerData);
        toastr.success(
          "Welcome to D-guide!",
          "Your request has been seccessfully received."
        );
      } catch (error) {
        console.log(error);
      }
    }
  };
};
