import { /* DELETE_TOUR, */ FETCH_TOUR } from "./tourConstarnts";
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
} from "../async/asyncActions";
import { fetchSampleData } from "../../app/data/mockApi";
import { toastr } from "react-redux-toastr";
import { createNewTour } from "../../app/common/helpers";
import cuid from "cuid";

export const uploadImage =
  (file, fileName, basePath, tour) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const imageName = cuid();
    const firebase = getFirebase();
    const firestore = getFirestore();
    const options = {
      name: imageName,
    };

    try {
      dispatch(asyncActionStart);
      //upload the file to firebase storage
      let uploadedFile = await firebase.uploadFile(
        basePath,
        file,
        null,
        options
      );
      //get the url of the image
      let downloadURL =
        await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL();
      //add the image to firestore
      if (tour.tour_media) {
        let tour_media = [
          ...tour.tour_media,
          {
            name: imageName,
            url: downloadURL,
            type: file.type,
          },
        ];
        tour = {
          ...tour,
          tour_media,
        };
      } else {
        let tour_media = [
          {
            name: imageName,
            url: downloadURL,
            type: file.type,
          },
        ];
        tour = {
          ...tour,
          tour_media,
          profile_pic: {
            name: imageName,
            url: downloadURL,
            type: file.type,
          },
        };
      }

      await firestore.update(`tours/${tour.id}`, tour);
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      toastr.error("Opss", "File upload faild. Try agian");
    }
  };

export const uploadAudio =
  (file, basePath, tour, audioTitle) =>
  async (dispatch, setState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const audioName = cuid();
    const options = {
      name: audioName,
    };
    try {
      dispatch(asyncActionStart);
      let uploadedAudio = await firebase.uploadFile(
        basePath + "/audio",
        file,
        null,
        options
      );
      let downloadURL =
        await uploadedAudio.uploadTaskSnapshot.ref.getDownloadURL();

      if (tour.tour_media) {
        let tour_media = [
          ...tour.tour_media,
          {
            name: audioName,
            url: downloadURL,
            title: audioTitle,
            type: file.type,
          },
        ];
        tour = {
          ...tour,
          tour_media,
        };
      } else {
        let tour_media = [
          {
            name: audioName,
            url: downloadURL,
            title: audioTitle,
            type: file.type,
          },
        ];
        tour = {
          ...tour,
          tour_media,
        };
      }
      await firestore.update(`tours/${tour.id}`, tour);
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      toastr.error("Oops", "Audio upload faild. Please try agian");
    }
  };

export const uploadVideo =
  (file, basePath, tour, poster) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const videoName = cuid();
    const firebase = getFirebase();
    const firestore = getFirestore();
    const options = {
      name: videoName,
    };

    try {
      dispatch(asyncActionStart);
      let uploadedVideo = await firebase.uploadFile(
        basePath + "/videos",
        file,
        null,
        options
      );
      let downloadURL =
        await uploadedVideo.uploadTaskSnapshot.ref.getDownloadURL();
      let uploadedPoster = await firebase.uploadFile(
        basePath + "pictures",
        poster,
        null,
        options
      );
      let posterURL =
        await uploadedPoster.uploadTaskSnapshot.ref.getDownloadURL();

      if (tour.tour_media) {
        let tour_media = [
          ...tour.tour_media,
          {
            name: videoName,
            url: downloadURL,
            poster: posterURL,
            type: file.type,
          },
        ];
        tour = {
          ...tour,
          tour_media,
        };
      } else {
        let tour_media = [
          {
            name: videoName,
            url: downloadURL,
            poster: posterURL,
            type: tour.type,
          },
        ];
        tour = {
          ...tour,
          tour_media,
          profile_pic: {
            name: videoName,
            url: downloadURL,
            type: "video",
            poster_Img: posterURL,
          },
        };
      }
      await firestore.update(`tours/${tour.id}`, tour);
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log("error", error);
      toastr.error("Oops", "File upload failed, plese try agian.");
    }
  };

export const deleteVideo =
  (video, tour) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const updated_media = tour.tour_media.filter(
      (media) => media.name !== video.name
    );
    let updated_tour = {
      ...tour,
      tour_media: updated_media,
    };
    try {
      await firebase.deleteFile(`${tour.id}/mediaTour/videos/${video.name}`);
      await firebase.deleteFile(`${tour.id}/mediaTour/pictures/${video.name}`);
      await firestore.update(`tours/${tour.id}`, updated_tour);
    } catch (error) {
      console.log(error);
      throw new Error("Problem deleting the video");
    }
  };
export const deletePhoto =
  (photo, tour) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    let updated_media = tour.tour_media.filter(
      (media) => media.name !== photo.name
    );
    let updated_tour = {
      ...tour,
      tour_media: updated_media,
    };
    try {
      await firebase.deleteFile(`${tour.id}/mediaTour/${photo.name}`);
      await firestore.update(`tours/${tour.id}`, updated_tour);
      //await firestore.delete(`tours/${tour.id}/all_media/`)
    } catch (error) {
      console.log(error);
      throw new Error("Problem deleting the photo");
    }
  };

export const setMainPhoto =
  (photo, tour) =>
  async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    try {
      let updated_tour = {
        ...tour,
        profile_pic: {
          name: photo.name,
          url: photo.url,
          type: "photo",
        },
      };
      await firestore.update(`tours/${tour.id}`, updated_tour);
    } catch (error) {
      console.log(error);
      throw new Error("Problem setting main photo");
    }
  };

const addTourToUser = async (tourId, user, firebase, firestore) => {
  /* var userRef =  */
  console.log("USER", user);
  await firebase
    .firestore()
    .collection("users")
    .where("email", "==", user.email)
    .get()
    .then(async (querySnapshot) => {
      console.log("SNAPSHOT", querySnapshot);
      try {
        const the_user = querySnapshot.docs[0].data();
        const old_tours = the_user.tours ? the_user.tours : [];
        const update_user = {
          ...the_user,
          tours: [...old_tours, tourId],
        };
        await firestore.set(
          `users/${the_user.uid}`,
          { ...update_user },
          { merge: true }
        );
      } catch (error) {
        console.log(error);
        toastr.error("Oops", "The guide is not exist!");
      }
    });
};

export const createTour = (tour) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    const newTour = createNewTour(user, tour);
    try {
      let createdTour = await firestore.add("tours", newTour);
      const tourWithId = {
        ...tour,
        id: createdTour.id,
      };
      console.log("AFTER ADD func", tourWithId);
      await firestore.set(
        `tours/${createdTour.id}`,
        { ...tourWithId },
        { merge: true }
      );
      // await addTourToUser(createdTour.id, user, firebase, firestore);
      toastr.success("Success!", "Tour has been created");
      return createdTour;
    } catch (error) {
      console.log(error);
      toastr.error("Oops", "Somthing went wrong!");
    }
  };
};

export const updateTour = (tour) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    let update_tour = {
      ...tour,
      last_update: new Date(),
      tour_image: tour.tour_image ? tour.tour_image : "",
    };
    try {
      /* dispatch(asyncActionStart()); */
      console.log("UPDATED TOUR", update_tour);
      await firestore.set(
        `tours/${tour.id}`,
        /*   { ...update_tour }, */
        update_tour,
        { merge: true }
      );
      console.log("AFTER!!!!");
      toastr.success("Success!", "Tour has been updated");
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      toastr.error("Oops", "Somthing went wrong!");
      dispatch(asyncActionError());
    }
  };
};
export const updateHomePage = (homePage) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    try {
      const newHomePage = {
        guide_list: homePage[0].guide_list,
        locations: homePage[0].locations,
        tours: homePage[0].tours,
      };
      await firestore.set(`homepage/${homePage[0].id}`, newHomePage);
      toastr.success("Success", "Home Page has been updates");
    } catch (error) {
      console.log(error);
      toastr.error("Oops", "Something went wrong");
    }
  };
};
export const applyAproval = (tour) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    let update_tour = {
      ...tour,
      applayAproval: true,
    };
    console.log("applyAproval", update_tour);
    try {
      await firestore.set(
        `tours/${tour.id}`,
        { ...update_tour },
        { merge: true }
      );
      console.log("AFTER APPLAY");
    } catch (error) {
      console.log(error);
      toastr.error("Oops", "Somthing went wrong!");
    }
  };
};

export const cancelToggle =
  (cancelled, tourId) =>
  async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const message = cancelled
      ? "Are you sure you want to cancel the tour?"
      : "This will reactivate the tour, are you sure?";

    try {
      toastr.confirm(message, {
        onOk: async () =>
          await firestore.update(`tours/${tourId}`, {
            cancelled: cancelled,
          }),
      });
    } catch (error) {
      console.log(error);
    }
  };

export const loadTour = () => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      const tours = await fetchSampleData();
      dispatch({ type: FETCH_TOUR, payload: { tours } });
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError());
    }
  };
};

const updateVideoStop = async (stop, firebase) => {
  const imagePromise = new Promise(async (resolve, reject) => {
    if (stop.type.includes("big") && stop.loc_pics[0]) {
      try {
        await firebase
          .storage()
          .ref(`${stop.id}/stopMedia/${stop.loc_pics[0].name}_resize.jpg`)
          .getDownloadURL()
          .then((result) => {
            stop.loc_pics[0].url = result;
            stop.loc_pics[0].name = `${stop.loc_pics[0].name}_resize.jpg`;
            console.log("CHANGE LOC PIC!!!!");
          });
      } catch (error) {
        console.log("Can't get url FOR LOC PIC", error);
      }
    }
  });
  const results = Promise.all([
    imagePromise,
    stop.all_media.map(async (media) => {
      if (media.type.includes("video") && !media.name.includes("compress")) {
        try {
          await firebase
            .storage()
            .ref(`${stop.id}/stopMedia/${media.name}_compress.mp4`)
            .getDownloadURL()
            .then((result) => {
              media.url = result;
              media.name = `${media.name}_compress`;
            });
        } catch (err) {
          console.log("Can't get url", err, media);
        }
      }
      if (media.type.includes("image")) {
        //let nameArr = media.name.splite(".");
        console.log("FETCH", `${stop.id}/stopMedia/${media.name}_resize.jpg`);
        try {
          await firebase
            .storage()
            .ref(`${stop.id}/stopMedia/${media.name}_resize.jpg`)
            .getDownloadURL()
            .then((result) => {
              media.url = result;
              media.name = `${media.name}_resize.jpg`;
            });
          console.log("THIS IS COMPRESS IMAGE");
        } catch (error) {
          console.log("Picture is not commpress", error, stop.order);
          //toastr.error("Oops", "Picture is not commpress");
        }
      }
    }),
  ]);
  return results;
};

const format_hours = (secs = 50000) => {
  if (secs === 86400) secs = 86360;
  var minutes = Math.floor(secs / 60);
  var hours = Math.floor(minutes / 60);
  minutes = minutes % 60;
  /*
      var amPm = hours > 11 ? "PM" : "AM";
      if (secs < 3600) {
        hours = 12;
      } else if (hours > 12) {
        hours -= 12;
      }
      return `${hours}:${("0" + minutes).slice(-2)} ${amPm}`;
      */
  return `${hours}:${("0" + minutes).slice(-2)}`;
};

export const approveTour =
  (tour, user_id) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    let stop_index = 1;
    /*update trailer to compress video */

    await tour.all_media.map(async (media, index) => {
      if (
        media.type.includes("video") &&
        !tour.all_media[index].name.includes("_compress")
      ) {
        try {
          await firebase
            .storage()
            .ref(
              `${tour.id}/toursMedia/${tour.all_media[index].name}_compress.mp4`
            )
            .getDownloadURL()
            .then((result) => {
              tour.all_media[index].url = result;
              tour.all_media[
                index
              ].name = `${tour.all_media[index].name}_compress`;
            });
          if (tour.type.includes("business")) {
            await firebase
              .storage()
              .ref(
                `${tour.id}/businessMedia/${tour.all_media[index].name}_compress.mp4`
              )
              .getDownloadURL()
              .then((result) => {
                tour.all_media[index].url = result;
                tour.all_media[
                  index
                ].name = `${tour.all_media[index].name}_compress`;
              });
          }
        } catch (error) {
          console.log(error);
          toastr.error("Oops", "Somthing went wrong! trailer problem");
          dispatch(asyncActionError());
        }
      }
      if (media.type.includes("image")) {
        try {
          let nameArr = [];
          try {
            nameArr = tour.all_media[index].name.splite(".");
          } catch (error) {
            nameArr[0] = tour.all_media[index].name;
            nameArr[1] = "jpg";
          }

          console.log(
            "IMAGE nameArr",
            nameArr,
            "TRY TO FETCH",
            `${tour.id}/toursMedia/${nameArr[0]}_resize.${nameArr[1]}`
          );
          await firebase
            .storage()
            .ref(`${tour.id}/toursMedia/${nameArr[0]}_resize.${nameArr[1]}`)
            .getDownloadURL()
            .then((result) => {
              tour.all_media[index].url = result;
              tour.all_media[index].name = `${nameArr[0]}_resize.${nameArr[1]}`;
            });
          console.log("THIS IS COMPRESS IMAGE");
        } catch (error) {
          console.log(error);
          toastr.error("Oops", "Picture is not commpress");
        }
      }
    });

    /* Add this tour id to the obj of the guide  */
    await addTourToUser(tour.id, tour.tour_guide, firebase, firestore);
    /* try {
    const userRef = await firebase
      .firestore()
      .collection("users")
      .doc(tour.tour_guide.id);
    userRef.get().then(async function (doc) {
      if (doc.exists) {
        let userDoc = doc.data();
        let allTours = userDoc.tours ? userDoc.tours : [];
        if (!allTours.includes(tour.id)) {
          allTours.push(tour.id);
          allTours = Array.from(new Set(allTours));
          userDoc = {
            ...userDoc,
            tours: [...allTours],
          };
          await firestore.set(
            `users/${tour.tour_guide.id}`,
            { ...userDoc },
            { merge: true }
          );
        }
      }
    });
  } catch (error) {
    console.log(error);
    toastr.error("Oops", "Somthing went wrong! user problem");
    dispatch(asyncActionError());
  } */
    let kosher = false;
    let accessible = false;
    let update_stop = {};
    tour.stops.map((stop, index) => {
      if (!stop.type.includes("smallStop")) {
        if (stop.type.includes("business")) {
          kosher = stop.kosher ? true : false;
          accessible = stop.accessible ? true : false;

          console.log("CALULATE HOURS", stop.hours_range);

          let min_hours_range =
            typeof stop.hours_range.min === "number"
              ? format_hours(stop.hours_range.min)
              : stop.hours_range.min;
          let max_hours_range =
            typeof stop.hours_range.max === "number"
              ? format_hours(stop.hours_range.max)
              : stop.hours_range.max;
          console.log("AFTER CALCULATE", min_hours_range, max_hours_range);
          update_stop = {
            ...stop,
            kosher: kosher,
            accessible: accessible,
            hours_range: { max: max_hours_range, min: min_hours_range },
          };
        } else {
          update_stop = {
            ...stop,
            stop_index: stop_index,
          };
        }

        tour.stops[index] = update_stop;
        stop_index++;
      }
    });

    let dateHash = new Date().getMilliseconds() / 100 - 1;
    let update_tour = {
      ...tour,
      approval_time: new Date(),
      approval_index: dateHash,
      kosher: kosher,
      accessible: accessible,
      exp_video:
        "https://firebasestorage.googleapis.com/v0/b/tours-app-1579553856346.appspot.com/o/expo_video.mp4?alt=media&token=1236c5eb-1097-4d48-946e-381b9e10085e",
    };

    const results = Promise.all(
      // await updateTrailerTour(tour, firebase),
      update_tour.stops.map(async function (stop) {
        await updateVideoStop(stop, firebase);
      })
    );

    async function output(results) {
      try {
        dispatch(asyncActionStart());
        console.log("WAIT FOR UPDATE COMPRESS", update_tour);

        await firestore.set(
          `approval_tours/${tour.id}`,
          { ...update_tour },
          { merge: true }
        );

        //await firestore.set(`approval_tours/${tour.id}`, update_tour);
        toastr.success("Success!", "Tour has been updated");
        dispatch(asyncActionFinish());
      } catch (error) {
        console.log(error);
        toastr.error("Oops", "Somthing went wrong!");
        dispatch(asyncActionError());
      }
    }

    output(results);

    console.log("AFTER PROMISE###");

    /*  try {
    
    dispatch(asyncActionStart());
    //await firestore.add("approval_tours", update_tour);
    await firestore.set(
      `approval_tours/${tour.id}`,
      { ...update_tour }
      //{ merge: true }
    );

    //await firestore.set(`approval_tours/${tour.id}`, update_tour);
    toastr.success("Success!", "Tour has been updated");
    dispatch(asyncActionFinish());
   
  } catch (error) {
    console.log(error);
    toastr.error("Oops", "Somthing went wrong!");
    dispatch(asyncActionError());
  } */
  };

export const unApproveTour =
  (tourID) =>
  async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    try {
      firestore.delete(`approval_tours/${tourID}`);
      toastr.success("Success", "The tour is removed from approval tour (APP)");
    } catch (error) {
      console.log(error);
      toastr.error("Oops", "someting went wrong!");
    }
  };

export const deleteTour =
  (tour) =>
  async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    try {
      firestore.delete(`approval_tours/${tour.id}`);
      firestore.set(`delete_tour/${tour.id}`, tour);
      firestore.delete(`tours/${tour.id}`);
      toastr.success("Success", "The tour is removed from approval tour (APP)");
    } catch (error) {
      console.log(error);
      toastr.error("Oops", "someting went wrong!");
    }
  };

export const duplicateTours =
  (tours) =>
  async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    try {
      tours.map((tour) => {
        let update_tour = { ...tour, tour_without_map: false };
        firestore.set(`duplicate_tours/${tour.id}`, update_tour);
      });
      toastr.success("Success", "The tour is removed from approval tour (APP)");
    } catch (error) {
      console.log(error);
      toastr.error("Oops", "someting went wrong!");
    }
  };

export const toursWithoutStops =
  (tours) =>
  async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    try {
      tours.map((tour) => {
        let update_tour = { ...tour, stops: [], exp_video: "" };
        //console.log(`tours_without_stops/${tour.id}`, update_tour);
        firestore.set(`tours_without_stops/${tour.id}`, update_tour);
      });
      toastr.success("Success", "The tour is removed from approval tour (APP)");
    } catch (error) {
      console.log(error);
      toastr.error("Oops", "someting went wrong!");
    }
  };

export const checkTour = (tour) => {
  let errorArray = [];
  tour.hasOwnProperty("accessible");
  tour.hasOwnProperty("all_media");
  let mediaOrder = [];
  tour.all_media.map((media) => {
    media.hasOwnProperty("name");
    if (typeof (media.name !== "String"))
      errorArray.push("media name type error");
    media.hasOwnProperty("order");
    if (typeof (media.order !== "Number"))
      errorArray.push("media order type error");
    else mediaOrder.push(media.order);
    media.hasOwnProperty("poster_name");
    if (typeof (media.poster_name !== "String"))
      errorArray.push("media poster_name type error");
    media.hasOwnProperty("poster_url");
    if (typeof (media.poster_url !== "String"))
      errorArray.push("media poster_url type error");
    media.hasOwnProperty("type");
    if (typeof (media.type !== "String"))
      errorArray.push("media type type error");
    media.hasOwnProperty("url");
    if (typeof media.url !== "String") errorArray.push("media url type error");
  });
  const uniqueOrder = mediaOrder.filter(
    (val, id, array) => array.indexOf(val) === id
  );
  if (uniqueOrder.length > 0) errorArray.push("tour all_media order error");
  tour.hasOwnProperty("description");
  if (typeof (tour.description !== "String"))
    errorArray.push("tour description type error");
  tour.hasOwnProperty("audience");
  if (!tour.audience.isArray()) errorArray.push("tour audience type error");
  tour.hasOwnProperty("distance");
  if (typeof (tour.distance !== "Number"))
    errorArray.push("tour distance type error");
  if (tour.distance > 3000 || tour.distance < 300)
    errorArray.push("tour distance can be a problem");
  tour.hasOwnProperty("duration");
  if (typeof (tour.duration !== "Number"))
    errorArray.push("tour duration type error");
  if (tour.distance > 10 || tour.distance < 1)
    errorArray.push("tour duration can be a problem");

  console.log("**************TOUR CHECK*************************", errorArray);
};
