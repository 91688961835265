import React from "react";
import { Form } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import TextInput from "./form/textInput";
import style from "../../style/form.css";
import "../../style/form.css";
import logo from "../../images/loginLogo.svg";
import { conferenceRegister } from "./conferenceActions";
import { connect } from "react-redux";
import { useState } from "react";

const actions = {
  conferenceRegister,
};

const ConferenceRegistration = ({
  invalid,
  submitting,
  conferenceRegister,
  handleSubmit,
}) => {
  const [submitDone, setSubmitDone] = useState(false);
  const submitConfrence = (e) => {
    conferenceRegister(e);
    setSubmitDone(true);
  };
  return (
    <div
      style={{
        backgroundColor: "#39415e",
        height: "100%",
        direction: "rtl",
        textAlign: "center",
        margin: "auto",
      }}
    >
      <div className="conference-form">
        <div className="loginLogo">
          {" "}
          <img src={logo} alt="smallLogo" />
        </div>
        <h1 className="mainTitleConference">
          Dguide workshop registration form
        </h1>
        <h4 style={{ color: "white" }}>
          {!submitDone
            ? "Send us your info and we will respond to you as soon as we can"
            : "Thank you, we got your info. Our content manager will contact you soon"}
        </h4>
        <div
          style={{
            backgroundColor: "#39415e",
            height: "100%",
            display: "inline-block",
            textAlign: "center",
          }}
        >
          {!submitDone && (
            <Form size="large" onSubmit={handleSubmit(submitConfrence)}>
              <div className="formLable">Full Name:</div>

              <Field
                name="full_name"
                component={TextInput}
                type="text"
                placeholder="Full Name"
                className={style.Inputlogin}
                lable="Full Name"
              />
              <div className="formLable">Email:</div>
              <Field
                name="email"
                component={TextInput}
                type="text"
                placeholder="Email"
                className={style.Inputlogin}
              />
              <div className="formLable">Phone number:</div>
              <Field
                name="phone"
                component={TextInput}
                type="text"
                placeholder="Phone number"
                className={style.Inputlogin}
              />
              <div className="formLable">Language:</div>
              <Field
                name="lang"
                component={TextInput}
                type="text"
                placeholder="Guide Language"
                className={style.Inputlogin}
              />
              <div className="formLable">Your Top Tour:</div>
              <Field
                name="flagTour"
                component={TextInput}
                type="text"
                placeholder="Your Top Tour"
                className={style.Inputlogin}
              />

              <button
                type="submit"
                className="submitButton"
                disabled={invalid || submitting}
              >
                {" "}
                Send
              </button>
            </Form>
          )}
        </div>
        <h3 style={{ color: "white" }}>
          Want to call us now? +972 52 670 0703
        </h3>
      </div>
    </div>
  );
};

export default connect(
  null,
  actions
)(reduxForm({ form: "conferenceRegistration" })(ConferenceRegistration));
