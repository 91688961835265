import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Modal, Grid, Header } from "semantic-ui-react";
import DropzoneInput from "./DropzoneInput";

const EditPhoto = ({ open, onClose, video, deletPoster, updatePoster }) => {
  const [title, setTitle] = useState(video.title ? video.title : "");
  const [poster, setPoster] = useState([]);
  useEffect(() => {
    setTitle(video.title);
  }, [video]);

  const handleTitle = (event) => {
    setTitle(event.target.value);
  };

  return (
    <Modal size="large" open={open} onClose={onClose}>
      <Modal.Header>Edit video</Modal.Header>
      <Grid>
        <Grid.Row />
        <Grid.Column width={6}>
          <Header color="teal" sub content="Video preview" />
          <video width="320" height="240" controls>
            <source src={video.url} type={video.type} />
          </video>
        </Grid.Column>
        <Grid.Column width={5}>
          {video.poster_url ? (
            <Fragment>
              <img
                style={{ width: "200px", hight: "200px", margin: "20px" }}
                src={video.poster_url}
              />
              <button
                className="saveButton"
                onClick={() => (deletPoster(video), onClose())}
              >
                Change poster pic
              </button>
            </Fragment>
          ) : poster.length > 0 ? (
            <img
              style={{ width: "200px", hight: "200px", margin: "20px" }}
              src={poster[0].preview}
            />
          ) : (
            <DropzoneInput setFiles={setPoster} acceptedFile="image/*" />
          )}
        </Grid.Column>
        <Grid.Column width={5}>
          <Header color="teal" sub content="Photo title" />
          <input
            placeholder="Video title"
            onChange={(e) => handleTitle(e)}
            type="text"
            value={title}
            style={{
              width: "200px",
              height: "30px",
              marginBottom: "8px",
            }}
          />
          <button
            className="saveButton"
            onClick={() => (updatePoster(poster, title), setPoster([]))}
          >
            Save
          </button>
        </Grid.Column>
      </Grid>
    </Modal>
  );
};

export default EditPhoto;
