import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  createTour,
  updateTour,
  deleteTour,
  applyAproval,
} from "../tourAction";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import BusinessList from "../../business/businessList/BusinessList";
import TourList from "../tourList/TourList";
import "../../../style/lists.css";

const query = (props) => {
  return [
    {
      collection: "tours",
      //where: ["tour_guide.id", "==", props.auth.uid],
      where: ["tour_guide.email", "==", props.auth.email],
    },
    {
      collection: "business",
      //where: ["tourOwner", "==", props.auth.uid],
      where: ["owner.email", "==", props.auth.email],
    },
  ];
};

const mapState = (state, props) => {
  console.log("PROPS", props);
  console.log("state", state, "DATA", state.firestore.data.tours);
  const auth = state.auth.authenticated
    ? state.auth.authenticated
    : state.firebase.auth;

  let user_tours = [];
  if (state.firestore.data.tours && auth) {
    user_tours = Object.values(state.firestore.data.tours);
    /* user_tours = state.firestore.data.tours.filter((tour) => {
      if (tour.tour_guide.email === auth.email) {
        return 1;
      } else {
        return -1;
      } 
    });*/
  }
  console.log("user_tours", user_tours);
  return {
    tours: user_tours, // state.firestore.ordered.tours,
    business: state.firestore.ordered.business,
    loading: state.async.loading,
    profile: state.firebase.profile,
    auth: state.firebase.auth,
  };
};

const actions = {
  createTour,
  updateTour,
  deleteTour,
  applyAproval,
};
const TourDashboard = (props) => {
  console.log("PROPS iNSIDE", props);
  const [allTours, setAllTours] = useState([]);
  useEffect(() => {
    const user_tours = props.tours.filter((tour) => {
      return tour.tour_guide.email === props.auth.email;
    });
    setAllTours(user_tours);
  }, [props.tours]);

  return (
    <div className="allAssets">
      <div className="tourAssets">
        {props.tours && (
          <TourList tours={allTours} applyAproval={props.applyAproval} />
        )}
      </div>
      <div className="tourAssets">
        {props.business && <BusinessList business={props.business} />}
      </div>
    </div>
    /*  <Grid>
      <Grid.Column width={8}>
        {props.tours && <TourList tours={props.tours} />}
      </Grid.Column>
      <Grid.Column width={8}>
        {props.business && <BusinessList business={props.business} />}
      </Grid.Column>
    </Grid> */
  );
};

export default compose(
  connect(mapState, actions),
  firestoreConnect((props) => query(props))
)(TourDashboard);
