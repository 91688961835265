import React, { Component, Fragment } from "react";
import TourDashboard from "./features/tour/tourDashboard/TourDashboard";
import NavBar from "./features/nav/navBar/NavBar";
import { Route, Router, withRouter, Switch, Redirect } from "react-router-dom";
import HomePage from "./features/home/HomePage";
import TourDetailedPage from "./features/tour/tourDetailed/tourDetailedPage";
import ModalManager from "./features/modals/modalManager";
/* import SettingDashboard from "./features/user/setting/SettingDashboard";
import StopCreation from "./features/stop/StopCreation"; */
import StopList from "./features/stop/StopList";
/* import TourControl from "./features/tour/tourCreation/TourControl"; */
import TourControlNew from "./features/tour/createTour/TourControl";
import { withFirebase } from "react-redux-firebase";
import { connect } from "react-redux";
import "./style/homePage.css";
import UserSettings from "./features/user/setting/UserSettings";
import MainPage from "./features/home/MainPage";
import BusinessCreation from "./features/business/businessCreation/BusinessCreation";
import Index from "./features/home/index.jsx";
import HelperComponent from "./HelperComponent";
import ManageTours from "./features/tour/tourDashboard/ManageTours";
import SalesPage from "./features/user/SalesPage";
import CandT from "./features/auth/CandT";
import TestComponent from "./features/testerea/testComponent";
import createHistory from "history/createBrowserHistory";
//require("history").createBrowserHistory;
import ReactGA from "react-ga";
import OuterPay from "./features/auth/OuterPay";
import paymeReturn from "./features/cart/paymeReturn";
import PaymeSuccess from "./features/cart/paymeSuccess";
import DirectCart from "./features/cart/DirectCart";
import ConferenceRegistration from "./app/common/ConferenceRegistration";
import SalePage from "./features/auth/SalePage";
import GuideRegistration from "./app/common/GuideRegistration";

const history = createHistory();
history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const mapState = (state, props) => {
  return {
    path: props.location.pathname,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

class App extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    const TandC = this.props.path.toLowerCase() === "/tandc" ? true : false;
    const directCart = this.props.path.toLowerCase().includes("directcart")
      ? true
      : false;
    const outerPay = this.props.path.toLowerCase().includes("outerpay")
      ? true
      : false;
    const confRegi = this.props.path.toLowerCase().includes("conference")
      ? true
      : false;
    const guideRegi = this.props.path.toLowerCase().includes("guidereg")
      ? true
      : false;
    const salePage = this.props.path.toLowerCase().includes("salepage")
      ? true
      : false;
    /*NEED TO CHECK*/
    let authenticated =
      this.props.auth.isLoaded &&
      !this.props.auth.isEmpty &&
      this.props.profile;

    return (
      <Fragment>
        <Router history={history}>
          <ModalManager />
          {TandC ? ((<Redirect to="/TandC" />), (authenticated = true)) : null}
          <Route exact path={"/TandC"} component={CandT} />
          {outerPay
            ? ((<Redirect to="/outerPay" />), (authenticated = true))
            : null}
          {directCart
            ? ((<Redirect to="/directCart" />), (authenticated = true))
            : null}
          {confRegi
            ? ((<Redirect to="/conferenceRegistration" />),
              (authenticated = true))
            : null}
          {guideRegi
            ? ((<Redirect to="/conferenceRegistration" />),
              (authenticated = true))
            : null}
          {salePage
            ? ((<Redirect to="/salepage" />), (authenticated = true))
            : null}

          <Route
            exact
            path={["/outerPay", "/outerPay/:guideId"]}
            component={OuterPay}
          />

          <Route
            exact
            path={["/conferenceRegistration"]}
            component={ConferenceRegistration}
          />

          <Route
            exact
            path={["/guideRegistration"]}
            component={GuideRegistration}
          />

          <Route
            exact
            path="/outerPayReturn/:approvalId/:transactionId/:sourceId"
            component={paymeReturn}
          />
          <Route
            exact
            path={[
              "/directCart/:wantedTour",
              "/directCart/:wantedTour/:sourceId",
            ]}
            component={DirectCart}
          />
          <Route
            exact
            path="/outerpaymentSuccess/:approvalId/:transactionId/:sourceId"
            component={PaymeSuccess}
          />

          <Route
            exact
            path={["/salepage/:guideId", "/salepage/:guideId/:sourceId"]}
            component={SalePage}
          />

          {/*{!authenticated ? <Redirect to="/" /> : null}
           <Route exact path="/" component={HomePage} /> */}
          {!authenticated ? <Redirect to="/login" /> : null}
          <Route exact path="/login" component={HomePage} />
          <Route
            path="/"
            exact
            component={() => {
              window.location.href = "https://dguidetours.com/";
              return null;
            }}
          />

          <Route
            path="/(.+)"
            render={() => (
              <div className="mainApp">
                {!(
                  TandC ||
                  outerPay ||
                  directCart ||
                  confRegi ||
                  salePage ||
                  guideRegi ||
                  !authenticated
                ) && <NavBar />}
                {/*auth.isEmpty === false && <NavBar />*/}
                <div className="switchContext">
                  <Switch key={this.props.location.key}>
                    <Route path="/settings" component={UserSettings} />
                    {/* <Route path="/settingss" component={SettingDashboard} /> */}

                    <Route exact path="/main" component={MainPage} />
                    <Route exact path="/tours" component={TourDashboard} />
                    <Route
                      exact
                      path={[
                        "/businessCreation",
                        "/businessCreation/:businessId",
                      ]}
                      component={BusinessCreation}
                    />
                    <Route exact path="/index" component={Index} />
                    <Route
                      exact
                      path={["/sales", "/sales/:guideId"]}
                      component={SalesPage}
                    />
                    <Route
                      path={["/tourControl/:tourId", "/tourControl"]}
                      component={TourControlNew}
                    />

                    <Route path="/tours/:id" component={TourDetailedPage} />
                    {/*<Route path={['/tourCreation/:tourId', '/tourCreation']} component={TourCreation} />*/}

                    {/*  <Route
                      path={[
                        "/createStop/:tourId/:stopId",
                        "/createStop/:tourId",
                        "/createStop",
                      ]}
                      component={StopCreation}
                    /> */}
                    {/*  <Route
                      path={["/tourControlOld/:tourId", "/tourControlOld"]}
                      component={TourControl}
                    /> */}

                    <Route path={"/stops"} component={StopList} />
                    <Route path={"/test"} component={TestComponent} />

                    <HelperComponent
                      path="/boss"
                      component={ManageTours}
                      requiredRole="User"
                    />
                  </Switch>
                </div>
              </div>
            )}
          />
        </Router>
      </Fragment>
    );
  }
}

export default withRouter(withFirebase(connect(mapState)(App)));
