import React, { useEffect, useState } from "react";
import { Table } from "semantic-ui-react";

const RegisterGuideTable = (props) => {
  const [registerGuide, setRegisterGuide] = useState([]);
  useEffect(() => {
    if (props.users) {
      props.users.sort((a, b) => {
        if (a.date > b.date) return -1;
        else return 1;
      });
      setRegisterGuide(props.users);
    }
  }, [props.users]);

  return (
    <Table celled compact definition>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Registration Date</Table.HeaderCell>
          <Table.HeaderCell>E-mail address</Table.HeaderCell>
          <Table.HeaderCell>Favorite tour</Table.HeaderCell>
          <Table.HeaderCell>Phone</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {props.users &&
          props.users.map((user) => (
            <Table.Row id={user.full_name}>
              <Table.Cell>
                {user.full_name ? user.full_name : "no Full name"}
              </Table.Cell>
              <Table.Cell>
                {
                  user.time ? user.time.toDate().toDateString() : ""
                  //? sale.time.toDate().toDateString()
                  //sale.time
                }
              </Table.Cell>
              <Table.Cell>{user.email ? user.email : "no Email"}</Table.Cell>
              <Table.Cell>
                {user.flagTour ? user.flagTour : "no favorit tour"}
              </Table.Cell>
              <Table.Cell>{user.phone ? user.phone : "no phone"}</Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
};

export default RegisterGuideTable;
