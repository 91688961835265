import React from "react";
import "./index.css";

const FAQ = ({ faq, index, toggleFAQ }) => {
  return (
    <div
      style={{ fontSize: "20px" }}
      className={"faq " + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="faq-question">{faq.question}</div>
      <hr />
      <div style={{ fontSize: "16px" }} className="faq-answer">
        {faq.answer}
      </div>
    </div>
  );
};

export default FAQ;
