import React, { Fragment, useEffect, useState } from "react";
import { withFirestore } from "react-redux-firebase";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
//import PhotoComponent from "./PhotoComponent";
import { toastr } from "react-redux-toastr";
import { generalDeleteFile, generalUploadFile } from "../../media/mediaActions";
//import VideoComponent from "../../tour/tourCreation/media/VideoComponent";
//import AudioComponent from "../../tour/tourCreation/media/AudioComponent";
import MediaList from "../../media/MediaList";
import PhotoComponent from "../../stop/media/PhotoComponent";
import VideoComponent from "../../stop/media/VideoComponent";
import AudioComponent from "../../stop/media/AudioComponent";

const actions = {
  generalUploadFile,
  generalDeleteFile,
};

const mapState = (state) => {
  return {
    firestore: state.firestore,
    initialValues: state.form.businessForm.values,
    loading: state.async.loading,
  };
};

const BusinessMedia = (props) => {
  const {
    initialValues,
    saveChanges,
    loading,
    generalDeleteFile,
    generalUploadFile,
  } = props;

  const [photoOpen, setPhotoModal] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState();
  const [videoOpen, setVideoModal] = useState(false);
  const [audioOpen, setAudioModal] = useState(false);
  const [allMedia, setAllMedia] = useState(
    initialValues.all_media ? initialValues.all_media : []
  );
  let all_media = initialValues.all_media ? initialValues.all_media : [];
  useEffect(() => {
    setAllMedia(initialValues.all_media ? initialValues.all_media : []);
  }, [initialValues]);

  const handleDeleteFile = async (file) => {
    try {
      await deleteFile(file, initialValues, "business");
    } catch (error) {
      toastr.error("Oops", error.message);
    }
  };

  const uploadFile = async (file, fileTitle = "file", poster = {}) => {
    try {
      let new_media = await generalUploadFile(
        file,
        initialValues.id,
        "business",
        fileTitle,
        poster
      );
      new_media = {
        ...new_media,
        order: initialValues.all_media ? initialValues.all_media.length : 0,
      };
      let new_all_media = [...initialValues.all_media, new_media];
      let update_stop = {
        ...initialValues,
        all_media: new_all_media,
      };

      await saveChanges(update_stop);
      props.change("all_media", new_all_media);
    } catch (error) {
      toastr.error("Oops", error.message);
    }
  };

  const deleteFile = async (file) => {
    if (
      file.type.includes("image") ||
      file.type.includes("audio") ||
      file.type.includes("video")
    ) {
      await generalDeleteFile(file, initialValues.id, "businessMedia");
    }
    let new_all_media = all_media.filter((media) => media.name !== file.name);
    let update_business = {
      ...initialValues,
      all_media: new_all_media,
    };

    saveChanges(update_business);
    props.change("all_media", new_all_media);
  };

  const handleSetMainFile = async (photo, stop) => {
    try {
      props.change("mainMedia", photo);
    } catch (error) {
      toastr.error("Oops", error.message);
    }
  };

  const setMediaList = (updatedList) => {
    console.log("new list", updatedList);
    updatedList.map((item, index) => (item.order = index));
    props.change("all_media", updatedList);
    console.log("AFTER CHANGE", initialValues.all_media);
    setAllMedia(updatedList);
  };

  return (
    <Fragment>
      <button className="addButton" onClick={() => setPhotoModal(true)}>
        + Photo
      </button>
      <button className="addButton" onClick={() => setVideoModal(true)}>
        + Video
      </button>
      <button className="addButton" onClick={() => setAudioModal(true)}>
        + Audio
      </button>
      {!initialValues.id && <h1>Please fill business details before</h1>}
      <PhotoComponent
        loading={loading}
        objectId={initialValues.id}
        all_media={initialValues.all_media}
        handleSetMainFile={handleSetMainFile}
        handleDeletePhoto={handleDeleteFile}
        open={photoOpen}
        onClose={() => setPhotoModal(false)}
        generalUploadFile={uploadFile}
        selectedPhoto={selectedPhoto}
      />
      {/*initialValues.id && (
        <PhotoComponent
          loading={loading}
          objectId={initialValues.id}
          all_media={all_media}
          handleSetMainFile={handleSetMainFile}
          handleDeletePhoto={handleDeleteFile}
          open={photoOpen}
          onClose={() => setPhotoModal(false)}
        />
      )*/}
      <VideoComponent
        open={videoOpen}
        onClose={() => setVideoModal(false)}
        objectId={initialValues.id}
        all_media={initialValues.all_media}
        handleDeleteFile={deleteFile}
        generalUploadFile={uploadFile}
      />

      <AudioComponent
        open={audioOpen}
        onClose={() => setAudioModal(false)}
        objectId={initialValues.id}
        all_media={initialValues.all_media}
        handleDeleteFile={deleteFile}
        generalUploadFile={uploadFile}
      />
      {/* <TextComponent
        open={textOpen}
        onClose={() => setTextModal(false)}
        objectId={initialValues.id}
        collectionName={"stops"}
        handleDeleteFile={deleteFile}
        uploadText={uploadWithoutFile}
        context={textContent}
      />

      <QuestionComponent
        open={questionOpen}
        onClose={() => setQuestionModal(false)}
        /*objectId={initialValues.id}
        tourId={initialValues.tour_owner}
        all_media={all_media} 
        collectionName={"stops"}*
        uploadQuestion={uploadWithoutFile}
        content={textContent}
      />
      <EditPhoto
        open={editPhotoModal}
        onClose={() => setEditdPhotoModal(false)}
        image={editPhoto}
        updatePhoto={updateTitle}
      />
      <EditVideo
        open={editVideoModal}
        onClose={() => setEditVideoModal(false)}
        video={editVideo}
        deletPoster={deletPoster}
        updatePoster={updatePoster}
      /> */}

      {/* {initialValues.id && (
        <VideoComponent
          open={videoOpen}
          onClose={() => setVideoModal(false)}
          objectId={initialValues.id}
          all_media={all_media}
          collectionName={"business"}
          handleDeleteFile={handleDeleteFile}
        />
      )}
      {initialValues.id && (
        <AudioComponent
          open={audioOpen}
          onClose={() => setAudioModal(false)}
          objectId={initialValues.id}
          all_media={all_media}
          collectionName={"business"}
          handleDeleteFile={handleDeleteFile}
        />
      )} */}
      {initialValues.all_media && (
        <MediaList
          all_media={allMedia}
          setMediaList={setMediaList}
          deleteFuncSwitch={deleteFile}
        />
      )}
      <button
        className="saveBusinessButton"
        onClick={() => saveChanges(initialValues)}
      >
        save & continue
      </button>
    </Fragment>
  );
};

export default withFirestore(
  connect(
    mapState,
    actions
  )(
    reduxForm({
      form: "businessForm",
      //validate,
      enableReinitialize: true,
      destroyOnUnmount: false,
      forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    })(BusinessMedia)
  )
);
