import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { addToCart, removeFromCart } from "../cart/cartActions";
import Cart from "./Cart";
import smallLogo from "../../images/smallLogo.png";

const query = (props) => {
  let tourId;

  if (props.match.params.wantedTour) {
    tourId = props.match.params.wantedTour;
  } else if (props.initialValues && props.initialValues.tourId) {
    tourId = props.initialValues.tourId;
  }

  if (tourId) {
    return [
      {
        collection: "approval_tours",
        doc: tourId,
      },
    ];
  } else {
    return [{ collection: "approval_tours" }];
  }
};

const mapState = (state, ownProps) => {
  let wantedTour = null;
  let tourCart = [];
  let source = "ItravelJRS";
  if (ownProps.match.params.sourceId) {
    source = ownProps.match.params.sourceId;
  }
  if (ownProps.match.params.wantedTour) {
    wantedTour = ownProps.match.params.wantedTour;
  }

  if (wantedTour && state.firestore.ordered.approval_tours) {
    let tours_cart = [];
    tours_cart.push(wantedTour);
    tourCart = state.firestore.ordered.approval_tours.filter(
      (tour) => tours_cart.indexOf(tour.id) > -1
    );
    /* if (wantedTour === "lfngCB2AQGIoIdVkiIdu") {
      tourCart[0].price = 50;
    } */
  }
  return {
    tourCart: tourCart,
    cart: state.cart,
    sourceID: source,
  };
};

const actions = {
  addToCart,
  removeFromCart,
};

const DirectCart = ({ tourCart, addToCart, cart, removeFromCart }) => {
  /* const [cartEmpty, setCartEmapty] = useState(false); */
  const [tourAdded, setTourAdded] = useState(false);

  useEffect(() => {
    if (!tourAdded && tourCart.length > 0) {
      cart.cartItems.map((tour) => removeFromCart(tour));
      tourCart.map((tour) => addToCart(tour));
      setTourAdded(true);
    }
  }, [tourCart, cart]);

  return (
    <div className="allDirectCart">
      <div className="directTop">
        <img
          src={smallLogo}
          alt="smallLogo"
          style={{ width: "72px", hight: "52px", margin: "auto" }}
        />
        <h2 className="directPayHeader">ברוכים הבאים!</h2>
      </div>
      <div className="directPayIntro">
        עוד קצת ומתחילים את הסיור העצמאי {tourCart.title}. מייד לאחר הרכישה
        תקבלו אימייל עם הסבר מפורט ולינק להורדת האפליקציה. בנוסף לכך, הצוות שלנו
        לראשותכם לכל שאלה בצאט התמיכה באפליקציה. טיול מהנה!
      </div>
      <div className="directCart">
        <Cart
          /* setCartEmapty={setCartEmapty} */
          paymeURL={null}
          setCheckoutVal={true}
          type={true}
          sourceId={"ItravelJRS"}
        />
      </div>
    </div>
  );
};

export default compose(
  connect(mapState, actions),
  firestoreConnect((auth) => query(auth))
)(DirectCart);
