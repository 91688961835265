import React, { useEffect, useState } from "react";
import { withFirestore } from "react-redux-firebase";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Form } from "semantic-ui-react";
import placeInput from "../../../app/common/form/placeInput";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import TextInput from "../../../app/common/form/textInput";
import textAreaInput from "../../../app/common/form/textAreaInput";
import PhotoComponent from "../../stop/media/PhotoComponent";
import { generalUploadFile, generalDeleteFile } from "../../media/mediaActions";

const actions = {
  generalUploadFile,
  generalDeleteFile,
};

const mapState = (state) => {
  let formValues = {};

  if (state.form.businessForm) {
    formValues = state.form.businessForm.values;
  }

  return {
    initialValues: formValues,
    loading: state.async.loading,
  };
};
const PeakLocation = (props) => {
  const {
    invalid,
    submitting,
    loading,
    initialValues,
    onFormSubmit,
    generalUploadFile,
    generalDeleteFile,
  } = props;
  const [photoOpen, setPhotoModal] = useState(false);
  const [loc_pics, setLocPics] = useState(
    initialValues.loc_pics ? initialValues.loc_pics : []
  );
  useEffect(() => {
    setLocPics(initialValues.loc_pics ? initialValues.loc_pics : []);
    console.log("locPics", loc_pics);
  }, [initialValues]);

  //let loc_pics = initialValues.loc_pics ? initialValues.loc_pics : [];

  const handleAddress = (address) => {
    geocodeByAddress(address).then((result) =>
      getLatLng(result[0]).then((latlng) => {
        let location = { longitude: latlng.lng, latitude: latlng.lat };
        props.change("stop_location", location);
      })
    );
  };

  const deleteFile = async (file) => {
    console.log("delete file", file);
    if (
      file.type.includes("image") ||
      file.type.includes("audio") ||
      file.type.includes("video")
    ) {
      await generalDeleteFile(file, initialValues.id, "businessMedia");
    }
    let new_all_media = initialValues.loc_pics.filter(
      (media) => media.name !== file.name
    );
    let update_business = {
      ...initialValues,
      loc_pics: new_all_media,
    };

    await onFormSubmit(update_business);
    props.change("loc_pics", new_all_media);
  };

  const uploadFile = async (file) => {
    if (!initialValues.id) {
      onFormSubmit(initialValues);
    }

    let new_media = await generalUploadFile(file, initialValues.id, "business");
    new_media = {
      ...new_media,
      order: initialValues.loc_pics ? initialValues.loc_pics.length : 0,
    };
    let new_all_media = initialValues.loc_pics
      ? [...initialValues.loc_pics, new_media]
      : [new_media];
    let update_stop = {
      ...initialValues,
      loc_pics: new_all_media,
    };

    onFormSubmit(update_stop);
    props.change("loc_pics", new_all_media);
  };

  return (
    <div className="allLocationForm">
      <Form onSubmit={props.handleSubmit(onFormSubmit)}>
        <div className="innerLocatioForm">
          <div className="locationInput">
            {/* <div className="peakLocationInput"> */}
            <div className="inputLocationHeader">Address</div>
            <Field
              component={placeInput}
              className="locationInput"
              onSelect={handleAddress}
              name="location"
              placeholder="Business location"
            />
            {/* </div> */}
          </div>
          <div>
            <div className="latLngArea">
              <div className="locationFooter">
                <div className="cordInput">
                  <h4>Latitude</h4>
                  <Field
                    component={TextInput}
                    className="cordInput"
                    placeholder="Latitude"
                    name="stop_location.latitude"
                  />
                </div>
                <div className="cordInput">
                  <h4>Longitude</h4>
                  <Field
                    component={TextInput}
                    className="cordInput"
                    placeholder="Longitude"
                    name="stop_location.longitude"
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="diractionArea">
            <div className="photoLocation">
              <div className="inputLocationHeader">Picture of the location</div>
              {loc_pics && loc_pics.length > 0 ? (
                <div key={loc_pics[0].name}>
                  <img
                    style={{ width: "200px", height: "200px" }}
                    src={loc_pics[0].url}
                  />
                  <button
                    className="addButton"
                    onClick={() =>
                      window.confirm("Do you want to remove location picture?")
                        ? deleteFile(loc_pics[0])
                        : null
                    }
                  >
                    {" "}
                    Change picture
                  </button>
                </div>
              ) : (
                <button
                  className="addLocPicButton"
                  onClick={() => setPhotoModal(true)}
                >
                  + Add pictures to be more precise
                </button>
              )}
              <PhotoComponent
                loading={loading}
                objectId={initialValues.id}
                all_media={loc_pics}
                //handleSetMainFile={handleSetMainFile}
                handleDeletePhoto={deleteFile}
                open={photoOpen}
                onClose={() => setPhotoModal(false)}
                generalUploadFile={uploadFile}
              />
            </div>
            <div className="locationInput">
              <div className="inputLocationHeader">Directions by words</div>
              <Field
                name="diraction_text"
                type="textarea"
                component={textAreaInput}
                placeholder="Diraction in text"
                rows={2}
                className="locationInput"
              />
            </div>
          </div>
          <button
            className="saveButton"
            disabled={invalid || submitting}
            positive
            type="submit"
          >
            save & continue
          </button>
        </div>
      </Form>
    </div>
  );
};

export default withFirestore(
  connect(
    mapState,
    actions
  )(
    reduxForm({
      form: "businessForm",
      //validate,
      enableReinitialize: true,
      destroyOnUnmount: false,
      forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    })(PeakLocation)
  )
);
