import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { Checkbox, Header } from "semantic-ui-react";
import { addTourToUser } from "../../auth/authActions";

const query = (props) => {
  return [{ collection: "approval_tours" }, { collection: "appUsers" }];
};

const actions = {
  addTourToUser,
};

const mapState = (state) => {
  return {
    aprv_tours: state.firestore.ordered.approval_tours,
    app_users: state.firestore.ordered.appUsers,
  };
};
const TourAllocation = ({ aprv_tours, app_users, addTourToUser }) => {
  const [tours, setTours] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedTour, setSelectedTour] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [withBusiness, setWithBusiness] = useState(false);
  useEffect(() => {
    let theTours = [];
    if (aprv_tours) {
      aprv_tours.map((tour) => {
        theTours.push({ key: tour.id, text: tour.title, value: tour });
      });
      setTours(theTours);
    }
  }, [aprv_tours]);

  useEffect(() => {
    let theUsers = [];
    if (app_users) {
      app_users.map((user) => {
        if (user.email) {
          theUsers.push({ key: user.id, text: user.email, value: user });
        }
      });

      theUsers.sort(function (a, b) {
        if (a.text > b.text) {
          return 1;
        } else {
          return -1;
        }
      });

      setUsers(theUsers);
    }
  }, [app_users]);

  const [value, setValue] = useState(null);

  const onChange = (option) => {
    setValue(option.target.value);
  };

  const addTour = () => {
    let wanted_tour = tours.filter((tour) => {
      return tour.value.id === selectedTour;
    });
    let wanted_user = users.filter((user) => {
      return user.value.id === selectedUser;
    });

    addTourToUser(
      wanted_user[0].value.id,
      wanted_user[0].value.tours,
      { cartItems: [{ ...wanted_tour[0].value, count: 1 }] },
      withBusiness
    );
  };

  return (
    <div
      style={{
        width: "100%",
        display: "inline-block",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Header size="small" content="Peak Tour" />
      {/* <Field
        name="business_type"
        component={SelectInput}
        options={tours}
        value="tours.text"
        multiple={true}
        placeholder="tours"
      /> */}
      <select
        multiple
        onChange={(e) => {
          console.log("e", e.target.value);
          setSelectedTour(e.target.value);
        }}
        value={tours.text}
        style={{ margin: "20px", width: "30%", height: "150px" }}
      >
        {tours.map((tour) => (
          <option key={tour.key} value={tour.key}>
            {tour.text}
          </option>
        ))}
      </select>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "30%",
          margin: "20px",
        }}
      >
        <input name="search" onChange={(e) => onChange(e)} />
        <select
          multiple
          onChange={(e) => {
            console.log("e", e.target.value);
            setSelectedUser(e.target.value);
          }}
          value={users.text}
          style={{ height: "150px" }}
        >
          {users.map((user) => {
            if (user.email && user.email.includes("shemesh")) {
              console.log("SHEMESH!!!!!!");
            }
            if (value != null && user.text) {
              if (user.text.includes(value)) {
                return (
                  <option key={user.key} value={user.key}>
                    {user.text}
                  </option>
                );
              }
            } else {
              return (
                <option key={user.key} value={user.key}>
                  {user.text}
                </option>
              );
            }
          })}
        </select>
      </div>

      {/* <select
        options={options.filter(
          (option) => option.value !== this.state.value1.value
        )}
      /> */}
      <Checkbox slider onClick={() => setWithBusiness(!withBusiness)} />
      <div style={{ margin: "15px" }}>
        <button onClick={addTour}>Add Tour</button>
      </div>
    </div>
  );
};

export default compose(
  connect(mapState, actions),
  firestoreConnect((props) => query(props))
)(TourAllocation);
