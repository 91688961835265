import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  PAYMENT_SUCCESS,
  REMOVE1_FROM_CART,
} from "./cartConstant";

export const cartReducer = (
  state = {
    cartItems: JSON.parse(localStorage.getItem("cartItems") || "[]"),
    paymentDone: false,
  },
  action
) => {
  switch (action.type) {
    case ADD_TO_CART:
      return { cartItems: action.payload.cartItems };
    case REMOVE1_FROM_CART:
      return { cartItems: action.payload.cartItems };
    case REMOVE_FROM_CART:
      return { cartItems: action.payload.cartItems };
    case PAYMENT_SUCCESS:
      return { cartItems: [], paymentDone: true };
    default:
      return state;
  }
};
