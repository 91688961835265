import { toastr } from "react-redux-toastr";
import {
  ADD_TO_CART,
  PAYMENT_SUCCESS,
  REMOVE_FROM_CART,
  REMOVE1_FROM_CART,
} from "./cartConstant";

export const addToCart = (product) => (dispatch, getState) => {
  const cartItems = getState().cart.cartItems.slice();
  let alreadyExists = false;
  cartItems.forEach((x) => {
    if (x.id === product.id) {
      alreadyExists = true;
      x.count++;
    }
  });

  if (!alreadyExists) {
    cartItems.push({ ...product, count: 1 });
  }
  dispatch({
    type: ADD_TO_CART,
    payload: { cartItems },
  });
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
};

export const removeFromCart = (product) => (dispatch, getState) => {
  console.log("REMOVE FROM CART!");
  const cartItems = getState()
    .cart.cartItems.slice()
    .filter((x) => x.id !== product.id);
  dispatch({ type: REMOVE_FROM_CART, payload: { cartItems } });
  localStorage.setItem("cartItems", JSON.stringify(cartItems));
};

export const removeOneFromCart = (product) => (dispatch, getState) => {
  const cartItems = getState().cart.cartItems.slice();
  cartItems.forEach((x) => {
    if (x.id === product.id) {
      if (x.count === 1) {
        dispatch(removeFromCart(product));
      } else {
        x.count--;
        dispatch({
          type: REMOVE1_FROM_CART,
          payload: { cartItems },
        });
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
      }
    }
  });
};

export const clearCart = () => (dispatch, getState) => {
  const emptyCart = [];
  dispatch({ type: REMOVE_FROM_CART, payload: { emptyCart } });
  localStorage.setItem("cartItems", JSON.stringify(emptyCart));
  //console.log("CLEAR CART", getState().cart.cartItems.slice());
};

export const addCoupon =
  (coupon) =>
  async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    try {
      await firestore.add("coupons", coupon);
      /* console.log("ADD coupon AFTER", coupon); */
      toastr.success("Coupon has added");
    } catch (error) {
      console.log("the ERROR is ", error);
    }
  };

export const deleteCoupon =
  (coupon) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    try {
      console.log("dlelete", coupon);
      firestore.delete(`coupons/${coupon.id}`);
      /* const userRef = await firebase
        .firestore()
        .collection("coupons")
        .doc(coupon.id);

      await userRef.get().then(async function (doc) {
        let update_coupon = doc.data();
        update_coupon = {
          ...update_coupon,
          discount: update_coupon.discount.toString(),
        };
        await firestore.set(`coupons/${coupon.id}`, update_coupon, {
          merge: true,
        });
        console.log("check coupon", update_coupon);
      }); */
      toastr.success("Coupon has deleted");
    } catch (error) {
      console.log("ERROR DELETING COUPON", error);
    }
  };

export const disableCoupon =
  (coupon) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    try {
      console.log("dlelete", coupon);
      const userRef = await firebase
        .firestore()
        .collection("coupons")
        .doc(coupon.id);

      await userRef.get().then(async function (doc) {
        let update_coupon = doc.data();
        update_coupon = {
          ...update_coupon,
          discount: update_coupon.discount.toString(),
        };
        await firestore.set(`coupons/${coupon.id}`, update_coupon, {
          merge: true,
        });
        console.log("check coupon", update_coupon);
      });
      toastr.success("Coupon has deleted");
    } catch (error) {
      console.log("ERROR DELETING COUPON", error);
    }
  };

export const updatePurchase =
  (user_id, source_id) =>
  async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const cartItems = getState().cart.cartItems;
    cartItems.map(async (tour) => {
      const purchase = {
        amount: tour.count,
        guideID: tour.tour_guide.id,
        guideEmail: tour.tour_guide.email,
        time: Date(),
        source_id: source_id,
        tourID: tour.id,
        userID: user_id,
      };
      try {
        const newCityRef = firebase.firestore().collection("webPurchase").doc();
        await newCityRef.set(purchase);
        console.log("AFTER PURCHASE", purchase);
      } catch (error) {
        console.log("the ERROR is ", error);
      }
    });

    /*  cartItems.map((tour) => {
      allPurchaseTours.push(tour.id);
      guidePurchase.push({
        id: tour.tour_guide.id,
        email: tour.tour_guide.email,
      });
    });
    const purchase = {
      userId: user_id,
      tours: allPurchaseTours,
      guide: guidePurchase,
      date: Date(),
      sourceLink: source_id,
    };

    try {
      const newCityRef = firebase.firestore().collection("webPurchase").doc();
      const res = await newCityRef.set(purchase);
    } catch (error) {
      console.log("the ERROR is ", error);
    } */
  };

export const paymentSuccess = () => (dispatch, getState) => {
  dispatch({ type: PAYMENT_SUCCESS });
};

export default function formatCurrency(num) {
  return "₪" + Number(Number(num).toFixed(1)).toLocaleString() + " ";
}
