export const metaData = {
  description: {
    en: "The Self-guided app, the best self guided tours",
    he: "הסיורים העצמאיים ברמה הגבוה ביותר",
  },
};
export const navBar = {
  section1: { en: "Who we are?", he: "מי אנחנו" },
  section2: { en: "How it works?", he: "איך זה עובד" },
  section3: { en: "Guides", he: "מדריכים" },
  section4: { en: "businesses", he: "עסקים" },
  section5: { en: "Q & A", he: "שאלות תשובות" },
  section6: { en: "Contact us", he: "צור קשר" },
  section7: { en: "Our Tours", he: "הסיורים שלנו" },
  section8: { en: "Get into system", he: "התחבר למערכת" },
  style: { en: "centerNav", he: "centerNav centerNavHe hebrewDiraction " },
  menuStyle: { en: "responsive", he: "responsive hebMenu" },
};
export const section1 = {
  smallTitle: { en: "Who we are?", he: "מי אנחנו" },
  bigTitle: {
    en: "An app that will change the way you travel",
    he: "האפליקציה שהולכת לשנות את עולם הטיולים שלך",
  },

  area1Title: { en: "Independent tours", he: "סיורים עצמאיים" },
  area1Text: {
    en: "Independent Tours- DGuide provides independent tour services that suit the needs of the traveler without having a physical guide. History, culinary, religious, and nightlife tours are set up by local guides through the newest tech platforms available today for tour guides.",
    he: " אפליקציית DGuide  נותנת שירותי סיור עצמאי המתאימים לצרכי המטייל ללא נוכחות פיזית של מדריך. סיוריי היסטוריה, קולינרייה, דת, חיי לילה ועוד נבנים על ידי מדריכים מקומיים דרך פלטפורמת המדריכים החדשנית והטכנולוגית ביותר בשוק התיירות העולמי. ",
  },
  area2Title: { en: "Navigate in your own pace", he: "נווטו בקצב שלכם" },
  area2Text: {
    en: "Navigate at your own pace between the different touring stations and the app will make sure you won’t miss the most popular sites, most importantly -at your own pace and without the physical presence of a tour-guide.",
    he: "בין תחנות הסיור שנבחר תוכלו לנווט דרך האפליקציה שתדאג שלא תפספסו את היעדים הפופולריים בטיול והכי חשוב בקצב שלכם וללא נוכחות פיזית של המדריך.",
  },
  area3Title: { en: "Large variety of content", he: "תוכן מגוון לכל המשפחה" },
  area3Text: {
    en: "A variety of content for the whole family In every tour station you can enjoy the content from our best tour guides, combining audio, text, pictures, video and games for the whole family. You can integrate culinary tasting, attractions and many other things to build the tour the way you like!",
    he: "בכל אחת מתחנות הסיור תוכלו ליהנות מתכני מיטב המדריכים המשלבים אודיו, טקסט, תמונות, וידיאו ומשחקים לכל המשפחה . טעימות קולינריות, אטרקציות ועוד ישתלבו בחלק מהסיורים ועל פי רצונכם.",
  },

  textStyle: { en: "whoWeAreItemText", he: "whoWeAreItemText rtlText" },
  headerStyle: {
    en: "whoWeAreItemHeader",
    he: "whoWeAreItemHeader hebrewDiraction",
  },
};

export const section2 = {
  smallTitle: { en: "How it works?", he: "איך זה עובד" },
  bigTitle1: { en: "Choose your tour", he: "בחרו את הסיור שלכם" },
  text1: {
    en: "Culinary tours, history tours, art tours and many more are waiting just for you!",
    he: "סיורים קולינרים, היסטורים, אומונתיים ועוד מחכים רק לכם",
  },
  bigTitle2: {
    en: "Enjoy challenging and thrilling content",
    he: "להינות מתוכן מרתק ומאתגר",
  },
  text2: {
    en: "Explanation: Video, sound audio and games for the whole family will be waiting for you in each one of the stations",
    he: "וידיאו, אודיו שמע ומשחקים לכל המשפחה יחכו לכם בכל אחת מהתחנות וגם בנייהם",
  },
  bigTitle3: {
    en: "Tour at your own pace and your own way",
    he: "טיילו בקצב שלכם ובדרך שלכם",
  },
  text3: {
    en: "Dguide will guide you from station to station,and from one attraction to another in the most convenient way for you, whenever you want.",
    he: "Dguide תכוון אתכם מנקודה לנקודה ומאטרקציה לאטרקציה מתי שתרצו ובדרך הנוחה ביותר עבורכם.",
  },
  textStyle: { en: "caroulelContext", he: "caroulelContext rtlText" },
  continueButton: {
    en: "Got it, continue to tours",
    he: "הבנתי, קח אותי לחנות ",
  },
};

export const section3 = {
  text: {
    en: "Do you have experience with tour-guiding ?Do you have interesting content? Do you think you know your city the best? would you like to make money out of it? Join the Dguides community, create your own tour through our platform and enjoy maximum exposure and an extra income!",
    he: "יש לכם נסיון בתחום הסיור וחושבים שהתוכן שלכם הוא הכי מעניין? חושבים שאתם מכירים את העיר שלכם הכי טוב ורוצים להרוויח מזה כסף? הצטרפו לקהילת DGUIDES, צרו את הסיור העצמאי שלכם בפלטפורמת יצירת הסיורים המובילה בעולם ותהנו מחשיפה מקסימלית והכנסה נוספת!",
  },
  buttonText: {
    en: "Join to our guide community",
    he: "הצטרף לקהילת המדריכים שלנו",
  },
  objStyle: { en: "guideSection", he: "guideSection hebrewDiraction rtlText" },
};

export const section4 = {
  text: {
    en: "If you have a culinary business, or any other attractions in the field of tourism, tell us about your business! Create your business proposal and allow tour-guides from all over the world to combine your business in their tour. This will allow you to enjoy more tourist traffic, enlarging your profits and maximum exposure for FREE!",
    he: "אם יש לכם עסק בתחום הקולינרי, או בכל תחום תיירותי אטרקטיבי אחר, אתם מוזמנים לספר לנו על העסק שלכם. צרו את הצעת ההערך שלכם ואפשרו למדריכים מכל העולם לשלב את העסק שלכם במהלך המסלול שלהם. כך תוכלו ליהנות מתנועת תיירים, הגדלת מכירות וחשיפה מקסימלית בחינם!",
  },
  buttonText: {
    en: "Join to our business community",
    he: "הצטרף לקהילת העסקים שלנו",
  },
  objStyle: {
    en: "businessSection",
    he: "businessSection hebrewDitaction rtlText",
  },
};

export const section5 = {
  question1: {
    en: "Who creates the independent tours on the application ?",
    he: "מי יוצר את הסיורים העצמאיים באפליקציה?",
  },
  answer1: {
    en: "Tour guides, bloggers and professionals who create content ,as well as individuals that have long term experience that were chosen carefully to make sure you will receive the most interesting content throughout the tour",
    he: "מדריכי טיולים, אנשי תוכן ובלוגרים מקצועיים ובעלי ניסיון רב שנבחרו בקפידה דואגים שלכם יהיה את התוכן המעניין ביותר בכל סיור עצמאי",
  },
  question2: {
    en: "What is an independent tour on Dguide ?",
    he: "מה זה סיור עצמאי בDguide?",
  },
  answer2: {
    en: "The independent tour will navigate you to places, attractions and hidden corners depending on the category and the tour that you have chosen.  You will receive content  from the tour-guide at every point in the video, audio, text photos and trivia games. and all that at your own time and pace. ",
    he: "הסיור העצמאי ינווט אתכם למקומות, האטרקציות והפינות הנסתרות ביותר על פי קטגוריית עניין והסיור שבחרתם. את תוכן המדריך תפגשו בכל נקודת עניין בוידאו ,אודיו, טקסט תמונות משחקי טריוויה ועוד בזמן שלכם ובקצב שלכם :)",
  },
  question3: {
    en: "Where do you watch and purchase the independent tours?",
    he: "הייכן רואים ורוכשים את הסיורים העצמאיים?",
  },
  answer3: {
    en: "Download our application (link on top) to your phone. On the app you can choose tours from the advanced filtered categories.  and  Every tour has a trailer.",
    he: "עלייכם להוריד את האפליקציה שלנו (לינק מופיע מעלה) לנייד. באפליקציה תוכלו לבחור סיורים על פי קטגוריות סינון מתקדמות. לכל סיור ישנו טריילר ",
  },
  question4: {
    en: "How do you create an independent tour ?",
    he: "איך יוצרים סיור עצמאי?",
  },
  answer4: {
    en: "YYou enter the tour creating platform, and create an individual unique and interesting tour. After that our “content checker” will contact you.",
    he: 'נכנסים לפלטפורמת יצירת הסיורים, יוצרים סיור עצמאי ייחודי ומעניין. לאחר מכן "בודק התוכן" שלנו יצור איתכם קשר.',
  },
  title: { en: "F & Q", he: "שאלות תשובות" },
  faqStyle: { en: "faqs", he: "faqs hebrewDiraction rtlText" },
};

export const section6 = {
  title: { en: "CONTACT US", he: "צור קשר" },
  massage: { en: "Please write your message here", he: "כתוב את ההודעה כאן" },
  name: { en: "Your Name", he: "שם" },
  email: { en: "your@email.com", he: "אימייל" },
  submit: { en: "Submit", he: "שלח" },
};

export const onBoardingHeader = {
  part1: { en: "Welcome to Dguide", he: "ברוכים הבאים ל-Dguide" },
  part2: { en: "Choose your tour", he: "בחרו את הסיור שמתאים לכם" },
  part3: {
    en: "Enjoy challenging and thrilling content",
    he: "מוכנים ליהנות מתוכן מרתק ומאתגר?",
  },
  part4: {
    en: "Tour in your own pace and your own way",
    he: "טיילו בקצב שלכם ובדרך שלכם",
  },
  part5: {
    en: "Local businesses and attractions will be part of your tour",
    he: "עסקים מקומיים ואטרקציות ייחודיות יהיו חלק מהסיור שלכם.",
  },
};

export const onBoardingText = {
  part1: {
    en: "Independent tours that were written by the best guides around the world",
    he: "סיורים עצמאיים שנכתבו על ידי מיטב המדריכים",
  },
  part2: {
    en: "Culinary tours, Historian, Artistic and more are waiting for you ",
    he: "סיורי קולינריה, היסטוריה, אומנות ועוד מחכים רק לכם",
  },
  part3: {
    en: "Video, sound audio and games for all the family will be waiting for you in each one of the stops",
    he: "בכל אחת מהתחנות, וגם ביניהן, יחכו לכם תכני וידאו, אודיו והפעלות לכל המשפחה",
  },
  part4: {
    en: "Dguide will guide you from stop to top in the most convenient way for you, whenever you want",
    he: "Dguide יכוון אתכם מתחנה לתחנה מתי שרק תרצו ובדרך הנוחה ביותר עבורכם",
  },
  part5: {
    en: "Culinary tastings, attractions and entrance tickets to tourist sites will be waiting for you at the various tour stations presenting the Barcode in the various businesses",
    he: "הסיורים משלבים ברקודים שיקנו לכם שימוש באטרקציות, כמו טעימות קולינריות וכרטיסי כניסה לאתרי תיירות בסמוך לתחנות הסיור",
  },
  style: { en: "", he: "caroulelContext" },
};

export const cartText = {
  proceed: {
    en: "Proceed",
    he: "התקדם לתשלום",
  },
  backShopping: {
    en: "Continue shoping",
    he: "חזור לחנות",
  },
  loginText: {
    en: "",
    he: "רגע לפני הרכישה, צרו משתמש חדש איתו תתחברו לאפליקציה ותוכלו להשתמש בסיור שתרכשו.",
  },
  couponSuccess: {
    en: "",
    he: "הקופון עודכן בהצלחה",
  },
  couponFail: {
    en: "",
    he: "הקופון שגוי אנא נסה שנית",
  },
  couponPlaceHolder: {
    en: "Have a coupon?",
    he: "השתמש בקופון",
  },
  couponButton: {
    en: "Apply",
    he: "השתמש",
  },
};
