import React, { useEffect } from "react";
import { connect } from "react-redux";
import { socialLoginFuncApp, addTourToUser } from "../auth/authActions";
import {
  paymentSuccess,
  removeFromCart,
  updatePurchase,
} from "../cart/cartActions";
import playStore from "../../images/playStore.png";
import appStore from "../../images/appStore.png";
import * as emailjs from "emailjs-com";

const actions = {
  socialLoginFuncApp,
  addTourToUser,
  removeFromCart,
  updatePurchase,
};

const mapSate = (state, ownProps) => {
  let approval_id = ownProps.match.params.approvalId;
  let transaction_id = ownProps.match.params.transactionId;
  let source_id = ownProps.match.params.sourceId;
  return {
    approval_id: approval_id,
    cart: state.cart,
    auth: state.firebase.auth,
    transaction_id: transaction_id,
    source_id: source_id,
  };
};

const PaymeSuccess = ({
  approval_id,
  cart,
  auth,
  removeFromCart,
  addTourToUser,
  transaction_id,
  source_id,
  updatePurchase,
}) => {
  const sendMail = () => {
    const templateParams = {
      from_name: auth.email,
      to_name: auth.email,
      to_email: auth.email,
      reply_to: "idan@d-guide.co",
      subject: "Confirm purchase",
      message_html: "",
    };
    try {
      emailjs
        .send(
          "service_ddpey1o",
          "template_ca3dqqz",
          templateParams,
          "user_Vaw3LX1r3I1D5QNPfDIx4"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    } catch (err) {
      console.log("THERE IS A PROBLEM");
    }
  };

  useEffect(() => {
    let purchase_tours = [];
    cart.cartItems.map((item) => purchase_tours.push(item.id));

    addTourToUser(auth.uid, [], cart, true);

    let total_amount = cart.cartItems.reduce(
      (a, c) => a + c.price * c.count,
      0
    );
    cart.cartItems.map((tour) => {
      tour.stops.map((stop) => {
        if (stop.type.includes("business")) {
          total_amount += parseInt(stop.offer_price);
        }
      });
    });
    let check = total_amount;
    auth.uid.split("").forEach((character) => {
      let current = character.charCodeAt(0);
      check = check + current;
    });

    paymentSuccess(auth.uid, source_id);

    updatePurchase(auth.uid, source_id);
    cart.cartItems.map((item) => removeFromCart(item));
    sendMail();
    if (check.toString() === transaction_id && approval_id === auth.uid) {
      // paymentSuccess(auth.uid, source_id);
      //updatePurchase(auth.uid, source_id);
      /*  cart.cartItems.map((item) => removeFromCart(item));
      sendMail(); */
    }
  }, [auth]);

  return (
    <div className="successPayment">
      <div className="successTitle">Your order has been placed.</div>
      <div className="successMassage">
        At thouse moments we sending you an email with all the details for your
        next advencher with D-Guide. We are allways available for any questions
      </div>

      <div className="appDownload">
        <div className="appLinks">
          <a href="https://play.google.com/store/apps/details?id=com.tours.guide">
            <img className="getAppButton" src={playStore} alt="playStoreLink" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.tours.guide">
            <img className="getAppButton" src={appStore} alt="appStoreLink" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default connect(mapSate, actions)(PaymeSuccess);
