import React, { useState, Fragment, useEffect } from "react";
import {
  Divider,
  Button,
  Card,
  Modal,
  Header,
  Checkbox,
  Icon,
} from "semantic-ui-react";
import { addQuestion } from "../../media/mediaActions";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import cuid from "cuid";
import "./questionStyle.css";

const actions = {
  addQuestion,
};

const mapState = (state) => ({
  loading: state.async.loading,
});

const QuestionComponent = (props) => {
  const { loading, open, onClose, uploadQuestion, content } = props;
  const [question, setQuestion] = useState("");
  const [answers, setAnswers] = useState([{ option: "", isAnswer: false }]);
  const [index, setIndex] = useState(
    content && content.options ? content.options.length : 0
  );

  useEffect(() => {
    setQuestion(content && content.question_text ? content.question_text : "");
    setAnswers(
      content && content.options
        ? content.options
        : [{ option: "", isAnswer: false }]
    );
  }, [content]);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      //await addQuestion(question, answers, tourId,objectId,all_media)
      const question_media = {
        name: cuid(),
        question_text: question,
        options: answers,
        type: "question",
        order: content && content.order ? content.order : undefined,
      };
      uploadQuestion(question_media);
      onClose();
    } catch (error) {
      console.log(error);
      toastr.error("Oops", "Something went wrong");
    }
  };
  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleOptionChange = (e, data) => {
    const updateOption = [...answers];
    if (e.target.className === "option") {
      updateOption[e.target.dataset.idx][e.target.className] = e.target.value;
    } else {
      updateOption[data.id][data.className] = data.checked;
    }

    setAnswers(updateOption);
  };

  const handleAddFields = () => {
    const values = [...answers];
    values.push({ option: "", isAnswer: false });
    setAnswers(values);
  };

  const handleRemoveFields = () => {
    const values = [...answers];
    values.splice(answers.length - 1, 1);
    setAnswers(values);
  };
  const handleCancleCrop = () => {};

  return (
    <Modal size="large" open={open} onClose={onClose}>
      <Modal.Header>Question</Modal.Header>
      <Modal.Content>
        <form onSubmit={handleSubmit}>
          <div className="questionContent">
            <div className="questionInput" key={`question`}>
              <label className="inputHeader1" htmlFor="qustion"></label>
              <Header as="h3" content="Your Question" />
              <input
                type="text"
                className="question"
                id="question"
                name="question_text"
                value={question}
                onChange={handleQuestionChange}
              />
            </div>
            <Header as="h3" content="Your answers" />
            {answers &&
              answers.map((answer, index) => (
                <div className="questionInput1" key={`${answer}~${index}`}>
                  <label className="inputHeader" htmlFor="Answer1"></label>
                  <Header size="small" content={`Option #${index + 1}`} />
                  <input
                    type="text"
                    name={`option-${index}`}
                    data-idx={index}
                    id={`option-${index}`}
                    className="option"
                    value={answers[index].option}
                    onChange={handleOptionChange}
                  />
                  <Checkbox
                    type="checkbox"
                    name={`isAnswer-${index}`}
                    data-idx={index}
                    id={index}
                    className="isAnswer"
                    checked={answer.isAnswer}
                    onChange={handleOptionChange}
                    slider
                    label="Is this the answer?"
                  />
                </div>
              ))}
            <Divider />
            <div className="addRemoveQuestion">
              <Button
                className="btn"
                type="button"
                onClick={() => handleRemoveFields(index)}
              >
                <Icon name="minus" color="black" />
              </Button>
              <Button
                className="btn"
                type="button"
                onClick={() => handleAddFields()}
              >
                <Icon name="plus" color="black" />
              </Button>
            </div>
          </div>
          <div className="buttonArea">
            <Button
              loading={loading}
              type="submit"
              onSubmit={handleSubmit}
              style={{ width: "100px", height: "30px", margin: "5px" }}
              positive
              icon="check"
            />
          </div>
        </form>
        <Button
          disabled={loading}
          onClick={() => onClose()}
          style={{ width: "100px", height: "30px", margin: "5px" }}
          icon="close"
        />

        <Divider />
      </Modal.Content>
    </Modal>
  );
};

export default connect(mapState, actions)(QuestionComponent);
