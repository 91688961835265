import React from "react";
import { Rating } from "semantic-ui-react";
import SmartText from "../../app/common/util/SmatText";
import "./review.css";

const Review = ({ review }) => {
  return (
    <div className="reviewZone">
      <div className="topReview">
        <div className="picReview1">
          {review.imgurl && (
            <img className="picReview" src={review.imgurl && review.imgurl} />
          )}
        </div>
        <div className="topText">
          <div className="reviewName">{review.name && review.name}</div>
          <div className="reviewRating">
            {review.rating && (
              <Rating
                icon="star"
                defaultRating={review.rating}
                maxRating={5}
                disabled
                size="large"
                className="ratingStar"
              />
            )}
          </div>
        </div>
        <div className="reviewDate">
          {review.time &&
            new Intl.DateTimeFormat("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(review.time.toDate())}
        </div>
      </div>
      <div className="reviewContent">
        {review.content && <SmartText text={review.content} length={120} />}
      </div>
    </div>
  );
};

export default Review;
