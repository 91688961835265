import React, { useEffect } from "react";
import { connect } from "react-redux";
/*import { addToursToApp } from "../user/userActions";
import { socialLoginFuncApp, addTourToUser } from "../auth/authActions";
import { paymentSuccess, removeFromCart } from "../cart/cartActions"; */
/* 
const actions = {
  addToursToApp,
  socialLoginFuncApp,
  addTourToUser,
  removeFromCart,
};
*/
const mapSate = (state, ownProps) => {
  let approval_id = ownProps.match.params.approvalId;
  let transaction_id = ownProps.match.params.transactionId;
  let source_id = ownProps.match.params.sourceId;
  return {
    approval_id: approval_id,
    cart: state.cart,
    auth: state.firebase.auth,
    transaction_id: transaction_id,
    source_id: source_id,
  };
};

const PaymeReturn = ({ approval_id, transaction_id, source_id }) => {
  useEffect(() => {
    if (window.parent && window.location.href) {
      //window.parent.location = self.location;

      console.log("parent ", window.top.location, window.parent);
    }
    console.log(
      "payment RETURN REDIRACT",
      `https://d-guide.co/outerpaymentSuccess/${approval_id}/${transaction_id}/${source_id}`
    );
    window.top.location.href = `https://d-guide.co/outerpaymentSuccess/${approval_id}/${transaction_id}/${source_id}`;
  });

  /*   useEffect(() => { 
    let purchase_tours = [];
    cart.cartItems.map((item) => purchase_tours.push(item.id));
    console.log("purchase_tours", purchase_tours);
    addTourToUser(auth.uid, purchase_tours);
    //cart.cartItems.map((item) => removeFromCart(item));
    paymentSuccess();
  }, [auth]); */

  return <div>RETURN PAYMENT1</div>;
};

export default connect(mapSate)(PaymeReturn);
