import React from "react";
import styled from "styled-components";
import { useTable, useRowSelect, useSortBy, usePagination } from "react-table";
import "./userControl.css";

/* const sendAnEmail = () => {
  var nodemailer = require("nodemailer");
  var smtpTransport = require("nodemailer-smtp-transport");

  var transporter = nodemailer.createTransport(
    smtpTransport({
      service: "gmail",
      host: "smtp.gmail.com",
      auth: {
        user: "rootguide@d-guide.co",
        pass: "tr0mb0sa",
      },
    })
  );

  var mailOptions = {
    from: "idan@d-guide.co",
    to: "shemesh1500@gmail.com",
    subject: "Sending Email using Node.js[nodemailer]",
    text: "That was easy!",
  };
  console.log("sending email");
  transporter.sendMail(mailOptions, function (error, info) {
    if (error) {
      console.log("sendMail catch error", error);
    } else {
      console.log("Email sent: " + info.response);
    }
  });
}; */

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <input
        style={{ top: "0", left: "0", position: "relative" }}
        type="checkbox"
        className="checkBoxs"
        ref={resolvedRef}
        {...rest}
      />
    );
  }
);
function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    selectedFlatRows,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { selectedRowIds, pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 2 },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const sendEmail = () => {
    let allSelctedEmails = [];
    console.log("selectedFlatRows[].original");
    selectedFlatRows.map((d) => {
      if (d.original && d.original.email) {
        //console.log("ORIGINAL", d, d.original, d.original.email);
        allSelctedEmails.push(d.original.email);
      } else {
        console.log("DDDDDDDDDDDD", d);
      }
    });
    //
    console.log("allSelctedEmails", allSelctedEmails);
    //sendAnEmail();
  };

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : " 🔼"
                      : ""}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {/*  <tbody {...getTableBodyProps()}>
          {rows.slice(0, 10).map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody> */}
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
      <button onClick={() => sendEmail()}> CHECK </button>
      <p>Selected Rows: {Object.keys(selectedRowIds).length}</p>
      <pre>
        <code>
          {JSON.stringify(
            {
              selectedRowIds: selectedRowIds,
              "selectedFlatRows[].original": selectedFlatRows.map(
                (d) => d.original
              ),
            },
            null,
            2
          )}
        </code>
      </pre>
    </>
  );
}
const UserControl = ({ users }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        columns: [
          {
            Header: "First Name",
            accessor: "firstname",
          },
          {
            Header: "Last Name",
            accessor: "lastname",
          },
        ],
      },
      {
        Header: "Info",
        columns: [
          {
            Header: "email",
            accessor: "email",
          },
          {
            Header: "Creation time",
            accessor: "creationTime",
          },
          {
            Header: "Last sign-in",
            accessor: "lastSignIn",
          },
          {
            Header: "tour",
            accessor: "tours",
          },
          {
            Header: "id",
            accessor: "id",
          },
        ],
      },
    ],
    []
  );

  let data = users.map((user) => {
    //console.log("DATE@@", typeof user.lastSignIn, user.email);
    //console.log("DATE!!", user.lastSignIn ? user.lastSignIn.toDate() : null);

    return {
      ...user,
      creationTime: user.creationTime ? user.creationTime : null,
      lastSignIn: user.lastSignIn ? user.lastSignIn : null,
    };
  });
  //console.log("USERS", users);
  return (
    <Styles>
      <Table columns={columns} data={data} />
    </Styles>
  );
};

export default UserControl;
