import React from "react";
import { Rating } from "semantic-ui-react";
import "./product.css";
import locationIcon from "../../images/location.svg";
import distanceIcon from "../../images/distance.svg";
import durationIcon from "../../images/duration.svg";
import rangeIcon from "../../images/range.svg";
import SmartText from "../../app/common/util/SmatText";

const Product = ({ tour, addToCart }) => {
  let video = tour.all_media.filter((media) => media.type.includes("video"))[0];

  const formatLabel = (secs) => {
    if (secs === 86400) secs = 86360;
    var minutes = Math.floor(secs / 60);
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return `${hours}:${("0" + minutes).slice(-2)}`;
  };
  return (
    <div className="productItem" key={tour.id}>
      <div className="productTop">
        <div className="prodName">{tour.title}</div>
        <div className="prodSentense">{tour.main_sentense}</div>
        <div className="prodVideo">
          {video && video.url && (
            <video
              loop
              autoplay
              muted
              width="70%"
              hight="70%"
              controls
              style={{ borderRadius: "10px", margin: "auto" }}
            >
              <source src={video?.url} type="video/mp4" />
            </video>
          )}
        </div>
        <div className="tourRate">
          {tour.rating && (
            <Rating
              icon="star"
              defaultRating={tour.rating.total + 1}
              maxRating={5}
              disabled
              size="large"
              className="ratingStar"
            />
          )}{" "}
          {tour.rating && tour.rating.votes}{" "}
        </div>
        {/* <div className="DetailNguide"> */}
        <div className="prodDetails">
          <div className="prodDetailsIcon">
            <img className="imageIcon" src={locationIcon} alt="Location" />
            <div className="tourLocation">{tour.location && tour.location}</div>
          </div>
          <div className="prodDetailsIcon">
            <img className="imageIcon" src={distanceIcon} alt="distanse" />
            <div className="tourDistance">
              {tour.distance && Number((tour.distance / 1.6 / 1000).toFixed(2))}
              K
            </div>
          </div>
        </div>
        <div className="prodDetails">
          <div className="prodDetailsIcon">
            <img className="imageIcon" src={durationIcon} alt="duration" />
            <div className="tourDuration">
              {tour.duration && tour.duration} H
            </div>
          </div>

          <div className="prodDetailsIcon">
            <img className="imageIcon" src={rangeIcon} alt="difficulty" />
            <div className="tourDifficulty">
              {tour.hours_range && formatLabel(tour.hours_range.min)} -{" "}
              {tour.hours_range && formatLabel(tour.hours_range.max)}
            </div>
          </div>
        </div>

        <div className="descTour">
          {" "}
          <SmartText text={tour.description} length={180} />
        </div>
        <div className="prodName">
          <span
            style={{ marginRight: "5px", width: "15px", hight: "15px" }}
            class="iconify"
            data-icon="fa-ils"
            data-inline="false"
          ></span>
          {tour.price}
        </div>
        <button
          onClick={() => addToCart(tour)}
          className="button primary"
          className="actionButton"
          style={{ height: "38px" }}
        >
          הוסף לעגלה
        </button>
      </div>
      <div className="prodImg">
        {/*  <img className="tourImg" src={tour.tour_image} alt="tour image" /> */}
        {/* video && video.url && (
          <video
            loop
            autoplay
            muted
            width="100%"
            hight="100%"
            controls
            style={{ borderRadius: "10px", margin: "auto" }}
          >
            <source src={video?.url} type="video/mp4" />
          </video>
        ) */}
      </div>
    </div>
  );
};

export default Product;
