import React, { useEffect, useState } from "react";
import { addToCart } from "../cart/cartActions";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import "../cart/cartStyle.css";

import Slide from "react-reveal/Slide";
import styled, { css } from "styled-components";
import makeCarousel from "react-reveal/makeCarousel";

import rightCarousel from "../../images/rightCarousel.png";
import leftCarousel from "../../images/leftCarusel.png";
import bigLogo from "../../images/bigLogo.png";
import SlideDrawer from "../../app/common/util/SlidePane";
import "./salePage.css";
import timeImg from "../../images/time.png";
import navImg from "../../images/navigate.png";
import yourImg from "../../images/your.png";
import { Rating } from "semantic-ui-react";
import locationIcon from "../../images/location1.png";
import distanceIcon from "../../images/km1.png";
import pricetag from "../../images/pricetag.png";
import durationIcon from "../../images/duration1.png";
import rangeIcon from "../../images/leng.png";
import SmartText from "../../app/common/util/SmatText";
import { Helmet } from "react-helmet";
import Review from "../reviews/Review";

const query = (props) => {
  let tourId;
  if (props.tourId) {
    tourId = props.tourId;
  } else if (props.initialValues && props.initialValues.tourId) {
    tourId = props.initialValues.tourId;
  }
  if (tourId) {
    return [
      {
        collection: "approval_tours",
        doc: tourId,
      },
      { collection: "reviews" },
    ];
  } else {
    return [{ collection: "approval_tours" }, { collection: "reviews" }];
  }
};

const actions = {
  addToCart,
};

const mapState = (state, ownProps) => {
  let guideId = null;
  let wanted_tour = null;
  let english = false;
  let source = "general";

  if (ownProps.match.params.sourceId) {
    source = ownProps.match.params.sourceId;
  }

  if (ownProps.match.params.guideId) {
    guideId = ownProps.match.params.guideId;

    if (guideId === "ortalTours") {
      if (state.firestore.ordered.approval_tours) {
        wanted_tour = state.firestore.ordered.approval_tours.filter((tour) => {
          return (
            tour.id === "rljiQyhYwm2pMEnZgDaE" ||
            tour.id === "1XU3bpkdsgJePawMWXB0" ||
            tour.id === "QwvsqpPJMHwVQX6Qdvyr" ||
            tour.id === "lfngCB2AQGIoIdVkiIdu"
          );
        });
      }
    } else if (guideId === "tlvTours") {
      if (state.firestore.ordered.approval_tours) {
        wanted_tour = state.firestore.ordered.approval_tours.filter((tour) => {
          return (
            tour.id === "DVl8TCMY8VhQdN9qr1Nc" ||
            tour.id === "2RsJUf7rYXZpZnkfOCqr" ||
            tour.id === "MnmUJZoT4znQur4YQjMu" ||
            tour.id === "n0Kuwb71znhTCxu4nZQ4"
          );
        });
      }
    } else if (guideId === "zuzuTours") {
      if (state.firestore.ordered.approval_tours) {
        wanted_tour = state.firestore.ordered.approval_tours.filter((tour) => {
          return (
            tour.id === "lfngCB2AQGIoIdVkiIdu" ||
            tour.id === "QwvsqpPJMHwVQX6Qdvyr" ||
            tour.id === "rljiQyhYwm2pMEnZgDaE" ||
            tour.id === "1XU3bpkdsgJePawMWXB0"
          );
        });
      }
    } else if (
      guideId === "EldanHotel" ||
      guideId === "BatSheva" ||
      guideId === "MyJerusalemView"
    ) {
      if (state.firestore.ordered.approval_tours) {
        let temp_wanted_tour = state.firestore.ordered.approval_tours.filter(
          (tour) => {
            return (
              tour.id === "1XU3bpkdsgJePawMWXB0" ||
              tour.id === "rljiQyhYwm2pMEnZgDaE" ||
              tour.id === "lfngCB2AQGIoIdVkiIdu" ||
              tour.id === "QwvsqpPJMHwVQX6Qdvyr" ||
              tour.id === "rljiQyhYwm2pMEnZgDaE"
            );
          }
        );
        /*  if (guideId === "EldanHotel") { */
        wanted_tour = temp_wanted_tour.map((tour) => {
          return { ...tour, price: tour.price * 0.85 };
        });
        /*   } else {
          wanted_tour = temp_wanted_tour;
        } */
      }
    } else if (guideId === "brownShok") {
      if (state.firestore.ordered.approval_tours) {
        wanted_tour = state.firestore.ordered.approval_tours.filter((tour) => {
          return tour.id === "lfngCB2AQGIoIdVkiIdu";
        })[0];

        wanted_tour.price = 49;
        wanted_tour.stops.map((stop) => {
          if (stop.type === "business") {
            //return { ...stop, type: "bigStop" };
            stop.type = "bigStop";
          }
        });
      }
    } else if (guideId === "brownDavid") {
      if (state.firestore.ordered.approval_tours) {
        wanted_tour = state.firestore.ordered.approval_tours.filter((tour) => {
          return tour.id === "rljiQyhYwm2pMEnZgDaE";
        })[0];

        wanted_tour.price = 49;
      }
    } else {
      if (state.firestore.ordered.approval_tours) {
        wanted_tour = state.firestore.ordered.approval_tours.filter(
          (tour) => guideId === tour.id
        )[0];
      }
    }
  }

  return {
    auth: state.firebase.auth,
    tours: wanted_tour, //state.firestore.ordered.approval_tours,
    english: english,
    sourceID: source,
    reviews: state.firestore.ordered.reviews,
    cart: state.cart,
  };
};

const OuterPay = ({
  addToCart,
  english,
  auth,
  tours,
  sourceID,
  reviews,
  cart,
}) => {
  const [status, setStatus] = useState("login");
  const [showCart, setShowCart] = useState(false);
  const [drawerClasses, setDrawerClasses] = useState("side-drawer");
  const [cartEmpty, setCartEmapty] = useState(false);
  //const [video, setVideo] = useState(null);
  const [businessStop, setBusinessStop] = useState([]);
  const [tour, setTour] = useState(null);

  useEffect(() => {
    if (!auth.authenticated) {
      setStatus("login");
    }

    if (tours && !tours.length) {
      setTour(tours);
    } else if (tours && tours.length > 1) {
      /* console.log("tour", tours); */
    }
  }, [auth, tours]);

  useEffect(() => {
    /* if (tour && tour.all_media) {
      
      let tmp_video = tour.all_media.filter((media) =>
        media.type.includes("video")
      )[0];
      setTimeout(() => {
        setVideo(tmp_video);
        console.log("AFTER RENDER VIDEO", video, tmp_video);
      }, 1500);
    }
 */
    if (tour && tour.stops) {
      let business_stop = tour.stops.filter((stop) =>
        stop.type.includes("business")
      );
      setBusinessStop(business_stop);
    }
  }, [tour]);

  const getTourVideo = (tour) => {
    return tour.all_media.filter((media) => media.type.includes("video"))[0];
  };
  const formatLabel = (secs) => {
    if (secs === 86400) secs = 86360;
    var minutes = Math.floor(secs / 60);
    var hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return `${hours}:${("0" + minutes).slice(-2)}`;
  };
  const addProdToCart = (tour) => {
    setShowCart(true);
    setDrawerClasses("side-drawer open");
    /* setTimeout(() => {
      setShowCart(!showCart);
      setDrawerClasses("side-drawer");
    }, 3000); */

    let tour_exist = cart.cartItems.filter((item) => {
      if (item.id === tour.id) {
        return -1;
      } else return 1;
    });
    if (tour_exist.length === 0) addToCart(tour);
  };

  const [lang, setLang] = useState(english ? "en" : "he");
  const [openModal, setOpenModal] = useState(true);
  const /*width = "300px",*/ height = "150px";
  const Arrow = styled.div`
    text-shadow: 1px 1px 1px #fff;
    z-index: 1000;
    line-height: ${height};
    text-align: center;
    position: absolute;
    top: ;
    width: 10%;
    font-size: 3em;
    cursor: pointer;
    user-select: none;
    padding-top: 120px;
    opacity: 0.5;
    ${(props) =>
      props.right
        ? tour
          ? css`
              left: 70%;
            `
          : css`
              left: 80%;
            `
        : tour
        ? css`
            left: 20%;
          `
        : css`
            left: 10%;
          `}
    @media (max-width: 600px) {
      ${(props) =>
        props.right
          ? tour
            ? css`
                left: 88%;
                padding-top: 120px;
                opacity: 0.5;
              `
            : css`
                left: 60%;
                padding-top: 500px;
                opacity: 0.8;
              `
          : tour
          ? css`
              left: 3%;
              padding-top: 120px;
              opacity: 0.5;
            `
          : css`
              left: 25%;
              padding-top: 500px;
              opacity: 0.8;
            `}
    }
  `;

  const Container = styled.div`
    position: relative;
    overflow: hidden;
    /* width: 1100px; */
    width: 100%;
    height: 650px;
    @media (max-width: 600px) {
      width: 100%;
      height: 450px;
    }
  `;

  const CarouselUI = ({ position, handleClick, children }) => (
    <Container>
      {children}
      <Arrow onClick={handleClick} data-position={position - 1}>
        {" "}
        <img src={leftCarousel} alt="airbaloon" />
      </Arrow>
      <Arrow right onClick={handleClick} data-position={position + 1}>
        {" "}
        <img
          src={rightCarousel}
          alt="airbaloon"
          style={{ marginTop: "28px" }}
        />{" "}
      </Arrow>
    </Container>
  );
  const Carousell = makeCarousel(CarouselUI);

  const renderTourHeader = (tour) => {
    let cur_video = null;
    cur_video = getTourVideo(tour);
    return (
      <div key={tour.id}>
        <h1 className="saleHeader">{tour.title}</h1>
        <h2 className="mainSentense">{tour.main_sentense}</h2>

        <div className="tourRate">
          {tour.rating && (
            <Rating
              icon="star"
              defaultRating={tour.rating.total}
              maxRating={5}
              disabled
              size="large"
              className="ratingStar"
            />
          )}
          {" דירוגים /"}
          {tour.rating && tour.rating.votes}{" "}
        </div>
        {cur_video && (
          <div>
            <video
              className="saleVideo"
              poster={cur_video.poster_url}
              loop
              autoplay
              muted
              controls
            >
              <source src={cur_video.url} type="video/mp4" />
            </video>
          </div>
        )}
      </div>
    );
  };

  const commonQuestionRender = () => {
    return (
      <div className="commonQuestion">
        <div className="commonQuestionHeader">שאלות נפוצות</div>
        <div className="commonQuestionQestion">
          <div className="commonQuestionQestionQ">איפה נטייל?</div>
          <img
            className="commonQuestionQestionImg"
            src={navImg}
            alt="airbaloon"
          />
          <div className="commonQuestionQestionA">
            האפליקציה תנווט אתכם בין התחנות השונות ובכל תחנה יוצגו בפנייכם כל
            תכני המדריך- ממש כמו בסיור קבוצתי
          </div>
        </div>

        <div className="commonQuestionQestion">
          <div className="commonQuestionQestionQ">מי המדריך?</div>
          <img
            className="commonQuestionQestionImg"
            src={yourImg}
            alt="airbaloon"
          />
          <div className="commonQuestionQestionA">
            המדריכים מקצועיים ומנוסים אשר הזינו את תכנייהם, תוכלו לפגוש אותם
            בסרטוני הדרכה, הרחבות שמע, תכנים מרתקים, פעילויות ואתגרי טרוויה לכל
            המשפחה
          </div>
        </div>
        <div className="commonQuestionQestion">
          <div className="commonQuestionQestionQ">באיזה שעה?</div>
          <img
            className="commonQuestionQestionImg"
            src={timeImg}
            alt="airbaloon"
          />
          <div className="commonQuestionQestionA">
            בזמן שלכם ובקצב שלכם מתי שתרצו בשעות המומלצות מטה
          </div>
        </div>
      </div>
    );
  };

  const renderReviews = () => {
    let tempReviews = reviews;
    let randomItems = [];

    for (let i = 0; i < 5; i++) {
      let randomIdx = Math.floor(Math.random() * reviews.length);
      let randomItem = tempReviews.splice(randomIdx, 1)[0];

      randomItems.push(randomItem);
    }

    if (randomItems.length === 5) {
      return randomItems.map((review) => review && <Review review={review} />);
    } else {
      return <div></div>;
    }
  };

  const renderTourBody = (tour) => {
    return (
      <div style={{ backgroundColor: "#FFFFFF" }}>
        <div className="saleDescription">
          <SmartText text={tour.description} length={200} />
        </div>
        <div className="commonQuestion">
          <hr className="hrLine" />
          {commonQuestionRender()}
          {tour && (
            <div style={{ paddingBottom: "50px" }}>
              <hr className="hrLine" />
              <div className="commonQuestionHeader">קצת פרטים יבשים</div>
              <div className="prodDetails">
                <div className="prodDetailsIcon">
                  <img
                    className="imageIcon"
                    src={locationIcon}
                    alt="Location"
                  />
                  <div className="tourLocation">
                    מיקום : {tour.location && tour.location}
                  </div>
                </div>
                <div className="prodDetailsIcon">
                  <img
                    className="imageIcon"
                    src={distanceIcon}
                    alt="distanse"
                  />
                  <div className="tourDistance">
                    אורך הסיור :
                    {tour.distance &&
                      Number((tour.distance / 1.6 / 1000).toFixed(2))}
                    K
                  </div>
                </div>
              </div>
              <div className="prodDetails">
                <div className="prodDetailsIcon">
                  <img className="imageIcon" src={pricetag} alt="distanse" />
                  <div className="tourDistance">
                    מחיר הסיור :{tour.price && calcPrice(tour)}
                  </div>
                </div>
              </div>
              <div className="prodDetails">
                <div className="prodDetailsIcon">
                  <img
                    className="imageIcon"
                    src={durationIcon}
                    alt="duration"
                  />
                  <div className="tourDuration">
                    משך הסיור :{tour.duration && tour.duration} שעות
                  </div>
                </div>

                <div className="prodDetailsIcon">
                  <img className="imageIcon" src={rangeIcon} alt="difficulty" />
                  <div className="tourDifficulty">
                    שעות מומלצות{" : "}
                    {tour.hours_range &&
                      formatLabel(tour.hours_range.min)} -{" "}
                    {tour.hours_range && formatLabel(tour.hours_range.max)}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div class="perchase-bar">
            {tour && (
              <button
                className="guideSectionButton saleButton"
                onClick={() => addProdToCart(tour)}
              >
                לרכישת הסיור
              </button>
            )}
          </div>

          {businessStop.length > 0 && (
            <div className="infoCarousel">
              <hr className="hrLine" />
              <div className="commonQuestionHeader businessHeader">
                עצירות עסקיות כלולות:
              </div>
              <Carousell defaultWait={0} /*wait for 1000 milliseconds*/>
                {businessStop.map((stop) => {
                  return (
                    <Slide right middle id={stop.id}>
                      <div className="commonQuestionQestion businessPicFrame">
                        <div className="commonQuestionQestionQ">
                          {stop.product_name}
                        </div>
                        <img
                          className="commonQuestionQestionImg1"
                          src={stop.product_pic.url}
                          alt="airbaloon"
                        />
                        {/*  <div className="commonQuestionQestionA">
                          {stop.s_smallDesc}
                        </div> */}
                      </div>
                    </Slide>
                  );
                })}
              </Carousell>
            </div>
          )}
          {reviews && (
            <div>
              <hr className="hrLine" />

              <div className="commonQuestionHeader businessHeader">
                ביקורות:
              </div>
              <div className="reviewsZone">{renderReviews()}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const formatCurrency = (num) => {
    return "₪" + Number(num.toFixed(1)).toLocaleString() + " ";
  };

  const calcPrice = (tour) => {
    let total_amount = parseInt(tour.price);
    tour.stops.map((stop) => {
      if (stop.type.includes("business")) {
        total_amount += parseInt(stop.offer_price);
      }
    });
    return formatCurrency(total_amount);
  };
  const renderTourButton = (tour) => {
    return (
      <a
        href="#"
        onClick={() => {
          setTour(null);
          setTour(tour);
        }}
        className="tourButton"
      >
        <img className="tourButtonImg" src={tour.tour_image} />
        <div stely="tourButtonText">
          <h1 className="buttonText bigTitle">{tour.title}</h1>
          <div className="buttonText smallText">{tour.main_sentense}</div>
          <button
            className="guideSectionButton saleButton orangeButton"
            onClick={() => setTour(tour)}
          >
            לפרטים נוספים
          </button>
          <div style={{ fontSize: "15px", color: "black" }}>
            {calcPrice(tour)}
          </div>
        </div>
      </a>
    );
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="outerStore">
      <Helmet>
        <title>{tour === null ? "D-guide" : tour.title}</title>
        <meta
          name="description"
          content={
            tour === null
              ? "סיורים עצמאיים, סיורים ללא מדריך מדריך, סיורים בתל אביב, סיורים בירושלים, סיורים מוזלים"
              : tour.description
          }
        />
        <meta name="theme-color" content="#ccc" />
      </Helmet>
      <div className="indexPage">
        <div className="headerIndex hederSale">
          <div>
            <img src={bigLogo} alt="Logo" className="bigLogo" />
            {!cartEmpty && (
              <Icon
                name="cart"
                size="large"
                onClick={() => setShowCart(!showCart)}
                circular
                inverted
                color="orange"
                className="cartIcon"
              />
            )}
          </div>
          {tour === null
            ? tours &&
              tours.length > 1 && (
                <div className="toursMenu">
                  <Carousell defaultWait={4000} /*wait for 1000 milliseconds*/>
                    {tours.map((tour) => {
                      return <Slide right>{renderTourButton(tour)}</Slide>;
                    })}
                  </Carousell>
                  {/*  {tours.map((tour) => renderTourButton(tour))}{" "} */}
                </div>
              )
            : tours.length > 1 && (
                <button className="returnButton" onClick={() => setTour(null)}>
                  חזור לרשימה של הסיורים
                </button>
              )}
          {
            tour && renderTourHeader(tour)
            /* <div>
              <div className="saleHeader">{tour.title}</div>
              <div className="mainSentense">{tour.main_sentense}</div>

              <div className="tourRate">
                {tour.rating && (
                  <Rating
                    icon="star"
                    defaultRating={tour.rating.total}
                    maxRating={5}
                    disabled
                    size="large"
                    className="ratingStar"
                  />
                )}
                {" דירוגים /"}
                {tour.rating && tour.rating.votes}{" "}
              </div>
              {video && (
                <div>
                  <video className="saleVideo" loop autoplay muted controls>
                    <source src={video.url} type="video/mp4" />
                  </video>
                </div> 
              )}
            </div>*/
          }
        </div>
        {tour ? (
          renderTourBody(tour)
        ) : (
          <div>
            {" "}
            {commonQuestionRender()}{" "}
            <div class="perchase-bar returnTopBar">
              <button
                className="guideSectionButton saleButton returnTop"
                onClick={() => scrollToTop()}
              >
                חזרה לבחירת סיור
              </button>
            </div>
          </div>
        )}
        <SlideDrawer
          setCartEmapty={setCartEmapty}
          showSlide={showCart}
          setDrawerClasses={setDrawerClasses}
          drawerClasses={drawerClasses}
          sourceId={sourceID}
        />
      </div>
    </div>
  );
};

//export default connect(mapState, actions)(OuterPay);
export default compose(
  connect(mapState, actions),
  firestoreConnect((auth) => query(auth))
)(OuterPay);
