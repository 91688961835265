import React, { useEffect, useState } from "react";
import { addToCart } from "../cart/cartActions";
import { connect } from "react-redux";
import { Icon, Modal } from "semantic-ui-react";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import Product from "../product/product";
import "../cart/cartStyle.css";

import { section2 } from "../home/language";
import { onBoardingHeader } from "../home/language";
import { onBoardingText } from "../home/language";
import Slide from "react-reveal/Slide";
import styled, { css } from "styled-components";
import makeCarousel from "react-reveal/makeCarousel";
import onBoarding1 from "../../images/onBoarding1.svg";
import onBoarding2 from "../../images/onBoarding2.jpg";
import onBoarding3 from "../../images/onBoarding3.jpg";
import onBoarding4 from "../../images/onBoarding4.jpg";
import onBoarding5 from "../../images/onBoarding5.jpg";
import saleVideo from "../../images/saleVideo.mp4";
import rightCarousel from "../../images/rightCarousel.png";
import leftCarousel from "../../images/leftCarusel.png";
import bigLogo from "../../images/bigLogoHe.png";
import zuzuLogo from "../../images/zuzuLogo.jpeg";
import zuzuLogo2 from "../../images/zuzuLogo2.png";
import SlideDrawer from "../../app/common/util/SlidePane";

const query = (props) => {
  let tourId;
  if (props.tourId) {
    tourId = props.tourId;
  } else if (props.initialValues && props.initialValues.tourId) {
    tourId = props.initialValues.tourId;
  }
  if (tourId) {
    return [
      {
        collection: "approval_tours",
        doc: tourId,
      },
    ];
  } else {
    return [{ collection: "approval_tours" }];
  }
};

const actions = {
  addToCart,
};

const mapState = (state, ownProps) => {
  let guideId = null;
  let tours = [];
  let english = false;
  let type = null;
  if (ownProps.match.params.guideId) {
    guideId = ownProps.match.params.guideId;
  }

  if (
    guideId &&
    state.firestore.ordered.approval_tours &&
    (guideId.includes("ITravelJerusalem") ||
      guideId.includes("ZuzuJerusalem") ||
      guideId.includes("OrenTours") ||
      guideId.includes("NadavTours") ||
      guideId.includes("specific"))
  ) {
    let jrs_tours = [];
    if (guideId === "ITravelJerusalemOldCity") {
      jrs_tours = ["1XU3bpkdsgJePawMWXB0"];
      type = "ITravelJerusalemOldCity";
    } else if (guideId === "ITravelJerusalemMoshe") {
      jrs_tours = ["QwvsqpPJMHwVQX6Qdvyr"];
      type = "ITravelJerusalemOldCity";
    } else if (guideId === "ITravelJerusalemShuk") {
      jrs_tours = ["lfngCB2AQGIoIdVkiIdu"];
      type = "ITravelJerusalemOldCity";
    } else if (guideId === "ZuzuJerusalem") {
      jrs_tours = [
        "QwvsqpPJMHwVQX6Qdvyr",
        "rljiQyhYwm2pMEnZgDaE",
        "1XU3bpkdsgJePawMWXB0",
      ];
      type = "ZUZU";
    } else if (guideId === "OrenTours") {
      jrs_tours = ["d5oxDdgraYCiqEf3uYyJ"];
      english = true;
    } else if (guideId === "NadavTours") {
      jrs_tours = ["1XU3bpkdsgJePawMWXB0", "n0Kuwb71znhTCxu4nZQ4"];
    } else if (guideId === "specificTLV") {
      jrs_tours = [
        "DVl8TCMY8VhQdN9qr1Nc",
        "2RsJUf7rYXZpZnkfOCqr",
        "n0Kuwb71znhTCxu4nZQ4",
        "MnmUJZoT4znQur4YQjMu",
      ];
    } else {
      jrs_tours = [
        "1XU3bpkdsgJePawMWXB0",
        "QwvsqpPJMHwVQX6Qdvyr",
        "rljiQyhYwm2pMEnZgDaE",
      ];
    }

    tours = state.firestore.ordered.approval_tours.filter(
      (tour) => jrs_tours.indexOf(tour.id) > -1
    );
    if (guideId === "ZuzuJerusalem") {
      tours.reverse();
    }
  } else if (guideId && state.firestore.ordered.approval_tours) {
    tours = state.firestore.ordered.approval_tours.filter(
      (tour) => guideId === tour.tour_guide.id
    );
  } else {
    tours = state.firestore.ordered.approval_tours;
  }

  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    aprov_tours: tours, //state.firestore.ordered.approval_tours,
    english: english,
    type: type,
  };
};

const OuterPay = ({ addToCart, english, auth, profile, aprov_tours, type }) => {
  const [status, setStatus] = useState("login");
  const [showCart, setShowCart] = useState(false);
  const [drawerClasses, setDrawerClasses] = useState("side-drawer");
  const [cartEmpty, setCartEmapty] = useState(false);

  useEffect(() => {
    if (!auth.authenticated) {
      setStatus("login");
    }
  }, [auth]);

  const addProdToCart = (tour) => {
    setShowCart(true);
    setDrawerClasses("side-drawer open");
    /* setTimeout(() => {
      setShowCart(!showCart);
      setDrawerClasses("side-drawer");
    }, 3000); */
    addToCart(tour);
  };

  const renderProducts = () => {
    return (
      <div className="toursProd">
        {aprov_tours &&
          aprov_tours.map((tour) => (
            <Product tour={tour} addToCart={addProdToCart} />
          ))}
      </div>
    );
  };

  const [lang, setLang] = useState(english ? "en" : "he");
  const [openModal, setOpenModal] = useState(true);
  const /*width = "300px",*/ height = "150px";
  const Arrow = styled.div`
    text-shadow: 1px 1px 1px #fff;
    z-index: 1000;
    line-height: ${height};
    text-align: center;
    position: absolute;
    top: 0;
    width: 10%;
    font-size: 3em;
    cursor: pointer;
    user-select: none;
    padding-top: 280px;
    ${(props) =>
      props.right
        ? css`
            left: 70%;
          `
        : css`
            left: 55%;
          `}
    @media (max-width: 600px) {
      ${(props) =>
        props.right
          ? css`
              left: 80%;
              padding-top: 350px;
              opacity: 0.5;
            `
          : css`
              left: 0%;
              padding-top: 350px;
              opacity: 0.5;
            `}
    }
  `;

  const Container = styled.div`
    position: relative;
    overflow: hidden;
    /* width: 1100px; */
    width: 100%;
    height: 450px;
    @media (max-width: 600px) {
      width: 100%;
      height: 650px;
    }
  `;

  const CarouselUI = ({ position, handleClick, children }) => (
    <Container>
      {children}
      <Arrow onClick={handleClick} data-position={position - 1}>
        {" "}
        <img src={leftCarousel} alt="airbaloon" />
      </Arrow>
      <Arrow right onClick={handleClick} data-position={position + 1}>
        {" "}
        <img
          src={rightCarousel}
          alt="airbaloon"
          style={{ marginTop: "28px" }}
        />{" "}
      </Arrow>
    </Container>
  );
  const Carousell = makeCarousel(CarouselUI);
  return (
    <div className="outerStore">
      <Modal size="large" open={openModal} onClose={() => setOpenModal(false)}>
        {/* <Modal.Header>Our</Modal.Header> */}
        <Modal.Content>
          <video
            loop
            autoplay
            muted
            width="40%"
            hight="75%"
            controls
            style={{ borderRadius: "10px", hight: "300px" }}
          >
            <source src={saleVideo} type="video/mp4" />
          </video>
          {/*  <div className="infoCarousel">
            <Carousell defaultWait={0} /*wait for 1000 milliseconds*>
              <Slide right>
                <div className="carouselItem">
                  <div className="carouselPic">
                    <img
                      src={onBoarding1}
                      alt="airbaloon"
                      class="onBoardingPic"
                    />
                  </div>
                  <div className={section2["textStyle"][lang]}>
                    <div className="hiwSmallTitle">
                      {section2["smallTitle"][lang]}
                    </div>
                    <div className="carouselHeder">
                      {onBoardingHeader["part1"][lang]}
                    </div>
                    <div className="carouselText">
                      {onBoardingText["part1"][lang]}
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide right>
                <div className="carouselItem">
                  <div className="carouselPic">
                    <img
                      src={onBoarding2}
                      alt="airbaloon"
                      class="onBoardingPic"
                    />
                  </div>
                  <div className={section2["textStyle"][lang]}>
                    <div className="hiwSmallTitle">
                      {section2["smallTitle"][lang]}
                    </div>
                    <div className="carouselHeder">
                      {onBoardingHeader["part2"][lang]}
                    </div>
                    <div className="carouselText">
                      {onBoardingText["part2"][lang]}
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide right>
                <div className="carouselItem">
                  <div className="carouselPic">
                    <img
                      src={onBoarding3}
                      alt="airbaloon"
                      class="onBoardingPic"
                    />
                  </div>
                  <div className={section2["textStyle"][lang]}>
                    <div className="hiwSmallTitle">
                      {section2["smallTitle"][lang]}
                    </div>
                    <div className="carouselHeder">
                      {onBoardingHeader["part3"][lang]}
                    </div>
                    <div className="carouselText">
                      {onBoardingText["part3"][lang]}
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide right>
                <div className="carouselItem">
                  <div className="carouselPic">
                    <img
                      src={onBoarding4}
                      alt="airbaloon"
                      class="onBoardingPic"
                    />
                  </div>
                  <div className={section2["textStyle"][lang]}>
                    <div className="hiwSmallTitle">
                      {section2["smallTitle"][lang]}
                    </div>
                    <div className="carouselHeder">
                      {onBoardingHeader["part4"][lang]}
                    </div>
                    <div className="carouselText">
                      {onBoardingText["part4"][lang]}
                    </div>
                  </div>
                </div>
              </Slide>
              <Slide right>
                <div className="carouselItem">
                  <div className="carouselPic">
                    <img
                      src={onBoarding5}
                      alt="airbaloon"
                      class="onBoardingPic"
                    />
                  </div>
                  <div className={section2["textStyle"][lang]}>
                    <div className="hiwSmallTitle">
                      {section2["smallTitle"][lang]}
                    </div>
                    <div className="carouselHeder">
                      {onBoardingHeader["part5"][lang]}
                    </div>
                    <div className="carouselText">
                      {onBoardingText["part5"][lang]}
                    </div>
                  </div>
                </div>
              </Slide>

               {/ <Slide right>
                <div className="carouselItem">
                  <div className="carouselPic">
                    <img src={howItWork3} alt="airbaloon" class="carouselImg" />
                  </div>
                  <div className={section2["textStyle"][lang]}>
                    <div className="hiwSmallTitle">
                      {section2["smallTitle"][lang]}
                    </div>
                    <div className="carouselHeder">
                      {section2["bigTitle3"][lang]}
                    </div>
                    <div className="carouselText">
                      {section2["text1"][lang]}
                    </div>
                  </div>
                </div>
              </Slide> /*
            </Carousell>
          </div> */}
          <button
            className="guideSectionButton"
            onClick={() => setOpenModal(false)}
          >
            {section2["continueButton"][lang]}
          </button>
        </Modal.Content>
      </Modal>
      {/* <Modal size="large" open={showCart} onClose={() => setShowCart(false)}>
        <div className="allCart">
          <Cart paymeURL={urlIframe} />
        </div>
      </Modal> */}
      <SlideDrawer
        setCartEmapty={setCartEmapty}
        showSlide={showCart}
        setDrawerClasses={setDrawerClasses}
        drawerClasses={drawerClasses}
        sourceId={type}
      />
      <div className="outerPayTop">
        <div className="productsLogo">
          <img src={bigLogo} alt="Logo" className="bigLogo" />
          {type === "ZUZU" && (
            <span>
              <img src={zuzuLogo} alt="Logo" className="zuzuBigLogo" />
              <img src={zuzuLogo2} alt="Logo" className="zuzuBigLogo" />
            </span>
          )}
        </div>
        <div>
          {" "}
          {!cartEmpty && (
            <Icon
              name="cart"
              size="large"
              onClick={() => setShowCart(!showCart)}
              circular
              inverted
              color="orange"
            />
          )}
        </div>
      </div>
      <div className="productsList">{renderProducts()}</div>
      {/* showCart && (
        <div className="allCart">
          <Cart paymeURL={urlIframe} />
        </div>
      ) */}
    </div>
  );
};

//export default connect(mapState, actions)(OuterPay);
export default compose(
  connect(mapState, actions),
  firestoreConnect((auth) => query(auth))
)(OuterPay);
