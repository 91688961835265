import React, { useEffect, useState, useRef } from "react";
import imageDivider from "../../../images/divider.svg";
import imageCheckbox from "../../../images/checkboxQuiz.svg";
import imageCheckboxOn from "../../../images/checkboxQuizOn.svg";
/* import videoMedia from "../../../images/videoMedia.svg";
import audioMedia from "../../../images/audioMedia.svg";
import textMedia from "../../../images/textMedia.svg";
import questionMedia from "../../../images/questionMedia.svg"; */
import { VariableSizeList as List } from "react-window";
//import { FixedSizeList as List } from "react-window";

import "../../../style/routePreview.css";
import { Fragment } from "react";

const useComponentDidMount = (func) => useEffect(func, []);

const useComponentWillMount = (func) => {
  const willMount = useRef(true);
  if (willMount.current) {
    func();
  }
  useComponentDidMount(() => {
    willMount.current = false;
  });
};

const StopPreview = (props) => {
  const { stop } = props;
  const [orderedMedia, setOrderedMedia] = useState(
    stop.all_media ? stop.all_media : []
  );

  const orderMedia = () => {
    return orderedMedia.sort((a, b) => (a.order > b.order ? 1 : -1));
  };

  const sizeArr = [
    (stop.s_smallDesc ? stop.s_smallDesc.length / 40 : 0) * 30 + 100,
  ];
  const setSizeArr = () => {
    stop.all_media.map((media) => {
      if (media.type) {
        if (media.type.includes("video")) {
          sizeArr[media.order + 1] = 280;
        } else if (media.type.includes("audio")) {
          //sizeArr.push(270);
          sizeArr[media.order + 1] = 270;
        } else if (media.type.includes("image")) {
          //sizeArr.push(250);
          sizeArr[media.order + 1] = 250;
        } else if (media.type.includes("text")) {
          let strLen = media.content.length;
          //sizeArr.push((strLen / 40) * 30 + 70);
          sizeArr[media.order + 1] = (strLen / 40) * 30 + 70;
        } else if (media.type.includes("question")) {
          //sizeArr.push(250);
          sizeArr[media.order + 1] = 250;
        }
      } else {
        sizeArr[media.order + 1] = 250;
      }
    });
  };
  /*   useEffect(() => {
    console.log("useEffect123!@#");
    setSizeArr();
  }, []); */
  useComponentWillMount(() => setSizeArr());

  const renderMedia = (all_media, index) => {
    /*     let data = {all_media : stop._all_media, s_title : stop.s_title}
    return(
      <img onClick={() => getPreview(data)} src={imageMedia} alt="image" />
    ) */
    if (index === 0) {
      return (
        <div>
          <div className="stopPreviewHeader">
            <div className="stopPreviewTitle">
              {stop.s_title ? stop.s_title : ""}
            </div>
            <div className="stopPreviewType">
              {" "}
              {stop.tag ? stop.tags.map((type) => type + " / ") : ""}
            </div>
          </div>
          <div className="stopPreviewInfo">
            <div className="stopInfoTitle">Info</div>
            <div className="stopInfoContent">
              {stop.s_smallDesc ? stop.s_smallDesc : ""}
            </div>
          </div>
        </div>
      );
    } else {
      let media = all_media[index - 1];
      //let media = all_media.filter((item) => item.order === index - 1)[0];
      //console.log("METDIA", media, index);

      if (typeof media === "undefined" /* && !media.type */) {
        return;
      }
      if (media.type && media.type.includes("image")) {
        return (
          <div className="stopPreviewImage">
            <img className="stopPreviewImage" src={media.url} />
          </div>
        );
      } else if (media.type && media.type.includes("video")) {
        return (
          <div className="stopPreviewVideo">
            <div className="videoTitle">
              {media.title ? media.title : "Title"}
            </div>
            <video
              className="videoModalPlayer videoPreview"
              poster={media.poster_url}
              controls
              style={{ width: "98%" }}
            >
              <source
                /* style={{ width: "50px" }} */
                src={media.url}
                type={media.type}
              />
            </video>
          </div>
        );
      } else if (media.type && media.type.includes("audio")) {
        return (
          <Fragment>
            <div className="stopInfoTitle">
              {media.title ? media.title : "Add title"}
            </div>
            <audio
              preload="auto"
              id="id12"
              className="stopPreviewAudio"
              controls="controls"
              src={media.url}
            ></audio>
          </Fragment>
        );
      } else if (media.type && media.type.includes("text")) {
        return (
          <div className="stopPreviewInfo">
            <div className="stopInfoTitle">
              {media.title ? media.title : "More info"}
            </div>
            <div className="stopInfoContent">{media.content}</div>
          </div>
        );
      } else if (media.type && media.type.includes("question")) {
        return (
          <div className="stopPreviewQuestion">
            <div className="quizTitle">{media.question_text}</div>
            {media.options.map((ans) => (
              <div className="quizAnswer">
                {" "}
                {ans.isAnswer ? (
                  <img src={imageCheckboxOn} className="checkboxQuiz" />
                ) : (
                  <img src={imageCheckbox} className="checkboxQuiz" />
                )}{" "}
                {ans.option}{" "}
              </div>
            ))}
          </div>
        );
      }
    }
  };

  const getItemSize = (index) => {
    return sizeArr[index];
  };
  console.log("orderedMedia", orderedMedia, "sizeArr", sizeArr);
  return (
    <div>
      <div className="stopMediaList">
        {
          /* stop.all_media */ orderedMedia && orderedMedia.length > 0 ? (
            <List
              // className="List"
              //direction="horizontal"
              height={650}
              itemCount={stop.all_media.length + 1}
              itemSize={getItemSize}
              width={"100%"}
            >
              {({ key, index, style }) => (
                <div
                  // className={index % 2 ? "ListItemOdd" : "ListItemEven"}
                  key={key}
                  style={style}
                >
                  {renderMedia(/* stop.all_media */ orderMedia(), index)}
                  <img src={imageDivider} class="divider" />
                </div>
              )}
            </List>
          ) : (
            <div>The stop is still empty of context</div>
          )
        }
      </div>
      {/*    <div className="stopHeader">{stop.s_title}</div> */}
    </div>
  );
};

export default StopPreview;
