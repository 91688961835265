import React, { useEffect, useState } from "react";
import { Icon } from "semantic-ui-react";
import Cart from "../../../features/cart/Cart";
import "./SlidePane.css";
const SlideDrawer = ({
  showSlide,
  drawerClasses,
  setDrawerClasses,
  setCartEmapty,
  sourceId,
}) => {
  //const [drawerClasses, setDrawerClasses] = useState("side-drawer");
  useEffect(() => {
    if (showSlide) {
      setDrawerClasses("side-drawer open");
    } else {
      setDrawerClasses("side-drawer");
    }
  }, [showSlide]);

  return (
    <div className={drawerClasses}>
      <Icon
        name="close"
        size="big"
        onClick={() => {
          // setShowSide(false);
          setDrawerClasses("side-drawer");
        }}
      />
      <div className="allCart1">
        <Cart
          setCartEmapty={setCartEmapty}
          paymeURL={null}
          sourceId={sourceId}
        />
      </div>
    </div>
  );
};

export default SlideDrawer;
