import React, { useState } from "react";
import { Form, Label } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import TextInput from "./form/textInput";
import style from "../../style/form.css";
import "../../style/form.css";
import logo from "../../images/loginLogo.svg";
import SelectInput from "./form/selectInput";
import { conferenceRegister } from "./conferenceActions";
import { connect } from "react-redux";

const actions = {
  conferenceRegister,
};
const conference_name = [
  {
    key: "Zoom Conf",
    text: "כנס הזום הקרוב- מועד ישלח בקרוב",
    value: "Zoom Conf",
  },
  {
    key: "personal",
    text: "מפגש פרונטלי- מתחם התחנה בירושלים,",
    value: "personal",
  },
  {
    key: "Zoom Personal",
    text: "פגישה בזום",
    value: "Zoom Presonal",
  },
];

const ConferenceRegistration = ({
  invalid,
  submitting,
  conferenceRegister,
  handleSubmit,
}) => {
  const [submitDone, setSubmitDone] = useState(false);
  const submitConfrence = (e) => {
    conferenceRegister(e);
    setSubmitDone(true);
  };
  const headText =
    "לאחר שמכרנו מאות סיורים לקהל תיירות הפנים ומדריכים רבים יצרו לעצמם מנוע הכנסה נוסף אנחנו מגייסים ומלווים מדריכים הרוצים לבנות סיור עצמאי במגוון שפות ולהרוויח עד 80% על כל מכירה…";
  const textButtom =
    "הצטרפו לקהילת מדריכי הסיורים העצמאיים של Dguide ותצרו ביעילות מוצר שישרת אתכם לטווח הרחוק, יתן לכם אלטרנטיבה נוספת לתקופות העומס ויחשוף אתכם לקהל מטיילים שעוד לא הכרתם.\
לחברת Dguide אפליקציית סיורים עצמאיים אשר מוכרת למטיילים סיור עצמאי מרתק וטכנולוגי אותו הם יכולים לעשות בזמן שלהם ובקצב שלהם. מהצד השני החברה פיתחה את פלטפורמת יצירת הסיורים הידידותית והטכנולגית ביותר בעולם בו תוכלו לבנות ולנהל את הסיורים העצמאיים שלכם.";
  return (
    <div
      style={{
        backgroundColor: "#39415e",
        height: "100%",
        direction: "rtl",
        textAlign: "center",
        margin: "auto",
      }}
    >
      <div className="conference-form">
        <div className="loginLogo">
          {" "}
          <img src={logo} alt="smallLogo" />
        </div>
        <h1 className="mainTitleConference">
          הצטרפו לקהילת מדריכי הסיורים העצמאיים
        </h1>
        <h3 style={{ color: "white" }}>
          {!submitDone
            ? headText
            : "הטופס התקבל בהצלחה. תודה רבה, אנחנו נחזור אליך בהקדם!"}
        </h3>
        <div
          style={{
            backgroundColor: "#39415e",
            height: "100%",
            display: "inline-block",
            textAlign: "center",
          }}
        >
          {!submitDone && (
            <Form size="large" onSubmit={handleSubmit(submitConfrence)}>
              <div className="formLable">שם מלא:</div>
              <Field
                name="full_name"
                component={TextInput}
                type="text"
                placeholder="שם מלא"
                className={style.Inputlogin}
                lable="שם מלא"
              />
              <div className="formLable">אימייל:</div>
              <Field
                name="email"
                component={TextInput}
                type="text"
                placeholder="אימייל"
                className={style.Inputlogin}
              />
              <div className="formLable">טלפון:</div>
              <Field
                name="phone"
                component={TextInput}
                type="text"
                placeholder="מספר טלפון"
                className={style.Inputlogin}
              />
              <div className="formLable">שפות:</div>
              <Field
                name="lang"
                component={TextInput}
                type="text"
                placeholder="שפות"
                className={style.Inputlogin}
              />
              <div className="formLable">סיור הדגל:</div>
              <Field
                name="tourFlag"
                component={TextInput}
                type="text"
                placeholder="סיור הדגל"
                className={style.Inputlogin}
              />

              <div className="formLable">התקשרות:</div>
              <Field
                name="conference_name"
                component={SelectInput}
                options={conference_name}
                value="conference_name.text"
                multiple={false}
                placeholder="התקשרות"
              />

              <button
                type="submit"
                className="submitButton"
                disabled={invalid || submitting}
              >
                הירשם כאן
              </button>
            </Form>
          )}
        </div>
        <h3 style={{ color: "white" }}>
          {!submitDone
            ? textButtom
            : "הטופס התקבל בהצלחה. תודה רבה, אנחנו נחזור אליך בהקדם!"}
        </h3>
      </div>
    </div>
  );
};

export default connect(
  null,
  actions
)(reduxForm({ form: "conferenceRegistration" })(ConferenceRegistration));
