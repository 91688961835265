import { SubmissionError, reset } from "redux-form";
import { closeModal } from "../modals/modalActions";
import { toastr } from "react-redux-toastr";
import cuid from "cuid";

export const login = (creds) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(creds.email, creds.password);
      //dispatch(closeModal());
    } catch (error) {
      console.log(error);
      throw new SubmissionError({
        _error: error.message,
      });
    }
  };
};

export const resetPassword = (email) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      //dispatch(closeModal());
    } catch (error) {
      console.log(error);
      throw new SubmissionError({
        _error: error.message,
      });
    }
  };
};

export const registerUser =
  (user) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    try {
      let createdUser = await firebase
        .auth()
        .createUserWithEmailAndPassword(user.email, user.password);
      await createdUser.user.updateProfile({
        displayName: user.displayName,
      });
      let newUser = {
        displayName: user.displayName,
        creationTime: firestore.FieldValue.serverTimestamp(),
        rating: {
          total: 0,
          votes: 0,
        },
        fisrtname: "",
        lastname: "",
        imageUrl: "",
        isNewUser: false,
        locale: "",
        tours: [],
        email: user.email ? user.email : "",
        uid: cuid(),
        userType: "guide",
      };
      await firestore.set(`users/${createdUser.user.uid}`, { ...newUser });
      dispatch(closeModal());
    } catch (error) {
      console.log(error);
      throw new SubmissionError({
        _error: error.message,
      });
    }
  };

export const socialLoginFunc =
  (selectedProvider) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    try {
      //dispatch(closeModal());
      let user = await firebase.login({
        provider: selectedProvider,
        type: "popup",
      });
      console.log(
        "social",
        user,
        user.additionalUserInfo.isNewUser,
        user.user.uid
      );
      let isNewUser = false;

      const userRef = await firebase
        .firestore()
        .collection("users")
        .doc(user.user.uid);

      await userRef.get().then(function (doc) {
        //console.log("inside get", doc, doc.exists, doc.data());
        if (user.additionalUserInfo.isNewUser || !doc.exists) {
          isNewUser = true;
        }
      });

      if (isNewUser) {
        const new_user = {
          displayName: user.user.displayName,
          //imageUrl: user.profile.avatarUrl,
          creationTime: firestore.FieldValue.serverTimestamp(),
          email: user.additionalUserInfo.profile.email,
          rating: {
            total: 0,
            votes: 0,
          },
          firstname: user.additionalUserInfo.profile.given_name,
          lastname: user.additionalUserInfo.profile.family_name,
          imageUrl: user.additionalUserInfo.profile.picture,
          isNewUser: false,
          locale: user.additionalUserInfo.profile.locale,
          tours: [],
          lastSignIn: firestore.FieldValue.serverTimestamp(),
          uid: user.user.uid,
          userType: "guide",
          questionAndAnswer: [],
        };
        await firestore.set(`users/${user.user.uid}`, new_user);
      } else {
        console.log("OLD USER");
      }
    } catch (error) {
      console.log(error);
    }
  };

export const updatePassword =
  (creds) =>
  async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;
    try {
      await user.updatePassword(creds.newPassword1);
      await dispatch(reset("account"));
      toastr.success("Success", "Your password has been updated");
    } catch (error) {
      console.log(error);
      throw new SubmissionError({
        _error: error.message,
      });
    }
  };

export const CheckType =
  () =>
  async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const user = firebase.auth().currentUser;

    if (user) {
      if (
        user.uid === "tLnbyrAN3XSFaQXv6dJdUjxgY0F2" ||
        user.uid === "HH6St4cQa2UpCGdc2zO62r2EHDg2" ||
        user.uid === "Nnq5qCRzfHbg6UzmSWQcRzghvpv1"
      ) {
        console.log("checkType TRUE", user);
        return true;
      }
    }
    console.log("checkType FALSE", user);
    return false;
  };

const addUserToAppUsers = async (user, firebase) => {
  console.log("ADD TO APP USERS", user);
  try {
    const isUserExist = await firebase
      .firestore()
      .collection("appUsers")
      .doc(user.id)
      .get();
    if (isUserExist._exists) {
      return;
    } else {
      await firebase
        .firestore()
        .collection("appUsers")
        .doc(user.id)
        .set({ ...user });
    }
  } catch (err) {
    console.log(err, "Error in saveUserInDB");
  }
};

export const handleUserSignIn =
  async (
    //navigation,

    //userData,
    service,
    appleData = {}
  ) =>
  async (dispatch, getState, { getFirebase }) => {};

export const socialLoginFuncApp =
  (service, appleData = {}, additional_tours = []) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    let newUser = {};
    const time = new Date().toLocaleString();
    let userData = {};
    try {
      //dispatch(closeModal());
      userData = await firebase.login({
        provider: service,
        type: "popup",
      });
      const { user, additionalUserInfo } = userData;
      let { isNewUser } = additionalUserInfo;
      const { profile } = additionalUserInfo;
      console.log(
        "social",
        userData,
        userData.additionalUserInfo.isNewUser,
        userData.user.uid,
        isNewUser
      );

      const userRef = await firebase
        .firestore()
        .collection("appUsers")
        .doc(userData.user.uid);

      await userRef.get().then(function (doc) {
        if (userData.additionalUserInfo.isNewUser || !doc.exists) {
          isNewUser = true;
        } else {
          newUser = doc.data();
        }
      });

      if (isNewUser) {
        if (service != null) {
          newUser = {
            id: user.uid,
            imageUrl:
              service === "google"
                ? profile.picture
                : service === "facebook"
                ? profile.picture.data.url
                : null,
            firstname:
              service === "google"
                ? profile.given_name
                : service === "facebook"
                ? profile.first_name
                : appleData.fullName.givenName,
            lastname:
              service === "google"
                ? profile.family_name
                : service === "facebook"
                ? profile.last_name
                : appleData.fullName.familyName,
            email: service === "AP" ? appleData.email : profile.email,
            locale: /* currentLocale */ "IL",
            lastSignIn: time,
            creationTime: time,
            isNewUser,
            isPaymentProcess: false,
            tours: [],
            favoriteTours: [],
          };
        } else {
          newUser = {
            id: userData.id,
            imageUrl: null,
            firstname: userData.firstname,
            lastname: userData.lastname,
            email: userData.email,
            locale: /* currentLocale */ "IL",
            isPaymentProcess: false,
            lastSignIn: time,
            creationTime: time,
            isNewUser: true,
            tours: [],
            favoriteTours: [],
          };
        }
      }
    } catch (error) {
      console.log(error);
      return false;
    }
    try {
      const userId = newUser.id;
      let current_tours = newUser.tours;
      let new_tours = {
        ...newUser.tours,
        ...additional_tours,
      };
      //store.dispatch(setUser(newUser));
      console.log("JUST BEFORE ADD USER", newUser);
      await addUserToAppUsers(newUser, firebase);
      //const userToken = await createJWTToken(newUser);
      //await saveUserTokenInDB(userId, userToken);
      //await storeLocalObject("token", userToken);

      /* setTimeout(() => {
      //store.dispatch(setCurrentUser(newUser));
      //  navigation.navigate('main');
    }, 500); */
      return true;
    } catch (err) {
      console.log(err, "error in handleUserSignIn");
      return false;
    }
  };

export const addTourToUser =
  (uid, additional_tours = [], cart = [], with_business = false) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    let newUser = {};
    let map_obj = {};

    try {
      const userRef = await firebase
        .firestore()
        .collection("appUsers")
        .doc(uid);
      if (with_business && newUser.tourMapping) {
        Object.keys(newUser.tourMapping).forEach((tour) => {
          map_obj[tour] = newUser.tourMapping[tour];
        });
      }

      await userRef.get().then(async function (doc) {
        if (!doc.exists) {
          toastr.error("Oops", "Please contact DGuide team");
        } else {
          newUser = doc.data();
          cart.cartItems.map((tour, t_index) => {
            let business_stops = [];
            additional_tours.push(tour.id);
            tour.stops.map((stop, s_index) => {
              if (with_business && stop.type.includes("business")) {
                business_stops.push(stop.id);

                map_obj[tour.id] = {
                  amount: tour.count,
                  business: business_stops,
                };
              }
            });
          });

          newUser.tours.map((tour) => additional_tours.push(tour));

          const unique = new Set(additional_tours);
          if (with_business) {
            newUser = {
              ...newUser,
              tours: [...unique],
              tourMapping: map_obj,
            };
          } else {
            newUser = {
              ...newUser,
              tours: [...unique],
            };
          }
          /* console.log("new user", newUser); */
          await firestore.set(`appUsers/${uid}`, newUser, {
            merge: true,
          });
          /* console.log("SET NEWUSER", newUser); */
        }
      });
      toastr.success("GREAT", "ADD tour to user");
    } catch (err) {
      console.log("ERROR", err);
      toastr.error("Oops", "something went wrong");
    }
  };
