import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Modal, Grid, Header } from "semantic-ui-react";
import DropzoneInput from "./DropzoneInput";

const EditAudio = ({ open, onClose, audio, updateAudio }) => {
  const [title, setTitle] = useState(audio.title ? audio.title : "");
  const [poster, setPoster] = useState([]);
  useEffect(() => {
    setTitle(audio.title);
  }, [audio]);

  const handleTitle = (event) => {
    setTitle(event.target.value);
  };

  return (
    <Modal size="large" open={open} onClose={onClose}>
      <Modal.Header>Edit audio</Modal.Header>
      <Grid>
        <Grid.Row />
        <Grid.Column width={6}>
          <Header color="teal" sub content="Audio preview" />
          <audio width="320" height="240" controls>
            <source src={audio.url} type={audio.type} />
          </audio>
        </Grid.Column>
        <Grid.Column width={5}>
          <Header color="teal" sub content="Audio title" />
          <input
            placeholder="Audio title"
            onChange={(e) => handleTitle(e)}
            type="text"
            value={title}
            style={{
              width: "200px",
              height: "30px",
              marginBottom: "8px",
            }}
          />
          <button
            className="saveButton"
            onClick={() => updateAudio(audio, title)}
          >
            Save
          </button>
        </Grid.Column>
      </Grid>
    </Modal>
  );
};

export default EditAudio;
