import React, { Component, useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  createTour,
  updateTour,
  deleteTour,
  approveTour,
  unApproveTour,
  checkTour,
} from "../tourAction";
import { createSeller } from "../../user/userActions";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import BusinessList from "../../business/businessList/BusinessList";
import TourList from "../tourList/TourList";
import { approveBusiness } from "../../business/businessActions.js";
import UserTable from "../../auth/UserTable";
import TourAllocation from "./TourAllocation";
import SalesPage from "../../user/SalesPage";
import UserControl from "./UsersControl";
import RegisterGuideTable from "../../auth/RegisterGuideTable";
import CouponsComponent from "../../coupons/CouponsComponent";
import HomePageAllocation from "../../homeAppPage/HomePageAllocation";

const query = (props) => {
  return [
    {
      collection: "tours",
      //where: ["tour_guide.id", "==", props.auth.uid],
    },
    {
      collection: "business",
      // where: ["tourOwner", "==", props.auth.uid],
    },
    {
      collection: "users",
    },
    {
      collection: "appUsers",
    },
    {
      collection: "webPurchase",
    },
    {
      collection: "purchase",
    },
    {
      collection: "guideRegister",
    },
  ];
};

const mapState = (state) => {
  const allow_user = [
    "shemesh1500@gmail.com",
    "idand93@gmail.com",
    "dori.nadav@gmail.com",
    "shemesh@shemesh.com",
  ];
  const check_user = allow_user.find(
    (user) => user === state.firebase.auth.email
  );
  let isAllowed = false;
  if (check_user) {
    isAllowed = true;
  }

  return {
    tours: state.firestore.ordered.tours,
    business: state.firestore.ordered.business,
    loading: state.async.loading,
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    users: state.firestore.ordered.users,
    isAllowed: isAllowed,
    appUsers: state.firestore.ordered.appUsers,
    guideRegister: state.firestore.ordered.guideRegister,
  };
};

const actions = {
  createTour,
  updateTour,
  deleteTour,
  approveTour,
  approveBusiness,
  unApproveTour,
  createSeller,
  checkTour,
};

const ManageTours = (props) => {
  const [checkPass, setCheckPass] = useState("");
  const [allowed, setAllowed] = useState("no");
  const [showTourAlloc, setshowTourAlloc] = useState(false);
  const [showHomePage, setshowHomePage] = useState(false);
  const [showAllTours, setshowAllTours] = useState(false);
  const [showGuideSales, setshowGuideSales] = useState(false);
  const [showRegisterGuide, setshowRegisterGuide] = useState(false);
  const [showCoupons, setshowCoupons] = useState(false);

  const [allGuides, setAllGuides] = useState([]);
  const [selectedGuide, setSelectedGuide] = useState(null);

  useEffect(() => {
    if (props.tours && props.tours.length > 0) {
      let all_guides = [];
      props.tours.map((tour) => all_guides.push(tour.tour_guide));
      setAllGuides(all_guides);
    }
  }, [props]);

  const dataRaper = () => {
    return (
      <div>
        {" "}
        {props.isAllowed && (
          <div>
            <button
              style={{ margin: "15px" }}
              onClick={() => setshowTourAlloc(!showTourAlloc)}
            >
              {" "}
              {showTourAlloc ? "Hide Tour Allocation" : "Show Tour Allocation"}
            </button>
            {showTourAlloc && <TourAllocation />}
            <button
              style={{ margin: "15px" }}
              onClick={() => setshowHomePage(!showHomePage)}
            >
              {" "}
              {showHomePage ? "Hide Homepage Editor" : "Show HomePage Editor"}
            </button>
            {showHomePage && <HomePageAllocation />}
            <button
              style={{ margin: "15px" }}
              onClick={() => setshowAllTours(!showAllTours)}
            >
              {" "}
              {showAllTours ? "Hide All Tours" : "Show All Tours"}
            </button>
            {showAllTours && (
              <Grid>
                <Grid.Column width={8}>
                  {props.tours && (
                    <TourList
                      tours={props.tours}
                      doApprov={true}
                      approveTour={props.approveTour}
                      unApproveTour={props.unApproveTour}
                      deleteTour={props.deleteTour}
                      checkTour={props.checkTour}
                    />
                  )}
                </Grid.Column>
                <Grid.Column width={8}>
                  {props.business && (
                    <BusinessList
                      business={props.business}
                      doApprov={true}
                      approveBusiness={props.approveBusiness}
                    />
                  )}
                </Grid.Column>
              </Grid>
            )}{" "}
            <button
              style={{ margin: "15px" }}
              onClick={() => setshowGuideSales(!showGuideSales)}
            >
              {" "}
              {showGuideSales ? "hide guide sales" : "Show guide sales"}
            </button>
            {showGuideSales && (
              <div>
                <select
                  multiple
                  onChange={(e) => {
                    console.log("e", e.target.value);
                    setSelectedGuide(e.target.value);
                  }}
                  value={allGuides.id}
                  key={allGuides.id}
                  style={{ margin: "20px", width: "30%", height: "150px" }}
                >
                  {allGuides.map((guide) => (
                    <option key={guide.id} value={guide.id} id={guide.id}>
                      {guide.full_name}
                    </option>
                  ))}
                </select>
                <SalesPage guideId={selectedGuide} />
              </div>
            )}
            <button
              style={{ margin: "15px" }}
              onClick={() => setshowRegisterGuide(!showRegisterGuide)}
            >
              {" "}
              {showRegisterGuide
                ? "hide registered guide"
                : "Show registered guide"}
            </button>
            {showRegisterGuide && (
              <div>
                <RegisterGuideTable users={props.guideRegister} />
              </div>
            )}
            <button
              style={{ margin: "15px" }}
              onClick={() => setshowCoupons(!showCoupons)}
            >
              {" "}
              {showCoupons ? "hide coupons" : "Show coupons"}
            </button>
            {showCoupons && (
              <div>
                <CouponsComponent />
              </div>
            )}
            {props.appUsers && <UserControl users={props.appUsers} />}
            {props.users && false && (
              <UserTable
                users={props.users}
                createSeller={props.createSeller}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  const beforeData = () => {
    const onSubmit = (e) => {
      e.preventDefault();
      if (checkPass === "I703") {
        setAllowed("isAllowed");
      }
    };

    return (
      <div>
        <p>The secret?</p>
        <form onSubmit={onSubmit}>
          <input
            type="password"
            value={checkPass}
            onChange={(e) => setCheckPass(e.target.value)}
          />
          <input type="submit" value="submit" />
        </form>
      </div>
    );
  };

  if (allowed === `isAllowed`) {
    return dataRaper();
  } else {
    return beforeData();
  }
};

export default compose(
  connect(mapState, actions),
  firestoreConnect((props) => query(props))
)(ManageTours);
