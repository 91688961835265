import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CartList from "./CartList";
import Fade from "react-reveal/Fade";
import { createSale } from "../user/userActions";
import { removeFromCart, removeOneFromCart } from "../cart/cartActions";
import SocialLogin from "../auth/SocialLogin/SocialLogin";
import { socialLoginFuncApp } from "../auth/authActions";
import { cartText } from "../home/language";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { useHistory } from "react-router-dom";

const actions = {
  createSale,
  removeFromCart,
  removeOneFromCart,
  socialLoginFuncApp,
};

const mapState = (state, ownProps) => {
  let source_id = null;
  if (ownProps.match && ownProps.match.params.guideId) {
    source_id = ownProps.match.params.guideId;
  }
  return {
    cart: state.cart,
    auth: state.firebase.auth,
    source_id: source_id,
    coupons: state.firestore.ordered.coupons,
  };
};
const Cart = ({
  cart,
  createSale,
  removeFromCart,
  removeOneFromCart,
  auth,
  socialLoginFuncApp,
  /* setCartEmapty, */
  setCheckoutVal,
  sourceId,
  type = true,
  coupons,
}) => {
  console.log("CART HAVE 1", cart);
  const [showCheckout, setShowCheckout] = useState(
    setCheckoutVal ? true : false
  );
  const [authStatus, setAuthStatus] = useState(auth.isEmpty ? false : true);
  const [paymeURL, setPaymeURL] = useState("");
  const formatCurrency = (num) => {
    return "₪" + Number(num.toFixed(1)).toLocaleString() + " ";
  };
  const history = useHistory();
  const [userCoupon, setUserCoupon] = useState("");
  let [itsFreeCouponTour, setItsFreeCouponTour] = useState(false);
  const [coupunsVal, setCouponVal] = useState([]);
  const [regPrice, setRegPrice] = useState(0);
  let [couponSeccess, setCouponSuccess] = useState(false);
  const [massage, setMassage] = useState(
    couponSeccess ? "couponSuccess" : "couponFail"
  );

  const checkCoupon = (total_amount, tour) => {
    let beforPrice = total_amount;
    console.log("total_amount, tour", total_amount, tour);
    if (coupunsVal.length > 0 && coupons) {
      coupunsVal.map((coupon) => {
        console.log("coupon", coupon, coupons);
        let wanted_coupon = coupons.filter((coup) => coup.value === coupon);
        if (wanted_coupon[0]) {
          wanted_coupon[0].tours.map((tourCoup) => {
            if (tourCoup === "all" || tourCoup === tour.id) {
              const discount = wanted_coupon[0].discount / 100;
              sourceId = coupon;
              if (discount === 1) {
                total_amount = 0;
              } else {
                total_amount = Math.round(
                  total_amount - total_amount * discount
                );
              }
            }
          });
        }
      });
    }
    if (beforPrice !== total_amount && !couponSeccess) {
      setCouponSuccess(true);
      console.log("CHECK");
      if (total_amount === 0) {
        setItsFreeCouponTour(true);
      }
    } /* else {
      setMassage("the coupon is invalide");
    } */

    return total_amount;
  };

  const proceedPayment = () => {
    /*  let total_amount = cart.cartItems.reduce(
      (a, c) => a + c.price * c.count,
      0
    );

    cart.cartItems.map((tour) => {
      console.log("business run", tour);
      tour.stops.map((stop) => {
        if (stop.type.includes("business")) {
          total_amount += parseInt(stop.offer_price);
        }
      });
    }); */
    let total_amount = 0;
    cart.cartItems.map((tour) => {
      total_amount += calcPrice(tour) * tour.count;
    });
    let t_id = total_amount;
    if (auth.uid && showCheckout && total_amount) {
      //console.log("total anount", total_amount, auth.uid);
      auth.uid.split("").forEach((character) => {
        let current = character.charCodeAt(0);
        t_id = t_id + current;
      });
      console.log(
        "NEW CREAATE SELL",
        sourceId,
        total_amount,
        auth.uid,
        t_id,
        sourceId
      );
      createSale(total_amount, auth.uid, t_id, sourceId)
        .then((res) => {
          setPaymeURL(res?.data?.sale_url);
        })
        .catch((err) => console.log("ERR", err));
    }
  };
  useEffect(() => {
    setMassage(couponSeccess ? "couponSuccess" : "couponFail");
  }, [couponSeccess]);

  useEffect(() => {
    /*  if (cart.cartItems.length > 0) setCartEmapty(false);
    setCartEmapty(cart.cartItems.length === 0);
 */
    setRegPrice(cart.cartItems.reduce((a, c) => a + calcPrice(c) * c.count, 0));

    if (cart.cartItems.length > 0) {
      proceedPayment();
    }
  }, [auth, showCheckout, cart]);

  const socialLogin = (details) => {
    socialLoginFuncApp(details).then((res) => {
      if (res) {
        setAuthStatus(true);
        proceedPayment();
      }
    });
    /*  if (logintReturn) {
      setStatus("payment");
    } */
  };

  const calcPrice = (tour) => {
    console.log("IN CALCPRICE");
    let total_amount = parseInt(tour.price);
    tour.stops.map((stop) => {
      if (stop.type.includes("business")) {
        total_amount += parseInt(stop.offer_price);
      }
    });
    total_amount = checkCoupon(total_amount, tour);
    return total_amount;
  };

  return (
    <div>
      {type &&
        (cart.cartItems.length === 0 ? (
          <div className="cart cart-header">Cart is empty</div>
        ) : (
          <div className="cart cart-header">
            You have {cart.cartItems.length} items in the cart{" "}
          </div>
        ))}
      {!showCheckout && (
        <CartList
          cartItems={cart.cartItems}
          setShowCheckout={setShowCheckout}
          removeFromCart={removeOneFromCart}
          calcPrice={calcPrice}
          formatCurrency={formatCurrency}
        />
      )}
      <div className="couponZone">
        <input
          onChange={(e) => setUserCoupon(e.target.value)}
          className="contactForm"
          name="coupon_value"
          type="text"
          placeholder={cartText["couponPlaceHolder"]["he"]}
          value={userCoupon}
        />
        <div className="couponText">
          {coupunsVal.length > 0 && cartText[massage]["he"]}
        </div>
        <button
          style={{ margin: "15px" }}
          onClick={() => setCouponVal([...coupunsVal, userCoupon])}
        >
          {cartText["couponButton"]["he"]}
        </button>
      </div>
      <div style={{ padding: "0px 5% 0 5%", textAlign: "center" }}>
        {type && (
          <div className="total">
            <div>
              Total:{" "}
              <div className="origPrice">
                {coupunsVal.length > 0 && formatCurrency(regPrice)}
              </div>
              <div className="totalPay">
                {formatCurrency(
                  cart.cartItems.reduce((a, c) => a + calcPrice(c) * c.count, 0)
                )}
              </div>
            </div>
          </div>
        )}
        {showCheckout && !setCheckoutVal ? (
          <button
            className="actionButton proceed"
            onClick={() => setShowCheckout(false)}
          >
            {cartText["backShopping"]["he"]}
          </button>
        ) : (
          !setCheckoutVal && (
            <button
              className="actionButton proceed"
              onClick={() => setShowCheckout(true)}
            >
              {cartText["proceed"]["he"]}
            </button>
          )
        )}
      </div>
      {showCheckout && (
        <div className="iframePay">
          <Fade right cascade>
            {authStatus ? (
              itsFreeCouponTour ? (
                history.push(
                  "/outerPayReturn/" +
                    auth.uid +
                    "/" +
                    auth.uid +
                    "/" +
                    sourceId
                ) &&
                console.log(
                  "!!!!https://d-guide.co/outerPayReturn/" +
                    auth.uid +
                    "/" +
                    auth.uid +
                    "/" +
                    sourceId
                )
              ) : (
                <iframe
                  id="paymeWin"
                  src={paymeURL}
                  style={{ width: "100%", height: "500px" }}
                />
              )
            ) : (
              <div className="outerLogin">
                <div className="loginText">
                  {" " + cartText["loginText"]["he"]}
                </div>
                <SocialLogin socialLogin={socialLogin} agree={true} />{" "}
              </div>
            )}
          </Fade>
        </div>
      )}
    </div>
  );
};

export default compose(
  connect(mapState, actions),
  firestoreConnect(() => [{ collection: "coupons" }])
)(Cart);
